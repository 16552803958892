/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
// import axios from 'axios';
// import Swal from 'sweetalert2';
// import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../Services/UserServices';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import Sign_Up from "../assets/logo.svg";
import { ToastContainer, toast } from 'react-toastify';
import api from '../Services/ApiService';


function OtpConfirm() {
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const [otpTouched, setOtpTouched] = useState(false)
  // const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // New state for error message
  const onSubmitHandlerResendOtp = async (e) => {
    setLoading(true)
    try {
      e.preventDefault();
      // Replace 'YOUR_API_ENDPOINT' with the actual endpoint of your server for the "Forgot Password" API
      const response = await api.post(
        `/user/forget-password?` + `email=${localStorage.getItem('userEmail')}`,{devMode:true}
      );


      // Check if the response indicates success (modify the condition based on your API response structure)
      if (response.data.statusCode === 200) {
        // Show a success message to the user

        const notify = () =>
          toast.success(
            " Otp resend successfully",
            {
              autoClose: 1000,
            }
          );
        notify();

      }
    } catch (error) {

      toast.error(error?.response?.data?.message)
    }
    setLoading(false)
  };

  const onSubmitHandlerOtpConfirm = async (e) => {
    try {
      e.preventDefault();
      const trimmedOtp = otp.trim();
      if (trimmedOtp === '') {
        setOtpTouched(true); // Mark otp field as touched
        return; // Return early if otp is empty
      }
      // Replace 'YOUR_API_ENDPOINT' with the actual endpoint of your server for OTP verification
      const response = await api.post(
        `/user/verify`, { email: localStorage.getItem('userEmail'), otp: otp } // Pass the entered OTP to the API in the request body
      );

      // Check if the response indicates success (modify the condition based on your API response structure)
      if (response.data.statusCode === 200) {

        const notify = () =>
          toast.success(
            'OTP verification successfully',
            {
              autoClose: 1000,
            }
          );
        notify();

        navigate('/ResetPassword')
      }
      else {
        setErrorMessage('Failed to verify otp.');
        setTimeout(() => {
          setErrorMessage('');
        }, 2000);
      }
    } catch (error) {
      toast.error(error.message == "Network Error" ? "Please check your internet connection.": error.message)
    }
  };


  return (
    <>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress />
          </Backdrop>
        </Box>
      )
      }


      <div className="container-fluid bg-login_page bg_Register_page bg_ForgetPassword_page">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-md-12 col-lg-7 col-xl-6">
              <div className="card card_custom_card">
                <div className="card-body card_custom">
                  <div className="text-center">
                    <a href="/">
                      <img src={Sign_Up} className="img-fluid logo_img" alt="" />
                    </a>
                  </div>
                  <div className="login_heading_Area">
                    <h3 className="fw-bold text-center my-2"> Verify OTP</h3>
                    <p className="bottom-nav_headeing">We have sent a verification code.<br/> Please enter it below.</p>
                  </div>
                  <form onSubmit={onSubmitHandlerOtpConfirm}>
                    <div className="col-md-12 mb-2">
                      {/* <label className="form-label">OTP<span className="redP">*</span></label> */}
                      {/* <input
                        type="number"
                        className="form-control form-control_custom"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="Enter OTP"
                        maxLength={6}
                        minLength={6}
                      /> */}
                      <input
                        type="text"
                        className="form-control form-control_custom"
                        value={otp}
                        onChange={(e) => {
                          const input = e.target.value;
                          // Remove non-numeric characters
                          const numericInput = input.replace(/\D/g, '');
                          // Limit the length to 6 characters
                          const truncatedInput = numericInput.slice(0, 6);
                          setOtp(truncatedInput);
                        }}
                        placeholder="Enter OTP"
                        maxLength={6}
                      />

                      {otpTouched && otp.trim() === '' && <div className="redP font-14 ms-2 ">OTP required</div>}
                      {errorMessage && <p className="redP font-14 ms-2 ">{errorMessage}</p>}
                    </div>
                    <div className="d-flex px-2 align-items-center justify-content-end">
                      {/* <p className='font-14'>00:00</p> */}
                      <a className="forget_password mb-0 pb-0" onClick={onSubmitHandlerResendOtp} type="button" >
                        Resend OTP
                      </a>
                    </div>
                    <button className="btn Sign_btn_submit w-100 mt-3" type="submit">
                      Send
                    </button>

                  </form>
                </div>

              </div>

            </div>
          </div>
          <ToastContainer />

        </div>
      </div >



      {/* <form onSubmit={onSubmitHandlerOtpConfirm}>
        <section className="container-fluid Sign-section  Sign-section-Password">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="card Sign-Up-card-section">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <h3 className="fw-bold"> Verify OTP</h3>
                    
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">OTP<span className="redP">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          placeholder="Enter OTP"
                          maxLength="8"
                        />
                        {otpTouched && otp.trim() === '' && <div className="redP">otp required</div>}
                        {errorMessage && <span className="redP">{errorMessage}</span>}
                      </div>
                      <div className="text-end">
                        <button className="forget_password" onClick={onSubmitHandlerResendOtp} type="button" >
                          Resend OTP
                        </button>
                      </div>
                      <div>
                        <button className="btn Sign_btn_submit" type="submit">
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="card Sign-Up-card-section-img">
                  <div className="card-body">
                    <img src={Sign_Up} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form> */}


    </>
  );
}

export default OtpConfirm;
