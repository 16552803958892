import axios from "axios";
import CryptoJS from "crypto-js";
import { Encryption_Key } from "../utils/constants";


// Your encryption key (ensure it's secure and random)
export const encryptionKey = Encryption_Key;
// Encrypt function
const encryptData = (data) => {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
  return encryptedData;
};

// Decrypt function
export const decryptData = (encryptedData) => {
  try {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
    const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (error) {
    console.error("Decryption Error:", error);
    return encryptedData; // or handle the error accordingly
  }
};


const api = axios.create({
  baseURL: "https://ductarchitect.com/api", // Replace with your API's base URL
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    // You can add headers or perform other tasks before the request is sent
    const user = JSON.parse(localStorage.getItem("user") || "[]"); // Replace with how you store your token
    if (user) {
      config.headers.Authorization = `Bearer ${user?.token ?? ""}`;
    }

    // Encrypt request data if present and if it's not FormData
    if (config.data) {
      config.data = {
        data: encryptData(config.data)
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    // Decrypt response data if present
    if (response.data) {
      response.data = decryptData(response.data);
    }

    // You can process successful responses here
    return response;
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      // Redirect to the login page on 401 error
      localStorage.clear();
      window.location.href = "/login"; // Replace with your login page URL
    }
    return Promise.reject(error);
  }
);

export default api;
