 

import React from "react";
import TreeView, { flattenTree } from "react-accessible-treeview";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";

function DirectoryTreeView({ data: folder, onSelected, selectedID }) {
//  const [selectedNodeIds, setSelectedNodeIds] = useState([]);

  const data = flattenTree({
    name: "",
    children: folder,
  });


  return (
    <div>
      <div className="directory">
        <TreeView
          data={data}
          aria-label="directory tree"
          // defaultExpandedIds={[1]}
          // expandedIds={[...new Set(selectedNodeIds?.filter(id => id !== null))]}  
          nodeRenderer={({
            element,
            isBranch,
            isExpanded,
            getNodeProps,
            level,
          }) => {
            const isSelected = selectedID && selectedID?.id === element.id;
              return (
              <div
                {...getNodeProps()}
                style={{
                  width: "90%",
                  lineHeight: 0,
                  background: isSelected ? "#00BFFF" : "transparent",
                  marginBottom: "3px",
                  padding: "4px 13px",
                  borderRadius: "3px",
                  marginLeft: 20 * (level - 1),
                }}
              >
                {isBranch ? (
                  <FolderIcon isOpen={isExpanded} />
                ) : (
                  <FileIcon filename={element.name} />
                )}
                <span style={{ margin: "5px", lineHeight: 0.9 }}>
                  {element.name}
                </span>
              </div>
            );
          }}
          onNodeSelect={(selected) => {
             onSelected(selected);
            //  setSelectedNodeIds(prevExpandedIds => [...prevExpandedIds, selected?.element?.id]);
          }}
         />
      </div>
    </div>
  );
}

const FolderIcon = ({ isOpen }) =>
  isOpen ? (
    <IoMdArrowDropdown color="black" className="icon" />
  ) : (
    <IoMdArrowDropright color="black" className="icon" />
  );

const FileIcon = ({ filename }) => null;

export default DirectoryTreeView;
