import { createSlice } from '@reduxjs/toolkit';
const initialState = [];
const PlanSlice = createSlice({
    name: 'PlanSlice',
    initialState:initialState,
    reducers:{
        setArrary:(state,action)=>{
            return action.payload
        },
        addArrary:(state,action)=>{
            console.log("ACtionoin add arrary",action)
            state.push(action.payload);
        },
      
    }})
    export const {setArrary,addArrary}=PlanSlice.actions;
export default PlanSlice.reducer