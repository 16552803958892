import React, { useEffect, useState } from "react";
import withAuth from "../WithAuth";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../Services/ApiService";
import Swal from "sweetalert2";
import axios from "axios";
import { useAuth } from "../../provider/AuthProvider";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Spinner from "../common/Spinner";
import NoData from "../../assets/NoData.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from 'react-tooltip';



function SpaceProjectList() {
  const [loading, setLoading] = useState(false);
  const [loadingM, setLoadingM] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items per page

  const totalPages = Math.ceil(data.length / pageSize);



  const [selectedspace, setSelectedspace] = useState(null);

  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageProducts = data.slice(startIndex, endIndex);

  const navigate = useNavigate();

  const schema = yup.object().shape({
    space_name: yup
      .string()
      .required("Space name is required")
      .trim()
      .min(3, "Space name be at least 3 characters")
      .max(55, "Space name must not exceed 55 characters"),
  });

  const { productId } = useParams();
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/hvac/getAll/${productId}`);
      if (response.status == 200) {
        setData(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data) => {
     try {
      setLoadingM(true);
      const response = await api.post("/hvac/create-space", {
        ...data,
        project_id: productId,
      });

      if (response.status === 200) {
        document.getElementById("close-modal").click();

        const notify = () =>
          toast.success(
            "Space created successfully.",
            {
              autoClose: 1000,
            }
          );
        notify();
        reset();
        fetchData();
      }
      setLoadingM(false);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
    setLoadingM(false);
  };

  const clearValidationOnClose = () => {
    clearErrors();
  };

  useEffect(() => {
    const modalElement = document.getElementById("exampleModal");
    modalElement.addEventListener("hidden.bs.modal", clearValidationOnClose);

    // Clean up the event listener when the component unmounts
    return () => {
      modalElement.removeEventListener(
        "hidden.bs.modal",
        clearValidationOnClose
      );
    };
  }, []);

  const onNavigate = (spaceId) => {
    navigate(`/spaceProjectDetail/${spaceId}`);
  };


  const handleDeleteSpace = async (itemId) => {
    try {
      const confirmDelete = await Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this space!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      if (confirmDelete.isConfirmed) {
        setLoading(true);
        const { status } = await api.get(`/hvac/delete/${itemId}`);
        if (status === 200) {
          fetchData();

          console.log("Deleted!", "The space has been deleted.", "success");
        } else {
          Swal.fire(
            "Error",
            "Failed to delete the space.",
            "error"
          );
        }

      }



      setLoading(false);
    }

    catch (error) {
      console.error('Error deleting item:', error);
    } finally {
      setLoading(false);
    }
  };

  const [nestedTable, setNestedTable] = useState(false);

  const handleClick = (item) => {

    //   setSelectedspace(item)
    //  setNestedTable(!nestedTable)
    if (selectedspace !== item) {
      setSelectedspace(item);
      setNestedTable(true);
    } else {
      setNestedTable(!nestedTable);
    }

  }



  const handleBack = () => {
    window.history.back()
  }

  const project = data.find(item => item?.product && item.product?.productName);

  const projectName = project ? project?.product?.productName : '';


  return (
    <div>
      <section className="container-fluid PageTitle_banner_section">
        <div className="container">
          <h1 className="PageTitle_banner_heading">Heat Summary</h1>
        </div>
      </section>

      <section className="container-fluid Project-section project_section_hp mt-4">
        <div className="container">
          <div className="row mb-0 ">
            <div className="col-md-12">
              <div className="project_heading_area">
                <div className="project_heading_BackBtn_area">
                  <a className="back_arrow" onClick={handleBack}
                    data-tooltip-id="BackBtn"
                    data-tooltip-content="Back"
                  >
                    <i class="fa-solid fa-arrow-left-long"></i>
                    <Tooltip id="BackBtn" />
                  </a>
                  <h3 className="fw-bold me-1">{projectName}</h3>
                </div>
                <button
                  type="button"
                  className="btn btn-primary"
                  id="modal-button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <i className="fa-solid fa-plus me-1"></i> New Space
                </button>
              </div>
              <ToastContainer />
            </div>
          </div>

          <div className="row mb-3 Project_listing_section_hp mt-4">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table  shadow-none mb-0 table-striped table-hover">
                  <thead>
                    <tr className="table-header">
                      <th>S. No.</th>
                      <th>Space Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  {loading ? (
                    <tr>
                      <td
                        colSpan="3"
                        style={{ textAlign: "center", height: "200px" }}
                      >
                        <span
                          className="spinner-border spinner-border-md text-dark"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Loading...</span>
                      </td>
                    </tr>
                  ) : data && data.length > 0 ? (
                    <>
                      <tbody>
                        {currentPageProducts?.map((item, index) => (
                          <>
                            {/* Main table row */}
                            <tr key={item.id} className="space_project_list_sl">
                              <td>{index + 1}</td>
                              <td onClick={() => handleClick(item)} style={{ cursor: "pointer" }}>{item?.space_name}</td>
                              <td>
                                <div className="table-action-icon">
                                  <a onClick={() => onNavigate(item.id)}
                                    data-tooltip-id="HeatSummary_edit"
                                    data-tooltip-content="Edit"
                                  >
                                    <i class="fa-solid fa-pen-to-square"></i>
                                    <Tooltip id="HeatSummary_edit" />
                                  </a>

                                  <a onClick={() => handleDeleteSpace(item.id)}
                                    data-tooltip-id="HeatSummary_Delete"
                                    data-tooltip-content="Delete"
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                    <Tooltip id="HeatSummary_Delete" />
                                  </a>
                                </div>
                              </td>
                            </tr>
                            {/* Nested table - render only if nestedTable state is true */}
                            {nestedTable && selectedspace === item && (
                              <tr key={`nested-${item.id}`}>
                                <td colSpan="3"> {/* Make sure to set colspan to the number of columns in the parent table */}
                                  <table className="table table-hover mb-0 table-striped">
                                    <thead>
                                      <tr className="table-header">
                                        <th>Summary</th>
                                        <th>Summary</th>
                                        <th>Monsoon</th>
                                        <th>Winter</th>
                                      </tr>
                                    </thead>
                                    {/* <tbody>
                                            <tr className="space_project_list_sl">
                                              <td>name</td>
                                              <td>surname</td>
                                              <td>action</td>
                                            </tr>
                                          </tbody> */}
                                    <tbody>
                                      <tr>
                                        <td>TR</td>
                                        {/* <td>  </td> */}
                                        <td> {selectedspace?.space_output?.TR?.toFixed(2)} </td>
                                        <td> {selectedspace?.space_output?.monsoonCal?.TRmonsoon?.toFixed(2)}</td>
                                        <td> {selectedspace?.space_output?.winterCal?.TR?.toFixed(2)} </td>
                                      </tr>
                                      <tr>
                                        <td>Effective Room Sensible Heat</td>
                                        {/* <td></td> */}
                                        <td>{selectedspace?.space_output?.ershResult} </td>
                                        <td>{selectedspace?.space_output?.monsoonCal?.ershResultMonsoon}</td>
                                        <td>{selectedspace?.space_output?.winterCal?.ershResultWinter}</td>
                                      </tr>
                                      <tr>
                                        <td>Effective Room Latent Heat</td>
                                        {/* <td></td> */}
                                        <td>{selectedspace?.space_output?.effectiveRoomLatentHeatErlh}</td>
                                        <td>{selectedspace?.space_output?.monsoonCal?.effectiveRoomLatentHeatErlhMonsoon}</td>
                                        <td>{selectedspace?.space_output?.winterCal?.effectiveRoomLatentHeatErlhWinter} </td>
                                      </tr>
                                      <tr>
                                        <td>Grand Total Heat</td>
                                        <td>{selectedspace?.space_output?.grandTotalHeat} </td>
                                        <td> {selectedspace?.space_output?.monsoonCal?.grandTotalHeat} </td>
                                        <td> {selectedspace?.space_output?.winterCal?.grandTotalHeat}</td>
                                      </tr>
                                      {/* <tr>
                          <td>CFM Load</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr> */}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </tbody>

                    </>
                  ) : (
                    <tr>
                      <td
                        colSpan="3"
                        style={{ textAlign: "center", height: "350px" }}
                      >
                        <div>
                          <img
                            src={NoData}
                            alt="No data available"
                            style={{ maxWidth: "300px" }}
                          />
                          <h5 className="mt-2"> Create Your New Space</h5>
                        </div>

                      </td>
                    </tr>
                  )}
                </table>
                {data.length == "0" ? (
                  <></>
                ) : (
                  <div className="d-flex justify-content-end my-3">
                    <div className="pagination m-1">
                      <a className="btn_next_Previous"
                        onClick={() => goToPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <i class="fa-solid fa-angle-left"></i>
                      </a>
                      <span className="mx-3">
                        {" "}
                        Page {currentPage} of {totalPages}
                      </span>
                      <a className="btn_next_Previous"
                        onClick={() => goToPage(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <i class="fa-solid fa-angle-right"></i>
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <div className="row mb-5">
            <div className="col-md-12 mt-4">
              <div className="card border-0">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-bordered mb-0">
                      <thead>
                        <tr className="table-header">
                          <th>Summary</th>
                          <th>Summer</th>
                          <th>Monsoon</th>
                          <th>Winter</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>TR</td>
                          <td> {selectedspace?.space_output?.TR?.toFixed(2)} </td>
                          <td> {selectedspace?.space_output?.monsoonCal?.TRmonsoon?.toFixed(2)}</td>
                          <td> {selectedspace?.space_output?.winterCal?.TR?.toFixed(2)} </td>
                        </tr>
                        <tr>
                          <td>Effective Room Sensible Heat</td>
                          <td>{selectedspace?.space_output?.ershResult} </td>
                          <td>{selectedspace?.space_output?.monsoonCal?.ershResultMonsoon}</td>
                          <td>{selectedspace?.space_output?.winterCal?.ershResultWinter}</td>
                        </tr>
                        <tr>
                          <td>Effective Room Latent Heat</td>
                          <td>{selectedspace?.space_output?.effectiveRoomLatentHeatErlh}</td>
                          <td>{selectedspace?.space_output?.monsoonCal?.effectiveRoomLatentHeatErlhMonsoon}</td>
                          <td>{selectedspace?.space_output?.winterCal?.effectiveRoomLatentHeatErlhWinter} </td>
                        </tr>
                        <tr>
                          <td>Grand Total Heat</td>
                          <td>{selectedspace?.space_output?.grandTotalHeat} </td>
                          <td> {selectedspace?.space_output?.monsoonCal?.grandTotalHeat} </td>
                          <td> {selectedspace?.space_output?.winterCal?.grandTotalHeat}</td>
                        </tr>
                      
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-content">
              <div className="modal-header bg-primary ">
                <h5 className="modal-title text-white " id="exampleModalLabel">
                  Add New Space
                </h5>
                <button
                  type="button"
                  className="btn close"
                  id="close-modal"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div class="form-group">
                  <label for="" className="m-1">
                    Space Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder="Enter space name"
                    maxLength="55"
                    {...register("space_name")}
                  />
                  {errors.space_name && (
                    <div className="redP">{errors.space_name.message}</div>
                  )}
                </div>
              </div>

              <div className="modal-footer">
                {/* <button
                        
                                className="btn btn-primary"
                                type="submit"
                            >
                                Submit
                            </button> */}
                {loadingM ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Loading...</span>
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default withAuth(SpaceProjectList);
