import { useState } from "react";
import React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Backdrop, CircularProgress } from "@mui/material/index";
import { toast } from "react-toastify";
import {
  CalcualteImperialDuctDiameter,
  calculateAspectRatio,
  calculateDia,
  calculateDiameter,
  calculateDiameterFromFriction,
  calculateDiameterFromVelocity,
  calculateDuctWidth,
  calculateFlowArea,
  calculateFrictionFromVelocity,
  calculateFrictionLoss,
  calculateFrictionLossByAirAndDia,
  calculateVelo,
  calculateVelocity,
  calculateVelocityFromDiameter,
  getwidthImperial,
} from "../../utils/helper";
const Unit = {
  SI: "SI",
  IMPERIAL: "Imperial",
};




const FreeCalculator = () => {
  const [Cbunit, setCbunit] = useState(Unit.SI);
  const [selectedDuct, setSelectedDuct] = useState({
    "id": 0,
    "frictionLoss": "",
    "velocity": "",
    "width": "",
    "height": 200,
    "lockStatus": false,
    "name": " ",
    "airQuantity": "",
    "velocityStatus": false,
    "velocityinputStatus": false,
    "diaMeter": "",
    "flowArea": "",
    "width1": "",
    "ratio1": "",
    "width2": "",
    "ratio2": "",
    "width3": "",
    "ratio3": "",
    "width4": "",
    "ratio4": "",
    "width5": "",
    "ratio5": "",
    "width6": "",
    "ratio6": "",
    "frictioninputStatus": false,
    "frictionStatus": false
  });
  const [loading, setLoading] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);

 const [isDisable ,setIsDisable] = useState(false)

  const handleMainCalculation = () => {

    setIsCalculating(true);

 


    // if (!selectedDuct?.name) {
    //   toast.error("Enter duct name.");
    //   return
    // }
    if (!selectedDuct.height) {
      toast.error("Enter height.");
      return
    }
    try {
      setLoading(true);
      if (Cbunit === Unit.SI) {
        handleCalculations();
      } else {
        handleImperialCalculation();
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleCalculations = () => {
  
    let h = 200;
    const { airQuantity, lockStatus, frictionLoss, velocity, height } =
      selectedDuct || {};
    let selectedDuct2 = { ...selectedDuct };

    if (!airQuantity || isNaN(parseFloat(airQuantity))) {
      toast.dismiss(); 
      toast.error("Please enter air quantity between 1 to 10000.");
      return;
    }
    if (airQuantity <= 0 || airQuantity > 10000) {
      toast.dismiss(); 
      toast.error("Please enter air quantity between 1 to 10000");
      return;
    }
    if (selectedDuct?.frictionLoss  && selectedDuct?.velocity) {
      toast.error("Provide either friction loss or velocity.");
      return;
  }
//   if (selectedDuct?.width  ==  0 ||  selectedDuct?.width == "0.00") {
//     toast.error("width cannot be zero.");
//     return;
// }
    if ((frictionLoss === 0 || frictionLoss === "0" || selectedDuct?.frictionLoss == "0.0" || selectedDuct?.frictionLoss === "0.00" || selectedDuct?.frictionLoss === "0") && !lockStatus) {
      toast.error("Friction loss cannot be 0.");

      return;
    }
    if ((velocity === 0 || velocity === "0" || selectedDuct?.velocity == "0.0" || selectedDuct?.velocity === "0.00" || selectedDuct?.velocity === "0") && !lockStatus) {
      toast.error(" Velocity loss cannot be 0.");
      return;
    }
    const flow = airQuantity;
    let dia = 0,
      fr = 0,
      velo = 0;

    if (!lockStatus) {
      if (!frictionLoss && !velocity) {
        toast.error("Provide either friction loss or velocity");
        return;
      }
      if ((frictionLoss <= 0 || frictionLoss == undefined) && !velocity) {
        toast.error("Friction loss must be greater than zero.");
        return;
      }
      if ((velocity <= 0 || velocity == undefined) && !frictionLoss) {
        toast.error("Velocity must be greater than zero.");
        return;
      }

      if (frictionLoss) {
        fr = frictionLoss;
        dia = calculateDiameterFromFriction(flow, fr);
        velo = calculateVelocityFromDiameter(flow, dia, fr);
      } else if (velocity) {
        velo = velocity;
        dia = calculateDiameterFromVelocity(flow, velo);
        fr = calculateFrictionFromVelocity(velo, dia);
      }

      const r = 50;
      const w = calculateDuctWidth(dia, height, r);
      const veloResult =
        frictionLoss || isNaN(frictionLoss)
          ? calculateVelo(fr, calculateDia(fr, flow)).toFixed(2)
          : velo;

      selectedDuct2 = {
        ...selectedDuct2,
        width: w,
        diaMeter: Math.round(dia * 1000),
        velocity: veloResult,
        frictionLoss: typeof fr === "number" ? fr?.toFixed(2) : fr,
        flowArea: flow > 0 || velo > 0 ? (flow / 1000 / velo).toFixed(2) : null,
      };

      // console.log(selectedDuct2)

      for (let i = 1; i <= 6; i++) {
        const hi = [250, 300, 350, 400, 500, 600][i - 1];
        selectedDuct2[`width${i}`] = calculateDuctWidth(dia, hi, r);
        selectedDuct2[`ratio${i}`] = calculateAspectRatio(
          selectedDuct2[`width${i}`],
          hi
        );
      }
      setIsDisable(true);
    }

    if (lockStatus && !selectedDuct.width && !height && !h) {
      const r = 50;
      dia = (1.3 * Math.pow(r * h, 0.625)) / Math.pow(r + h, 0.25);
      fr =
        (0.9 * Math.pow(0.0344198, 4.86) * Math.pow(flow, 1.82)) /
        Math.pow(dia / 1000, 4.86);

      selectedDuct2 = {
        ...selectedDuct2,
        diaMeter: Math.round(dia),
        frictionLoss: fr.toFixed(2),
        velocity: Math.round(
          10.91 * Math.pow(fr, 0.54) * Math.pow(dia / 1000, 0.67) * 1,
          1
        ),
        flowArea: (flow / 1000 / selectedDuct2.velocity).toFixed(2),
      };
    }

    selectedDuct2.frictioninputStatus = selectedDuct.frictioninputStatus;
    selectedDuct2.frictionStatus = selectedDuct.frictionStatus;
    // console.log(selectedDuct2);
    setSelectedDuct(selectedDuct2);
  };

 console.log(selectedDuct,"imperial");
  const handleImperialCalculation = () => {

    const { name, airQuantity, frictionLoss, velocity, height ,lockStatus } =
      selectedDuct || {};
    let selectedDuct2 = { ...selectedDuct };
    let roughness = 0.9;


    if (!airQuantity || isNaN(parseFloat(airQuantity))) {
      toast.error("Please enter air quantity between 1 to 10000.");
      return;
    }


    if (airQuantity <= 0 || airQuantity > 10000) {
      toast.error("Please enter air quantity betweeen 1 to 10000");
      return;
    }

    if (!frictionLoss && !velocity) {
      toast.error("Provide either friction loss or velocity");
      return;
    }
    if (selectedDuct2?.frictionLoss  && selectedDuct2?.velocity) {
      toast.error("Provide either friction loss or velocity.");
      return;
  }
//   if (selectedDuct?.width  ==  0 ||  selectedDuct?.width == "0.00") {
//     toast.error("width cannot be zero.");
//     return;
// }
    if ((frictionLoss === 0 || frictionLoss === "0" || selectedDuct2?.frictionLoss == "0.0" || selectedDuct2?.frictionLoss === "0.00" || selectedDuct2?.frictionLoss === "0") && !lockStatus) {
      toast.error("Friction loss cannot be 0.");

      return;
    }
    if ((velocity === 0 || velocity === "0" || selectedDuct2?.velocity == "0.0" || selectedDuct2?.velocity === "0.00" || selectedDuct2?.velocity === "0") && !lockStatus) {
      toast.error(" Velocity loss cannot be 0.");
      return;
    }

    if (velocity) {
      const flow = airQuantity / 2.1188799;
      const FlowArea = calcuateImperialFlowArea();
      const RoundDuctDiameter = CalcualteImperialDuctDiameter(FlowArea);
      const frictionLoss = calculateFrictionLoss(
        airQuantity,
        velocity,
        roughness
      );
      let h = height;
      h *= 25.4;
      const area = flow / 1000 / (velocity / 196.8);
      const dia = Math.pow((area * 4) / Math.PI, 0.5);

      selectedDuct2 = {
        ...selectedDuct2,
        width: getwidthImperial(dia, h),
        diaMeter: RoundDuctDiameter,
        frictionLoss: frictionLoss?.toFixed(2),
        flowArea: FlowArea,
      };

      ["h1", "h2", "h3", "h4", "h5", "h6"].forEach((key, index) => {
        selectedDuct2[key] = (12 + index * 2) * 25.4;
        selectedDuct2[`width${index + 1}`] = getwidthImperial(
          dia,
          selectedDuct2[key]
        );
        const mmW = selectedDuct2[`width${index + 1}`];
        selectedDuct2[`ratio${index + 1}`] = calculateAspectRatio(
          mmW,
          12 + index * 2
        );
      });
    } else {
      const flow = airQuantity / 2.1188799727597;
      let h = height * 25.4;
      let fr = frictionLoss;
      fr *= 249.08890833333 / 30.48;
      const dia =
        0.0344198 * Math.pow(0.9 / fr, 1 / 4.86) * Math.pow(flow, 1.82 / 4.86);
      const velo = 10.91 * Math.pow(fr, 0.54) * Math.pow(dia, 0.67);
      const area = (Math.PI * Math.pow(dia, 2)) / 4;

      selectedDuct2 = {
        ...selectedDuct2,
        width: getwidthImperial(dia, h),
        diaMeter: Math.round(dia / 0.0254),
        velocity: Math.round(velo * 196.8),
        flowArea: (area / (0.0254 * 0.0254)).toFixed(1),
      };

      ["h1", "h2", "h3", "h4", "h5", "h6"].forEach((key, index) => {
        selectedDuct2[key] = (12 + index * 2) * 25.4;
        selectedDuct2[`width${index + 1}`] = getwidthImperial(
          dia,
          selectedDuct2[key]
        );
        const mmW = selectedDuct2[`width${index + 1}`];
        selectedDuct2[`ratio${index + 1}`] = calculateAspectRatio(
          mmW,
          12 + index * 2
        );
      });
    }

    setIsDisable(true);

    selectedDuct2.frictioninputStatus =
      selectedDuct.frictioninputStatus || false;
    selectedDuct2.frictionStatus = selectedDuct.frictioninputStatus;
    selectedDuct2.velocityinputStatus = !selectedDuct.frictioninputStatus;
    selectedDuct2.velocityStatus = !selectedDuct.frictioninputStatus;
    setSelectedDuct(selectedDuct2);
  };





  //Imeperial Code

  const calcuateImperialFlowArea = () => {
    const { airQuantity, velocity } = selectedDuct || {};
    if (!airQuantity || airQuantity == 0 || velocity == 0 || !velocity) {
      return 0;
    }
    return Math.round(
      airQuantity /
      2.1188799727597 /
      1000 /
      (velocity / 196.8) /
      (0.0254 * 0.0254)
    );
  };
  const getwidth = (d, h) => {
    console.log("getwidth")
    if (!d && !h) {
        return 0;
    }
    if (h == 0) {
        return 0;
    }
    let w = 10;
    while (
        -(1.3 * Math.pow(w * h, 0.625)) / Math.pow(w + h, 0.25) + d * 1000 >
        0.00000001
    ) {
        w += 1;
    }
    return w;
};
console.log(selectedDuct,"ooooooo")
function getwidthImperial(d, h) {
  if (!d && !h) {
    return 0;
  }
  if (h == 0) {
    return 0;
  }
  let w = 10;
  while (
    -(1.3 * Math.pow(w * h, 0.625)) / Math.pow(w + h, 0.25) + d * 1000 >
    0.00000001
  ) {
    w++;
  }
  w = Math.round(w / 50.8) * 2;
  return w;
}
  const calculateDuctWidth = (dia, h, r) => {
    if (
        dia == null ||
        isNaN(dia) ||
        dia === 0 ||
        h == null ||
        isNaN(h) ||
        r == null ||
        isNaN(r) ||
        r === 0
    ) {
        return;
    }

    const width = Math.round(getwidth(dia, parseInt(h)) / r) * r;
    return width;
};

  const handleFrictionLossChange = (event) => {
    const newValue = event.target.value;

    if (newValue == "") {
      setSelectedDuct(prevState => ({
        ...prevState,
        frictionLoss: ""
      }));
    }
    if (newValue !== "" && (newValue < 0 || newValue > 10000)) {
      return
    }

    setSelectedDuct((prevSelectedDuct) => {
      let updatedDuct = {
        ...prevSelectedDuct,
        frictionLoss: newValue,
        velocityStatus: newValue ? true : false,
        velocityinputStatus: newValue ? true : false,
        frictionStatus: prevSelectedDuct.frictionLoss !== "",
      };
      return updatedDuct
    });
  };

  const handleVelocityChange = (event) => {
    const newValue = event.target.value;
    if (newValue == "") {
      setSelectedDuct(prevState => ({
        ...prevState,
        velocity: ""
      }));
    }
    if (newValue !== "" && (newValue <= 0 || newValue > 10000)) {
      return
    }
    setSelectedDuct((prevSelectedDuct) => ({
      ...prevSelectedDuct,
      velocity: newValue,
      frictionStatus: newValue ? true : false,
      frictioninputStatus: newValue ? true : false,
      velocityinputStatus: false,
      velocityStatus: false,
    }));
  };


  const handleHeightChange = (e) => {
    const { name, value } = e.target;

    if (value !== "" && (value < 0 || value > 10000)) {
      return
    }

    if (selectedDuct?.width  ===  0 ||  selectedDuct?.width == "0.00") {
      toast.dismiss();
      toast.error("Width cannot be zero.");
      return;
  }
 

    if (selectedDuct.lockStatus === true) {
      // update only width when duct lock and height change and width change.
      if (Cbunit == Unit.SI) {
        handleCalculationforHeight(e.target);
      } else if (Cbunit == Unit.IMPERIAL) {
        imperialSliderCalculation(e.target);
      }
    } 
    else {
      // update only width when duct not lock and height change.
      let updatedWidth = selectedDuct.width;
      if (Cbunit === Unit.SI ) {
        updatedWidth = calculateDuctWidth(selectedDuct.diaMeter / 1000, value, 50);
      } else if (Cbunit === Unit.IMPERIAL) {
        updatedWidth = getWidthWithHeightInImperial(value, selectedDuct.airQuantity, selectedDuct.velocity, selectedDuct.width);
      }
      const payload = {
        ...selectedDuct,
        [name]: value,
        ["width"]: updatedWidth !== undefined ? updatedWidth : selectedDuct.width,
        ["velocityStatus"]: true,
      };
      setSelectedDuct(payload);
    }



  };



  const handleChangeValues = (e) => {
    const { value } = e.target;
    if (value !== "" && (value < 0 || value > 10000)) {
      return
    }

    setSelectedDuct(prevState => ({
      ...prevState,
      airQuantity: value
    }));
  };


  const handleCalculationforHeight = (e) => {
    try {
      const { airQuantity, height, width } = selectedDuct;

      if (!airQuantity || isNaN(parseFloat(airQuantity))) {
        toast.error("Please enter air quantity between 1 to 10000.");
        return;
      }

      if (airQuantity <= 0 || airQuantity > 10000) {
        toast.error("Please enter air quantity between 1 to 10000.");
        return
      }

      const inputValue = parseInt(e.value);
      const newHeight = e.name === "height" ? inputValue : height;
      const newWidth = e.name === "width" ? inputValue : width;

      const diameter = calculateDiameter(newWidth, newHeight);
      const velocity = calculateVelocity(airQuantity, diameter);
      const frictionLoss = calculateFrictionLossByAirAndDia(airQuantity, diameter);
      const flowArea = calculateFlowArea(airQuantity, velocity);


      setSelectedDuct({
        ...selectedDuct,
        width: newWidth,
        height: newHeight,
        diaMeter: diameter.toFixed(2),
        frictionLoss: frictionLoss.toFixed(2),
        velocity:velocity.toFixed(2),
        flowArea: flowArea
      })
    } catch (error) {
      console.error("Error in calculations:", error);
    }
  };

  function imperialSliderCalculation(e) {
    let { height, width, airQuantity } = selectedDuct;
    const inputHeight = e.name === "height" ? e.value : height;
    const inputWidth = e.name === "width" ? e.value : width;
    let w, h, dia, fr, velo, area, flow;
    w = inputWidth;
    h = inputHeight;
    flow = airQuantity / 2.1188799727597;
    w *= 25.4
    h *= 25.4
    dia = 1.3 * Math.pow(w * h, 0.625) / Math.pow(w + h, 0.25);
    fr = 0.9 * Math.pow(0.0344198, 4.86) * Math.pow(flow, 1.82) / Math.pow(dia / 1000, 4.86)
    velo = 10.91 * Math.pow(fr, 0.54) * Math.pow(dia / 1000, 0.67);
    area = (Math.PI * Math.pow((dia / 1000), 2) / 4);
    setSelectedDuct({
      ...selectedDuct,
      diaMeter: Math.round(dia / 25.4, 0),
      frictionLoss: (fr * 0.0040146307866177 * 30.48).toFixed(2),
      velocity:  Math.round(velo * 196.8, 0),
      flowArea: (area / (0.0254 * 0.0254)).toFixed(2),
      height: inputHeight,
      width: inputWidth
    })

    
  }








  const handleUnitSystem = (e) => {
    setIsCalculating(false);
    setCbunit(e.target.value);

    const payload = {
      ...selectedDuct,
      airQuantity: "",
      frictionLoss:"",
      velocity:"",
      height: e.target.value == Unit.SI ? 200 : 10,
      width: "",
      lockStatus: false,
      name: "",
      velocityStatus: false,
      velocityinputStatus: false,
      diaMeter: "",
      flowArea: "",
      width1: "",
      ratio1: "",
      width2: "",
      ratio2: "",
      width3: "",
      ratio3: "",
      width4: "",
      ratio4: "",
      width5: "",
      ratio5: "",
      width6: "",
      ratio6: "",
    };
    setSelectedDuct(payload);
  };

  const handleWidthChange = (e) => {

    const { name, value } = e.target;
    if (value !== "" && (value < 0 || value > 10000)) {
      return
    }

    if (selectedDuct.lockStatus === true) {
      if (Cbunit === Unit.SI) {
        handleCalculationforHeight(e.target);
      }
      else if (Cbunit === Unit.IMPERIAL) {
        imperialSliderCalculation(e.target);
      }
    }

  };


  const handleFrictionLossSliderChange = (e) => {
    const { name, value } = e.target;
    const payload = {
      ...selectedDuct,
      [name]: value,
      ["velocityStatus"]: true,
      ["velocityinputStatus"]: true,
    };
    setSelectedDuct(payload);

  };



  function getWidthWithHeightInImperial(h, air, velo, width) {
    console.log(h, air, velo, width)
    if(air == "" || velo == "" || width == ""  ||  h == ""){
      return ;
    }
    let flow = air / 2.1188799;
    let area = flow / 1000 / (velo / 196.8);
    let dia = Math.pow((area * 4) / Math.PI, 0.5);
    h = h * 25.4;
    width = getwidthImperial(dia, h);
    return width;
  }



  const marks = [
    {
      value: 0.1,
      label: "0.1",
    },
    {
      value: 0.3,
      label: "0.3",
    },
    {
      value: 0.5,
      label: "0.5",
    },
    {
      value: 0.7,
      label: "0.7",
    },

    {
      value: 0.9,
      label: "0.9",
    },
    {
      value: 1,
      label: "1",
    },
  ];

  const marksVelo = [
    {
      value: 0,
      label: "",
    },
    {
      value: 1,
      label: "",
    },
    {
      value: 2,
      label: "",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: "10",
    },
  ];

  const marksHeight = [
    {
      value: 100,
      label: "100",
    },
    {
      value: 197,
      label: "200",
    },
    {
      value: 301,
      label: "300",
    },
    {
      value: 398,
      label: "400",
    },
    {
      value: 501,
      label: "500",
    },
    {
      value: 599,
      label: "600",
    },
    {
      value: 702,
      label: "700",
    },
    {
      value: 799,
      label: "800",
    },
    {
      value: 903,
      label: "900",
    },
    {
      value: 1000,
      label: "1000",
    },
  ];

  const marksImp = [
    {
      value: 0.006,
      label: "0.01",
    },
    {
      value: 0.037,
      label: "0.03",
    },
    {
      value: 0.061,
      label: "0.06",
    },
    {
      value: 0.086,
      label: "0.08",
    },
    {
      value: 0.11,
      label: "",
    },
    {
      value: 0.122,
      label: "0.12",
    },
  ];

  const marksVeloImp = [
    {
      value: 98.4,
      label: "",
    },

    {
      value: 590.4,
      label: "590",
    },
    {
      value: 985,
      label: "985",
    },
    {
      value: 1390,
      label: "1390",
    },
    {
      value: 1968,
      label: "1970",
    },
  ];

  const marksHeightImp = [
    {
      value: 4,
      label: "4",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 12,
      label: "12",
    },
    {
      value: 16,
      label: "16",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 24,
      label: "24",
    },
    {
      value: 28,
      label: "28",
    },
    {
      value: 32,
      label: "32",
    },
    {
      value: 36,
      label: "36",
    },
    {
      value: 40,
      label: "40",
    },
  ];






  const handleLockDuctSize = () => {
    const payload = {
      ...selectedDuct,
      ratio1: "",
      ratio2: "",
      ratio3: "",
      ratio4: "",
      ratio5: "",
      ratio6: "",
      width1: "",
      width2: "",
      width3: "",
      width4: "",
      width5: "",
      width6: "",
      ["widthStatus"]: !selectedDuct.lockStatus ? false : true,
      ["frictionStatus"]: !selectedDuct.lockStatus ? true : false,
      ["velocityStatus"]: !selectedDuct.lockStatus ? true : false,
      ["frictioninputStatus"]: !selectedDuct.lockStatus ? true : false,
      ["velocityinputStatus"]: !selectedDuct.lockStatus ? true : false,
      ["lockStatus"]: !selectedDuct.lockStatus ? true : false,
    };
    setSelectedDuct(payload);
  };


  console.log(Cbunit,"===========")

  const handleRefresh = (e) => {
    setIsDisable(false);
    e.preventDefault();
    const payload = {
      ...selectedDuct,      
      ["airQuantity"]: "",
      ["diaMeter"]: "",
      ["flowArea"]: "",
      ["frictionLoss"]: "",
      ["velocity"]: "",
      ["frictionStatus"]: false,
      ["velocityStatus"]: false,
      ["frictioninputStatus"]: false,
      ["velocityinputStatus"]: false,
      ["width1"]: "",
      ["width2"]: "",
      ["width3"]: "",
      ["width4"]: "",
      ["width5"]: "",
      ["width6"]: "",
      ["height"]: Cbunit == "SI" ? 200 : 10,
      ["width"]: "",
      ["ratio1"]: "",
      ["ratio2"]: "",
      ["ratio3"]: "",
      ["ratio4"]: "",
      ["ratio5"]: "",
      ["ratio6"]: "",
    };
    setSelectedDuct(payload);

  };





  const handleKeyDown = (event) => {
    if (event.key === 'e') {
        event.preventDefault();
    }
    
    if (
      !(
        (event.key >= '0' && event.key <= '9') ||
        event.key === 'Backspace' ||
        event.key === 'Delete' ||
        event.key === 'ArrowLeft' ||
        event.key === 'ArrowRight' ||
        event.key === 'Home' ||
        event.key === 'End'
      )
    ) {
      event.preventDefault();
    }
};

// const handleKeyDown = (event) => {

//   if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
//     return;
//   } 
//   if (event.key === 'e') {
//     event.preventDefault();
//     return;
//   }

//    const isValidInput =
//     /[0-9]/.test(event.key) || // Numbers
//     event.key === 'Backspace' || // Backspace key
//     event.key === 'Delete' || // Delete key
//     event.key === '.'; // Decimal point

//    if (!isValidInput) {
//     event.preventDefault();
//     return;
//   }


//   // Concatenate the input key with the current value of each field and convert to float
//   // const airQuantityValue = parseFloat((selectedDuct.airQuantity + event.key).replace(/[^0-9.]/g, ''));
//   // const heightValue = parseFloat((selectedDuct.height + event.key).replace(/[^0-9.]/g, ''));
//   // const frictionLossValue = parseFloat((selectedDuct.frictionLoss + event.key).replace(/[^0-9.]/g, ''));
//   // const widthValue = parseFloat((selectedDuct.width + event.key).replace(/[^0-9.]/g, ''));
//   // const velocityValue = parseFloat((selectedDuct.velocity + event.key).replace(/[^0-9.]/g, ''));



//   // Check if any of the values exceed the range of 1 to 10000
//   // if (airQuantityValue > 10000 || heightValue > 10000 || frictionLossValue > 10000   ) {
//   //   event.preventDefault();
//   //   return;
//   // }
// };




  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>
      </Box>


      <section className="container-fluid PageTitle_banner_section">
        <div className="container">
          <h1 className="PageTitle_banner_heading">Duct Planner</h1>
        </div>
      </section>

      <section className="container-fluid Calculator_section  py-4">
        <div className="container">
          <div className="row Calculator_projectName_area align-items-center">


            <div className="col-12 col-lg-5 col-xl-4 col-md-5 mb-3">
              <div className="form-group">
                <label htmlFor="">Unit System</label>
                <select
                  name="Cbunit"
                  id=""
                  className="form-select"
                  value={Cbunit}
                  title="please remove all the calculation with previous unit system."
                  disabled={
                    buttonStatus ? true : false
                  }
                  onChange={(e) => handleUnitSystem(e)}
                >
                  <option value={Unit.SI}>SI</option>
                  <option value={Unit.IMPERIAL}>IMPERIAL</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className={`col-12 col-lg-12 mb-4  col-xl-12 
              `}
            >
              <div className="row align-items-start">
                <div className="col-12 col-xl-4 col-lg-6 col-md-12 mb-4">
                  <div className="Calculator_AirData_area">
                    <h5 className="Calculator_AirData_heading">Air Data</h5>
                    <div className="form-group mb-3">
                      <label htmlFor="">Duct Name</label>
                      <input
                        type="text"
                        name="name"
                        id=""
                        className={`form-control`}
                        value={selectedDuct?.name || ""}
                        placeholder=""
                        onChange={(e) => setSelectedDuct({ ...selectedDuct, name: e.target.value })}
                        disabled={buttonStatus}
                        maxLength={50}                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="" style={{ textTransform: "none" }}>
                        Air Quantity<span className="redP">*</span>{" "}
                        {Cbunit === Unit.SI
                          ? "( L/S )"
                          : "( CFM )".replace(/l/g, "L").replace(/s/g, "s")}
                      </label>

                      <input
                        type="number"
                        name="airQuantity"
                        id=""
                        className={`form-control no-arrow ${isCalculating && selectedDuct?.airQuantity === "" || isNaN(selectedDuct?.airQuantity) ? "error_msg_hp" : ""}`}
                        placeholder=""
                        value={selectedDuct?.airQuantity}
                        onChange={handleChangeValues}
                        disabled={buttonStatus}
                        onKeyDown={handleKeyDown}

                      />

                    </div>

                    <div className="form-group mb-3">

                      <label htmlFor="" style={{ textTransform: "none" }}>
                        Friction loss{" "}
                        {Cbunit === Unit.SI ? "( P" + "A" + "/M )" : "(IN/100FT)"}
                      </label>

                      <input
                        type="number"
                        value={selectedDuct?.frictionLoss}
                        onChange={handleFrictionLossChange}
                        className={`form-control no-arrow ${isCalculating && selectedDuct?.frictionLoss === "" ? "error_msg_hp" : ""}`}
                        disabled={
                          selectedDuct?.lockStatus
                            ? selectedDuct?.frictionStatus
                            : selectedDuct?.frictioninputStatus
                              ? true
                              : buttonStatus
                                ? true
                                : false
                        }
                        onKeyDown={handleKeyDown}
                      />

                      <Box sx={{ paddingTop: 1, marginLeft: 0, minWidth: 220 }}>
                        <Slider
                          aria-label="Custom marks"
                          defaultValue={Cbunit === Unit.SI ? 0.5 : 0.08}
                          min={Cbunit === Unit.SI ? 0.05 : 0.006}
                          max={Cbunit === Unit.SI ? 1 : 0.122}
                          step={Cbunit === Unit.SI ? 0.05 : 0.006}
                          valueLabelDisplay="auto"
                          marks={Cbunit === Unit.SI ? marks : marksImp}
                          name="frictionLoss"
                          value={selectedDuct?.frictionLoss || 0}
                          onChange={handleFrictionLossSliderChange}
                          disabled={
                            selectedDuct?.frictionStatus
                              ? true
                              : buttonStatus
                                ? true
                                : false
                          }
                        />
                      </Box>
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="">
                        Velocity &nbsp;
                        {Cbunit === Unit.SI ? "( m/s )" : "(FPM)"}
                      </label>
                      <input
                        type="number"
                        min=".1"
                        max="100"
                        onKeyDown={handleKeyDown}
                        value={selectedDuct?.velocity}
                        onChange={handleVelocityChange}
                        className={`form-control no-arrow ${(Cbunit === "SI" &&
                          (selectedDuct?.velocity < 3 ||
                            selectedDuct?.velocity > 9.5) &&
                          selectedDuct.velocity !== "") ||
                          (Cbunit === Unit.IMPERIAL &&
                            (selectedDuct?.velocity < 590 ||
                              selectedDuct?.velocity > 1771) &&
                            selectedDuct.velocity !== "")
                          ? "error_msg_hp"
                          : ""
                          } ${isCalculating && selectedDuct?.velocity == "" ? "error_msg_hp" : ""}`}
                        disabled={
                          selectedDuct?.lockStatus
                            ? selectedDuct?.velocityStatus
                            : selectedDuct?.velocityinputStatus
                              ? true
                              : buttonStatus
                                ? true
                                : false
                        }
                      />
                      <Box sx={{ paddingTop: 1, marginLeft: 0, minWidth: 220 }}>
                        <Slider
                          aria-label="Custom marks"
                          defaultValue={Cbunit === Unit.SI ? 5 : 98.4}
                          // min={Cbunit === Unit.SI ? 0.5 : 98.4}
                          min={Cbunit === Unit.SI ? 3 : 590}
                          max={Cbunit === Unit.SI ? 10 : 1968}
                          step={Cbunit === Unit.SI ? 0.5 : 98.4}
                          valueLabelDisplay="auto"
                          marks={Cbunit === Unit.SI ? marksVelo : marksVeloImp}
                          name="velocity"
                          value={selectedDuct?.velocity || 0}
                          onChange={handleVelocityChange}
                          disabled={
                            selectedDuct?.velocityStatus
                              ? true
                              : buttonStatus
                                ? true
                                : false
                          }
                        />
                      </Box>
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="">Duct Material</label>
                      <select name="" id="" className="form-select">
                        <option value="">Galvanized Steel</option>
                      </select>
                    </div>
                  </div>
                  <button
                    className="btn btn_viewDuct mx-1"
                    style={{ width: "46%" }}
                    onClick={() => handleMainCalculation()}
                  >
                    Calculate
                  </button>
                  <button
                    className="btn btn_viewDuct mx-1"
                    style={{ width: "46%" }}
                    onClick={handleRefresh}
                
                  >
                    Refresh
                  </button>
                </div>
                <div className="col-12 col-xl-4 col-lg-6 col-md-12 mb-4">
                  <div className="Calculator_AirData_area">
                    <h5 className="Calculator_AirData_heading">Duct Size</h5>
                    <div className="form-group mb-3">
                      <label htmlFor="">
                        Round Duct diameter{" "}
                        {Cbunit === Unit.SI ? "( mM )" : "( IN )"}
                      </label>
                      <input
                        type="text"
                        name="diaMeter"
                        id=""
                        className="form-control no-arrow"
                        placeholder=""
                        disabled
                        value={
                          selectedDuct?.diaMeter !== undefined && !isNaN(selectedDuct?.diaMeter)
                            ? selectedDuct?.diaMeter
                            : 0
                        }
                      />
                    </div>

                    <label
                      htmlFor=""
                      className=""
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                      }}
                    >
                      Rectangular Duct Dimensions{" "}
                      {Cbunit === Unit.SI ? "( MM )" : "( IN )"}
                    </label>

                    <div className="form-group mt-2 mb-2">
                      <label htmlFor="">Width {Cbunit === Unit.SI ? "( mM )" : "( IN )"}</label>
                      <input
                        type="number"
                        min="100"
                        max="1000"
                        name="width"
                        onKeyDown={handleKeyDown}
                        value={selectedDuct?.width}
                        onChange={handleWidthChange}
                        className={`form-control no-arrow ${(Cbunit === Unit.SI &&
                          (selectedDuct?.width > selectedDuct?.height * 4 ||
                            selectedDuct?.width < selectedDuct?.height / 4) &&
                          selectedDuct?.width !== "") ||
                          (Cbunit === Unit.IMPERIAL &&
                            (selectedDuct?.width > selectedDuct?.height * 4 ||
                              selectedDuct?.width < selectedDuct?.height / 4) &&
                            selectedDuct?.width !== "")
                          ? "error_msg_hp"
                          : ""
                          }`}
                        disabled={
                          !selectedDuct.lockStatus
                        }
                      />

                      <Box
                        sx={{
                          paddingTop: 1,
                          marginLeft: 0,
                          minWidth: 220,
                        }}
                      >
                        <Slider
                          aria-label="Custom marks"
                          defaultValue={Cbunit === Unit.SI ? 500 : 8}
                          min={Cbunit === Unit.SI ? 100 : 4}
                          max={Cbunit === Unit.SI ? 1000 : 40}
                          step={Cbunit === Unit.SI ? 6 : 1}
                          valueLabelDisplay="auto"
                          marks={
                            Cbunit === Unit.SI ? marksHeight : marksHeightImp
                          }
                          name="width"
                          style={{ fontSize: 11 }}
                          value={selectedDuct?.width}
                          onChange={handleWidthChange}
                          disabled={
                            !selectedDuct.lockStatus
                          }
                        />
                      </Box>
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="">height {Cbunit === Unit.SI ? "( mM )" : "( IN )"}</label>
                      <input
                        type="number"
                        name="height"
                        value={selectedDuct?.height}
                        onChange={handleHeightChange}
                        className="form-control no-arrow"
                        disabled={buttonStatus}
                        onKeyDown={handleKeyDown}
                      />

                      <Box
                        sx={{
                          paddingTop: 1,
                          marginLeft: 0,
                          minWidth: 220,
                        }}
                      >
                        <Slider
                          aria-label="Custom marks"
                          defaultValue={Cbunit === Unit.SI ? 500 : 12}
                          min={Cbunit === Unit.SI ? 100 : 4}
                          max={Cbunit === Unit.SI ? 1000 : 40}
                          step={Cbunit === Unit.SI ? 6 : 1}
                          valueLabelDisplay="auto"
                          marks={
                            Cbunit === Unit.SI ? marksHeight : marksHeightImp
                          }
                          name="height"
                          value={selectedDuct?.height}
                          onChange={handleHeightChange}
                          disabled={
                            selectedDuct?.heightStatus
                              ? true
                              : buttonStatus
                                ? true
                                : false
                          }
                        />
                      </Box>
                    </div>

                    <button
                      className="btn m-auto btn_lock_Duct mb-3"
                      onClick={() =>
                        handleLockDuctSize(selectedDuct?.lockStatus)
                      }
                      title={
                        selectedDuct?.lockStatus
                          ? "Unlock duct size to calculate friction loss and velocity"
                          : "Lock duct size to calculate friction loss and velocity"

                      }
                      disabled={buttonStatus || !isDisable}
                    >
                      {selectedDuct?.lockStatus ? (
                        <>
                          <i className="fa-solid fa-lock me-2"></i>
                          Unlock Duct Size
                        </>
                      ) : (
                        <>
                          <i className="fa-solid fa-unlock me-2"></i>
                          Lock Duct Size
                        </>
                      )}
                    </button>

                    <div className="form-group mb-3">
                      <label htmlFor="">
                        Flow area {Cbunit === Unit.SI ? "( m² )" : "( IN² )"}
                      </label>
                      <input
                        type="text"
                        name="flowArea"
                        id=""
                        className="form-control no-arrow"
                        placeholder=""
                        value={!isNaN(selectedDuct.flowArea) ? selectedDuct.flowArea || "" : ""}
                        disabled
                      />
                    </div>
                  </div>

                </div>
                <div className="col-12 col-xl-4 col-lg-6 col-md-12 mb-4">
                  <div className="Calculator_AirData_area">
                    <h5 className="Calculator_AirData_heading">
                      Possible sizing
                    </h5>
                    <div>
                      {Cbunit === Unit.SI ? (
                        <>
                          <div className="Possible_sizing_heading mb-3 mt-4 ml-0 mr-0">
                            <div>
                              Width <br /> <small>( MM )</small>
                            </div>
                            <div>X</div>
                            <div>
                              Height <br /> <small>( MM )</small>
                            </div>
                            <div>Ratio</div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width1 !== undefined &&
                                selectedDuct?.width1 !== ""
                                ? selectedDuct?.width1
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 250</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio1 !== undefined &&
                                selectedDuct?.ratio1 !== ""
                                ? selectedDuct?.ratio1
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width2 !== undefined &&
                                selectedDuct?.width2 !== ""
                                ? selectedDuct?.width2
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 300</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio2 !== undefined &&
                                selectedDuct?.ratio2 !== ""
                                ? selectedDuct?.ratio2
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width3 !== undefined &&
                                selectedDuct?.width3 !== ""
                                ? selectedDuct?.width3
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 350</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio3 !== undefined &&
                                selectedDuct?.ratio3 !== ""
                                ? selectedDuct?.ratio3
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width4 !== undefined &&
                                selectedDuct?.width4 !== ""
                                ? selectedDuct?.width4
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 400</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio4 !== undefined &&
                                selectedDuct?.ratio4 !== ""
                                ? selectedDuct?.ratio4
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width5 !== undefined &&
                                selectedDuct?.width5 !== ""
                                ? selectedDuct?.width5
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 500</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio5 !== undefined &&
                                selectedDuct?.ratio5 !== ""
                                ? selectedDuct?.ratio5
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data">
                            <div>
                              {selectedDuct?.width6 !== undefined &&
                                selectedDuct?.width6 !== ""
                                ? selectedDuct?.width6
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 600</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio6 !== undefined &&
                                selectedDuct?.ratio6 !== ""
                                ? selectedDuct?.ratio6
                                : ""}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="Possible_sizing_heading mb-3 mt-4">
                            <div>
                              Width <br /> <small>( IN )</small>
                            </div>
                            <div>X</div>
                            <div>
                              Height <br /> <small>( IN )</small>
                            </div>
                            <div>Ratio</div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width1 !== undefined &&
                                selectedDuct?.width1 !== ""
                                ? selectedDuct?.width1
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 12</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio1 !== undefined &&
                                selectedDuct?.ratio1 !== ""
                                ? selectedDuct?.ratio1
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width2 !== undefined &&
                                selectedDuct?.width2 !== ""
                                ? selectedDuct?.width2
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 14</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio2 !== undefined &&
                                selectedDuct?.ratio2 !== ""
                                ? selectedDuct?.ratio2
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width3 !== undefined &&
                                selectedDuct?.width3 !== ""
                                ? selectedDuct?.width3
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 16</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio3 !== undefined &&
                                selectedDuct?.ratio3 !== ""
                                ? selectedDuct?.ratio3
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width4 !== undefined &&
                                selectedDuct?.width4 !== ""
                                ? selectedDuct?.width4
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 18</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio4 !== undefined &&
                                selectedDuct?.ratio4 !== ""
                                ? selectedDuct?.ratio4
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width5 !== undefined &&
                                selectedDuct?.width5 !== ""
                                ? selectedDuct?.width5
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 20</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio5 !== undefined &&
                                selectedDuct?.ratio5 !== ""
                                ? selectedDuct?.ratio5
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data">
                            <div>
                              {selectedDuct?.width6 !== undefined &&
                                selectedDuct?.width6 !== ""
                                ? selectedDuct?.width6
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 22</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio6 !== undefined &&
                                selectedDuct?.ratio6 !== ""
                                ? selectedDuct?.ratio6
                                : ""}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FreeCalculator;
