import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { API_URL } from '../../Services/UserServices';
// import { toast } from 'react-toastify';

import verify_email from '../../assets/Email_verify.svg';
import api from '../../Services/ApiService';

const EmailVerification = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [dataVerify, setDataVerify] = useState('');

    useEffect(() => {
        const getTokenFromUrl = () => {
            return new URLSearchParams(location.search).get('token');
        };
        // Function to verify the token
        const verifyToken = async (token) => {
            try {
                const response = await api.get(
                    `/user/verify-token/${token}`);
                if (response.status === 200) {
                    // toast.success('Token verified successfully');
                    setDataVerify("Email verified successfully")
                    // navigate('/login');
                }
            } catch (error) {
                console.error('Error verifying token', error);
                // toast.error(error?.message || "Error verifying token");
                if (error?.response?.status === 404) {
                    setDataVerify("Verification failed")
                } else {
                    setDataVerify("Verification Failed")
                }
            }
        };

        const token = getTokenFromUrl();
        if (token) {
            verifyToken(token);
        } else {
            console.error('No token found in URL');
            // Handle the case where there is no token in the URL
        }
    }, [location, navigate]);


    const handleButton = () => {
        window.location.href = "/login";

    }
    return (
        <>
            {/* <section className="container-fluid PageTitle_banner_section">
                <div className="container">
                    <h1 className="PageTitle_banner_heading">Email Verify</h1>
                </div>
            </section> */}

            <section className="container-fluid Email_verify_section my-5 pt-5">
                <div className="container">
                    {dataVerify === "" ? ( <h1 className=""> Verifying your account... </h1>) :
                        (<h1 className=''>{dataVerify}</h1>)}
                    <div className='mx-auto text-center'>
                        <img src={verify_email} />

                    </div>
                    <div className='text-center'>
                        <button onClick={handleButton} className='mt-3'>Go to login page </button>
                    </div>
                </div>
            </section>

            {/* <div style={{ height: '306px', paddingTop: "120px" }} className='text-center container' >
                {dataVerify === "" ? (<div> Verifying your account... </div>) :
                    (<div className=''>{dataVerify}</div>)}
            </div> */}

        </>
    );
};

export default EmailVerification;
