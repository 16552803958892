import React from 'react'
import { useState, useEffect } from 'react';
// import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../provider/AuthProvider';
import Swal from 'sweetalert2';
// import { API_URL } from '../../Services/UserServices';
import api from "../../Services/ApiService";
import { useLocation } from 'react-router-dom';
// import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setArrary } from '../../Redux/Reducer/planSlice';



const Pricing = () => {


  const [SubscriptionPlans, setSubscriptionPlans] = useState([]);
  
  const Dispatch = useDispatch()

  const { user } = useAuth()
 
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);



  const FetchSubscriptionPlan = async () => {
    setLoading(true)

    try {
      const response = await api.get(`/subscription/getAll`);

      if (response?.data?.statusCode === 200) {

        setSubscriptionPlans(response?.data?.data);
        setLoading(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Pricing Not found.",
        });
        setLoading(false);
      }
    } catch (error) {     
      Swal.fire({
        // icon: "error",
        // title: "Error",
        text: error.message == "Network Error" ? "Please check your internet connection.": error.message ,
      });
    }
    setLoading(false);

  };

  const getUserOne = async () => {
    setLoading(true);
    try {
      let user = JSON.parse(localStorage.getItem("user") ?? '{}');
      const { data: res } = await api.get("/user/getOne-user/" + user.id);

      if (res.statusCode == 200) {
        const currentObj = {
          ...user,
          "id": res?.data?.id,
          "name": user?.name,
          "email": user?.email,
          "role": user?.role,
          "paid": res?.data?.paid,
          "userSubs": res?.data?.userSubs,
          "isFirstTimeLogin": res?.data?.isFirstTimeLogin,
          "subscription": res?.data?.subscription,
          "token": user?.token,
          "isFreeUsed": currentPlan?.users?.isFreeUsed !== undefined ? currentPlan?.users?.isFreeUsed : user?.isFreeUsed
        }
        localStorage.setItem('user', JSON.stringify(currentObj));
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);          
      Swal.fire({
        text:  e.message == "Network Error" ? "Please check your internet connection.": e.message 
      });
    }
  };

  const [currentPlan, setCurrentPlan] = useState({});
  const getCurrentPlan = async () => {
    setLoading(true);
    try {
      const { data: res } = await api.get("/user/getPlanDetail/");
      if (res.statusCode == 200) {
        setCurrentPlan(res?.data)
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const location = useLocation();

  useEffect(() => {
    // Function to fetch subscription plans
    const fetchData = async () => {
      FetchSubscriptionPlan();

      // Reset the toggle button state when the URL changes
      // setMonthly(false);

      // Other cleanup logic if needed
    };

    fetchData();

    // Return a cleanup function
    return () => {
      // Additional cleanup logic if needed

      // Trigger a page reload
      window.location.reload();
    };
  }, [location.pathname]);


  useEffect(() => {
    // FetchSubscriptionPlan();

    if (user) {
      getUserOne();
      getCurrentPlan();
    }

  }, []);

  // useEffect(() => {
  //   if (!user) {
  //     navigate('/Signup');
  //   } else {
  //     FetchSubscriptionPlan();
  //     getUserOne();
  //   }
  // }, [user]);


  // const handleSelectPlan = async (index, plan) => {
  //   if (plan?.PlanType == 1) {
  //     setMonthly(true);
  //   } else if (plan?.PlanType == 2) {
  //     setMonthly(false);
  //   } else {
  //     setMonthly(false);
  //   }
  //   setSelectedPlan(SubscriptionPlans[index])
  //   // Prepare the payment data to be sent in the POST request
  // };

  // const processPayment = async (plan) => {

  //   if (!user) {
  //     // Swal.fire("Login required.").then(() => {
  //     // window.location.href = "Signup"; // Replace with the correct path to Login.js
  //     navigate('/register?redirect=/Pricing');
  //     // });
  //     return;
  //   }
  //   setLoading(true)

  //   const paymentData = {
  //     // amount: selectedPlan?.amount,
  //     amount: plan?.PlanType === 1 ? plan?.monthly : plan?.yearly,
  //     description: plan?.description,
  //     email: user?.email, // Replace with the actual email of the user
  //     subscriptionId: plan?.id,
  //     isYearly: plan?.PlanType === 1 ? false : true,
  //     // couponId: "2dc31475-d023-4c0e-96d3-117d62b7e549",
  //   };

  //   try {
  //     // Make the POST request to the payment API
  //     const { data } = await axios.post(`${API_URL}/payment/create-order`, paymentData, {
  //       headers: {
  //         Authorization: `Bearer ${user?.token}`
  //       }
  //     });
  //       if (data.message == "Coupon applied successfully.") {
  //       setLoading(false);
  //       navigate('/Project')
  //       return;
  //     }
  //     if (data.message == "Invalid coupon code.") {
  //       toast.error("Invalid coupon code");
  //       return;
  //     }
  //     // Check if the response status code is 200 (OK)
  //     if (data?.statusCode == 200 || data?.statusCode == 201) {
  //       // Handle the successful payment scenario here, e.g., show a success message, redirect to a success page, etc.
  //       window.location.href = data?.data?.approvalUrl;
  //       setLoading(false);

  //     }
  //     else {
  //       // Handle any other status codes (e.g., 4xx or 5xx) for error scenarios
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Payment Error',
  //         text: data?.message,
  //       });
  //       setLoading(false);
  //     }

  //   }
  //   catch (error) {

  //     if (error?.response?.status == 401) {
  //       localStorage.removeItem("user")
  //       window.location.href = '/Register';
  //     } else {
  //       Swal.fire(error?.message);
  //     }
  //     setLoading(false)
  //   }
  // }

  const handleBuyNow = (plan) => {
    if (!user) {
      navigate('/login?redirect=/Pricing');
      return;
    }
    Dispatch(setArrary(plan))
    navigate('/Checkout');
  }

  // const toogleBtn = () => {
  //   // setMonthly(!monthly);
  //   setMonthly((prevMonthly) => !prevMonthly);

  // };

  const freeUserSubscription = async () => {

    if (!user) {
      navigate('/login?redirect=/Pricing'); // Redirect to the sign-in page with a redirect query parameter
      return;
    }
    try {
      setLoading(true)
      const { data } = await api.get(`/payment/success-free-user`, {
        headers: {
          Authorization: `Bearer ${user?.token}`
        }
      });
      if (data?.statusCode == 200 || data?.statusCode == 201) {

        const user = JSON.parse(localStorage.getItem("user") ?? '{}');
        if (user) {
          user.paid = "paid";
          localStorage.setItem("user", JSON.stringify(user))
        }
        Swal.fire({
          title: 'You are Subcribed for 1-month',
        });
        setLoading(false);
        window.location.reload();
      }
      else if (data?.statusCode == 400) {
        Swal.fire({
          text: data?.message,
        });
        setLoading(false)
      }
      else {
        setLoading(false);
        navigate("/PaymentFailed")

      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("user")
        window.location.href = '/Register';
        setLoading(false);
      } else {
        Swal.fire( error.message == "Network Error" ? "Please check your internet connection.": error.message);
        setLoading(false);
      }

    }
    setLoading(false)
  };

  const freeSubscriber = () => {

    freeUserSubscription()

  }

  // function capitalizeName(fullName) {
  //   if (fullName) {
  //     const names = fullName.split(' ');
  //     const capitalizedNames = names.map(name => {
  //       return name.charAt(0).toUpperCase() + name.slice(1);
  //     });
  //     return capitalizedNames.join(' ');
  //   }
  //   return '';
  // }


  return (

    <div>
      <section className="container-fluid PageTitle_banner_section">
        <div className="container">
          <h1 className="PageTitle_banner_heading"> Pricing </h1>

        </div>
      </section>

      {/* <section className="container-fluid PageTitle_banner_section Pricing-select p-0">
        <div className="container">
          <h1 className="PageTitle_banner_heading">Pricing</h1>
        </div>
      </section> */}

      {/* <section className="container-fluid Pricing my-5">
        <div className="container">
          <div className="row mb-5 justify-content-center">
            <div className="col-md-12 mt-4">
              <div className="row justify-content-center">
                <div className="col-md-10 col-lg-6 text-center Protection_Plans">
                  <h2 className="fw-bold">Plans</h2>
                </div>
              </div>
            </div>
          </div> */}
      {/* <div className="row justify-content-center">
            {loading ? (
              
              <div></div>
            ) : SubscriptionPlans?.sort((a, b) => b?.amount > a?.amount ? -1 : 1).map((plan, index) => (

              <>{user?.paid == "paid" ? (plan?.packageName != "Free ") &&
                <div className="col-12 col-md-12 col-lg-12 col-xl-5 mt-0 ">
           

                </div>
                :
                <div className="col-12 col-md-6 col-lg-6 col-xl-4 mt-4 ">
                  
                </div>
                }

              </>
            ))}
          </div> */}
      {/* </div>

      </section> */}
      <section class="container-fluid Pricing">
        <div class="container">
          <div class="row mb-5 justify-content-center mx-auto">
            {loading ? (
              <div
                className="spinner-border"
                role="status"
              >
                <span className="visually-hidden">
                  Loading...
                </span>
              </div>
            ) : SubscriptionPlans?.sort((a, b) => b?.amount > a?.amount ? -1 : 1).map((plan, index) => (

              <div className="col-12 col-md-6 col-lg-6 col-xl-4 mt-4 ">
                <div className="card border-0 Free_Plans">
                  <div className="card-body">
                    <h3 className="text-center packageName">{plan?.packageName}</h3>

                    {plan?.packageName == "Free " ? <div class="text-center Month_heading my-4"><h3> One Time</h3></div> :
                      <div className="text-center my-4">

                        <div class="text-center Month_heading">
                          {plan?.PlanType == 1 ? (!user?.isFreeUsed ? <h3>{  "INR" + " " + plan?.monthly +" "}</h3> : <h3>{"INR" + " " + plan?.monthly }</h3>) : <h3>{"INR" + " " + plan?.yearly}</h3>}

                        </div>
                      </div>}

                    {/* <p className="text-center">{plan?.description}</p> */}
                    <div className="plans_list">
                      {plan?.features?.map((features, index) => (
                        <p key={index}><i class="fa-solid fa-check"></i> <span>{features} </span></p>))}
                    </div>
                  </div>
                  <div className="card-footer card-footer-Pricing ">




                    <div className="text-center">
                      {plan?.PlanType != 0 ? (
                        currentPlan?.subscriptionId == plan?.id ? currentPlan?.isExpired == false ? <button type="button" id="myButton"> Current Plan</button> : <button type="button" onClick={() => handleBuyNow(plan)}>Upgrade To</button> :
                          // <button className="btn btn-select" onClick={() => processPayment(plan)}>
                          <button className="btn btn-select" onClick={() => handleBuyNow(plan)}>
                            Buy Now
                            {/* {currentPlan?.isExpired == true && user?.paid == "unpaid" && user?.userSubs ? "Upgrade To" : "Buy Now"} */}
                          </button>
                      ) : (
                        currentPlan?.subscriptionId == plan?.id ? <button type="button" id="myButton">Current Plan</button> :
                          <button className="btn btn-select" onClick={freeSubscriber}
                            disabled={currentPlan?.users?.isFreeUsed !== (undefined && false ) || user?.isFreeUsed}>
                            { currentPlan?.users?.isFreeUsed !== (undefined && false ) ? "Expired" : user?.isFreeUsed ? "Expired" : "Try Now"}

                          </button>
                      )}
                    </div>

                    {/* <div className="text-center">                    
                      {plan?.PlanType != 0 ? (
                        currentPlan?.subscriptionId == plan?.id ? <button type="button" id="myButton">Current Plan</button> :
                          // <button className="btn btn-select" onClick={() => processPayment(plan)}>
                          <button className="btn btn-select" onClick={() => handleBuyNow(plan)}>                       
                          {!user?.isFirstTimeLogin && user?.paid == "unpaid" && user?.userSubs ? "Upgrade To" : "Buy Now"}
                          </button>
                      ) : (
                        currentPlan?.subscriptionId == plan?.id ? <button type="button" id="myButton">Current Plan</button> :
                          <button className="btn btn-select" onClick={freeSubscriber}
                            disabled={(!user?.isFirstTimeLogin && user?.paid == "unpaid" && user?.userSubs)}>
                            {!user?.isFirstTimeLogin && user?.paid == "unpaid"   && user?.userSubs ? "Expired" : "Try Now"}

                          </button>
                      )}
                    </div> */}


                  </div>
                </div>

              </div >
            ))}
          </div>
        </div>
      </section >
    </div >
  )
}

export default Pricing