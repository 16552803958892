import React from "react";
import logo from "../../assets/logo.svg";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../provider/AuthProvider";
// import Product from "../Pages/Product";
import Swal from "sweetalert2";
// import axios from "axios";
// import { API_URL } from "../../Services/UserServices";
import { useState } from "react";
import api from "../../Services/ApiService";
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { user, logout } = useAuth();
  let routes = [
    {
      id: 1,
      name: "Home",
      path: "/",
      Active: pathname === "/" ? "active" : "",
      show: true,
    },
    {
      id: 6,
      name: "Projects",
      path: "/Project",
      Active: pathname === "/Project" ? "active" : "",
      show:
        user &&
        (user.paid === "paid" ||
          (user.paid === "unpaid" && user?.userSubs === true) ||
          user.usertype == "paid" ||
          (user.usertype == "unpaid" && user.userSubs == true)),
    },
    {
      id: 2,
      name: "Free Calculator",
      path: "/FreeCalculator",
      Active: pathname === "/FreeCalculator" ? "active" : "",
      show: true,
      // show:
      //   user &&
      //   (user.paid !== "paid" ||
      //     (user.paid == "unpaid " && user?.userSubs == true)) &&
      //   !(user.usertype === "paid"),
    },
    //  {
    //   id: 6,
    //   name: "HVAC Load Calculator",
    //   path: "/HVACLoadCalculator",
    //   Active: pathname === '/HVACLoadCalculator' ? "active" : ""
    // },
    {
      id: 4,
      name: "Pricing",
      path: "/Pricing",
      Active: pathname === "/Pricing" ? "active" : "",
      show: true,
    },
    {
      id: 3,
      name: "About Us",
      path: "/About",
      Active: pathname === "/About" ? "active" : "",
      show: true,
    },

    {
      id: 5,
      name: "Contact Us",
      path: "/Contact",
      Active: pathname === "/Contact" ? "active" : "",
      show: true,
    },
    // {
    //   id : 6 ,
    //   name : "Profile",
    //   path : "/Profile",
    //   Active : pathname === '/Profile' ? "active" : ""
    //  }
  
  ];

  const [loading, setLoading] = useState(false);

  //  if (user && user.token !== "token") {
  //    routes.push({
  //      id: 4,
  //      name: "Pricing",
  //      path: "/Pricing",
  //      Active: pathname === '/Pricing' ? "active" : ""
  //    });
  //  }

  const handleFreeTrialClick = async () => {
    setHandleNav(true);
    if (!user) {
      //  Swal.fire({
      //    icon: 'error',
      //    title: 'Please Login First',
      //    text: 'You need to be logged in to access the free trial.',
      //  });
      navigate("/login");
    } else if (user.paid !== "paid") {
      try {
        setLoading(true);
        const { data } = await api.get(
          `/payment/success-free-user`,
          {
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          }
        );
        if (data?.statusCode == 200 || data?.statusCode == 201) {
          const user = JSON.parse(localStorage.getItem("user") ?? "{}");
          if (user) {
            user.paid = "paid";
            localStorage.setItem("user", JSON.stringify(user));
          }
          Swal.fire({
            title: "You are Subcribed for 1-month",
          });

          window.location.reload();
          navigate("/Project");
        } else if (data?.statusCode == 400) {
          Swal.fire({
            text: data?.message,
          });
        } else {
          navigate("/PaymentFailed");
        }
      } catch (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("user");
          window.location.href = "/login";
        } else {
          Swal.fire(error?.message);
        }
        setLoading(false);
      }
    }
  };

  // Get the token from local storage

  const [handleNav, setHandleNav] = useState(true);
  const handleNavBar = () => {
    setHandleNav(true);
  };

  const handleNavBarStatus = () => {
    setHandleNav(false);
  };

  const handlePage = () => {
    setHandleNav(true);
  };
  // const capitalizeName = (name) => {
  //   if (name) {
  //     const names = name.split(" ");
  //     const capitalizedNames = names.map(
  //       (n) => n.charAt(0).toUpperCase() + n.slice(1)
  //     );
  //     return capitalizedNames.join(" ");
  //   }
  //   return "";
  // };
  return (
    <div>
      <header className="container-fluid header_section">
        <div className="container">
          <nav className="navbar navbar-expand-lg header_section_hp">
            <Link className="navbar-brand logo_area p-0" to="/">
              <img src={logo} alt="logo"/>
            </Link>
            <a
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleNavBarStatus}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  fill="black"
                  d="M4 6a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1m0 12a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1m7-7a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2z"
                />
              </svg>
            </a>
            <div
              className={`collapse navbar-collapse ${handleNav ? "" : "show"}`}
              id="navbarScroll"
            >
              <ul className="navbar-nav me-auto  my-lg-0 navbar-nav-scroll align-items-start">
                {routes.map(({ id, name, path, Active, show }) =>
                  !show ? null : (
                    <li className="nav-item" key={name}>
                      <Link
                        className={`nav-link ${Active}`}
                        to={path}
                        id={id}
                        onClick={handleNavBar}
                      >
                        {name} <br />
                        <i className="fa-solid fa-circle"></i>
                      </Link>
                    </li>
                  )
                )}
              </ul>

              <div className="d-flex align-items-center nav_right_side">
                {(!user || (user && !user?.userSubs)) && (
                  <ul className="navbar-nav my-2 my-lg-0 navbar-nav-scroll">
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        style={{ cursor: "pointer" }}
                        onClick={handleFreeTrialClick}
                       >
                        Free Trial
                      </a>
                    </li>
                  </ul>
                )}
                {user ? (
                  <div className="d-flex align-items-center">
                    {/* <span className="me-2 text-white">{user?.name}</span> */}

                    <div className="dropdown After_login_dropdown_profile_area_hp">
                      <button
                        className="btn btn_signin dropdown-toggle m-0 "
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {" "}
                        <i className="fa fa-user me-1" aria-hidden="true"></i>
                        {/* {" " +
                          capitalizeName(user?.name || userData.firstName) +
                          " "} */}
                        {`${((user?.name || user?.firstName || "").charAt(0).toUpperCase() + (user?.name || user?.firstName || "").slice(1, 11))}${((user?.name || user?.firstName || "").length > 10 ? "..." : "")}`}

                      </button>
                      <ul
                        className="dropdown-menu "
                        aria-labelledby="dropdownMenuButton"
                      >
                        {/* {user.paid == "paid" || user.paid === 'unpaid' && user?.userSubs === true ? */}
                        {user ? (
                          <>
                            <li>
                              <a className="dropdown-item" href="/profile">
                                <i className="fa-solid fa-user me-2"></i> Profile
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={logout}
                                href="/login"
                                className="dropdown-item"
                              >
                                <i className="fa-solid fa-arrow-right-from-bracket me-2"></i>{" "}
                                Logout
                              </a>
                            </li>{" "}
                          </>
                        ) : (
                          <>
                            <li>
                              <a
                                onClick={logout}
                                href="/login"
                                className="dropdown-item"
                              >
                                <i className="fa-solid fa-arrow-right-from-bracket me-2"></i>{" "}
                                Logout
                              </a>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <Link
                    to="login"
                    className="btn btn_signin"
                    onClick={handlePage}
                  >
                    Sign In
                  </Link>
                )}
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Header;
