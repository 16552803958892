import React from 'react'
import about_img from '../../assets/about.jpg'
import mission from '../../assets/Mission_Vision_img.png'
import counting_Cloud from '../../assets/ProjectSavedCloud_icon.png'

function About() {
    return (
        <>
            <section className="container-fluid PageTitle_banner_section">
                <div className="container">
                    <h1 className="PageTitle_banner_heading">About Us</h1>
                </div>
            </section>

            <section className="container-fluid about_section">
                <div className="container">
                    <div className="row py-5">
                        <div className="col-xl-5 col-lg-5 col-md-12">
                            <div className="about_img_area">
                                <img src={about_img} alt="Image" />
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-12">
                            <div className="about_content_area">
                                <p className="about_content_heading_taglin mb-0">{""} </p>
                                <h2 className="h2">History of Our Company</h2>
                                <p>
                                    Established in 2014, our company, Microlent, embarked on a journey fueled by a vision to revolutionize the technology landscape. What began as a small team of passionate innovators has since blossomed into a beacon of digital excellence.
                                </p>
                                <p>
                                    In our early years, we focused on honing our craft, dedicating countless hours to mastering the latest technologies and methodologies. With each project, we learned, evolved, and expanded our capabilities, laying the foundation for the dynamic company we are today.
                                </p>
                                <p>
                                    As the years passed, Microlent's reputation for delivering exceptional solutions grew, attracting a diverse clientele spanning industries and geographies. Our commitment to quality, innovation, and client satisfaction became the cornerstone of our success
                                </p>
                                <p>
                                    Throughout our history, we've weathered challenges, embraced change, and embraced growth. From navigating technological shifts to adapting to the ever-changing needs of our clients, we've remained steadfast in our pursuit of excellence.
                                </p>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='about_content_area'>
                                <p>
                                    Today, as we reflect on our journey, we take pride in the milestones we've achieved and the relationships we've built along the way. But our story is far from over. With each new day comes new opportunities to innovate, to inspire, and to shape the future of technology.
                                </p>
                                <p>
                                    As we look to the horizon, we remain driven by the same passion and determination that fueled our inception. Together, let's continue to write the next chapter of our company's history—one defined by innovation, resilience, and boundless possibility.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container-fluid Mission_Vision_section py-5">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xl-7 col-lg-7 col-md-12">
                            <div className="Mission_Vision_content">
                                <div className="Mission_Vision_heading mb-4">
                                    <h2 className="fw-bolder">Company Mission & Vision</h2>
                                    <p className="w-75 text-muted">We believe our warranties set us apart from our competitors and
                                        show our commitment to
                                        quality work and service.</p>
                                </div>

                                <div className="Mission_Vision_accordion accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header mb-2" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#Mission" aria-expanded="true" aria-controls="Mission">
                                                Company Mission
                                            </button>
                                        </h2>
                                        <div id="Mission" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>
                                                    At Microlent, our mission is to empower businesses through innovative technology solutions that drive growth, efficiency, and success. We are committed to understanding our clients' unique challenges and delivering tailor-made solutions that exceed expectations, foster innovation, and unlock new opportunities for our partners worldwide.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header mb-2" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#Vision" aria-expanded="false" aria-controls="Vision">
                                                Company Vision
                                            </button>
                                        </h2>
                                        <div id="Vision" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>
                                                    Our vision at Microlent is to be a global leader in technology innovation, shaping the future of digital transformation. We aspire to be recognized for our unwavering commitment to excellence, our relentless pursuit of innovation, and our dedication to making a positive impact on businesses and communities around the world.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header mb-2" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#Valuesssss" aria-expanded="false" aria-controls="Valuesssss">
                                                Company Values
                                            </button>
                                        </h2>
                                        <div id="Valuesssss" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p className='mb-2'>
                                                    <strong>Excellence:</strong> We strive for excellence in everything we do, from the quality of our work to the level of service we provide to our clients. We hold ourselves to the highest standards and continuously seek opportunities for improvement.
                                                </p>
                                                <p className='mb-2'>
                                                    <strong>Innovation:</strong>  Innovation is at the heart of what we do. We embrace creativity, curiosity, and forward thinking to develop solutions that push boundaries, solve complex problems, and drive meaningful change
                                                </p>
                                                <p className='mb-2'>
                                                    <strong>Integrity:</strong> Integrity is the foundation of our relationships—with our clients, our partners, and each other. We conduct ourselves with honesty, transparency, and ethical behavior in all aspects of our work.
                                                </p>
                                                <p className='mb-2'>
                                                    <strong>Collaboration:</strong> Collaboration is key to our success. We believe in the power of teamwork, communication, and collective expertise to achieve our goals and deliver exceptional results for our clients.
                                                </p>
                                                <p className='mb-2'>
                                                    <strong>Customer-Centricity: </strong> Our clients are at the center of everything we do. We listen to their needs, understand their challenges, and tailor our solutions to meet their specific requirements. Our ultimate goal is to exceed our clients' expectations and become their trusted technology partner.
                                                </p>
                                                <p className='mb-2'>
                                                    <strong>Continuous Learning: </strong> We embrace a culture of continuous learning and growth. We are committed to staying ahead of the curve, mastering new technologies, and expanding our skills to remain at the forefront of innovation in the ever-evolving technology landscape.
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5 col-md-12">
                            <div className="Mission_Vision_img">
                                <img src={mission} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container-fluid Software_about_banner">
                <div className="container">
                    <div className="row align-items-center justify-content-center">

                        <div className="col-xl-8 col-lg-10 col-md-12 mb-4">
                            <div className="Software_video_content text-center">
                                <h2 className="fw-bolder mb-3">About Duct Architect</h2>
                                <p>DuctArchitect is the ultimate HVAC design software, catering to both individuals and firms alike. It simplifies complex processes by offering effortless heat load calculations and intuitive duct design tools, minimizing errors and maximizing efficiency. With seamless collaboration features and comprehensive reporting, it ensures smooth project management and exceptional results. DuctArchitect's user-friendly interface makes it easy to use, allowing you to focus on creating flawless HVAC systems with reduced time and costs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default About;