/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
// import ForgetPassword from "./ForgetPassword";

// import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { loginUserAsync  } from "../../Redux/Reducer/signInReducer";
// import Swal from "sweetalert2"; // Import SweetAlert
// import { useAuth } from "../provider/AuthProvider";
import Sign_Up from "../assets/logo.svg";


// import { Link } from "react-router-dom";
import Spinner from "./common/Spinner";
// import { API_URL } from "../Services/UserServices";
// import Terms_condition from "./Pages/Terms_condition";
import { ToastContainer, toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
import api from "../Services/ApiService";

const schemaSignUp = yup.object({
  email: yup
    .string()
    // .email(" ")
    .required("Email required")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Email must be a valid"
    ),
  
  

  phoneNumber: yup
    .string().trim()
    .required("Phone number required").matches(/^[0-9]+$/, "Phone number must contain only digits")
    .min(8, "Phone number must be at least 8 characters long")
    .max(14, "Phone number must be at most 14 characters long"),


  firstName: yup
    .string().trim("First name must contain only alphabets ")
    .required("First name required")
    .min(3, "First name must be longer than or equal to 3 characters")
    .max(25, "First name must be shorter than or equal to 25 characters")
    .matches(
      /^[a-zA-Z]+$/,
      "First name must contain only alphabets and no spaces"
    ),
  lastName: yup
    .string().trim()
    .required("Last name required")
    .min(3, "Last name must be longer than or equal to 3 characters")
    .max(25, "Last name must be shorter than or equal to 25 characters")
    .matches(
      /^[a-zA-Z]+$/,
      "Last name must contain only alphabets and no spaces"
    ),

  password: yup
    .string()
    .required("Password required")
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password cannot exceed 20 characters")
    .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#*()_+,.\/;'":?><])[A-Za-z\d@$!%*?&^#*()_+,.\/;'":?><]+$/,
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#*()_+,.\/;'":?><[\]{}|\\])[A-Za-z\d@$!%*?&^#*()_+,.\/;'":?><[\]{}|\\]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match")
    .required("Confirm password required"),
});

function Register({ activeTab }) {


  const [loading, setLoading] = useState(false);
  const [registrationFailed, setRegistrationFailed] = useState(false);
  const [emailVerification, setEmailVerification] = useState(false);
  const navigate = useNavigate();


  const onSubmitHandlerSignUp = async (data) => {

    setLoading(true); // Set loading to true before making the API call
    if (!data.termsAndConditions) {
      // Show an error message using SweetAlert if the checkbox is not checked
      setRegistrationFailed(true);
      setLoading(false);
      return; // Return early, preventing the API call
    }
    let NewData = {
      firstName: `${data.firstName}`,
      lastName: `${data.lastName}`,
      mobileNo: `${data.phoneNumber}`,
      email: `${data.email}`,
      password: `${data.password}`,
    };
    // let userData = JSON.stringify(NewData)

    try {
      const response = await api.post(`/user/signup`, NewData);
       if (response.data.statusCode === 200) {
        // toast.success(response.data.message);

        // window.location.href = `/emailVerification/${true}`;
        // window.history.pushState({}, '', `/verify-email`);

        // window.location.href = "/verify-email";

        setEmailVerification(true);

        // setTimeout(() => {
        //   setEmailVerification(false);
        // }, 10000);
        resetSignUp();
        setTimeout(() => {
          navigate("/login");
        }, 9000);
        

      }

      if (response?.data?.statusCode == 400) {
        toast.error(response.data.message);

      }
      // Clear the form after successful submission
    } catch (error) {
      if (error.response?.data?.status === 400) {
        toast.error(error?.response?.data?.message);
        
      } else {
        // Handle other error responses
        toast.error(error?.response?.data?.message || "An error occurred during registration.");

      }
    }
    setLoading(false);
  };

  const {
    register: registerSignUp,
    handleSubmit: handleSubmitSignUp,
    reset: resetSignUp,
    formState: { errors: signUpErrors },
    watch,
  } = useForm({
    resolver: yupResolver(schemaSignUp),
  });
  const terms = () => {
    window.location.href = "Terms&Condition";
  };
  useEffect(() => {
    if (activeTab !== "signUp") {
      resetSignUp();
    }
  }, [activeTab, resetSignUp]);



  return (
    <div>


      <div className="container-fluid bg-login_page bg_Register_page">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-md-12 col-lg-7 col-xl-6">
              <div className="card card_custom_card">
                <div className="card-body card_custom">
                  <div className="text-center">
                    <a href="/"> <img src={Sign_Up} className="img-fluid logo_img" alt="" />
                    </a>
                  </div>
                  <div className="login_heading_Area">
                    <h3 className="fw-bold text-center my-2">Sign Up</h3>
                    <p className="bottom-nav_headeing">Welcome to Duct Architect!</p>
                  </div>
                  <form onSubmit={handleSubmitSignUp(onSubmitHandlerSignUp)}>
                    <div className="row">
                      <div className="col-md-6 mb-2">

                        <input
                          type="text"
                          className="form-control_custom form-control " placeholder="Enter first name"
                          maxLength="25"                           
                          autoComplete="true"
                          // onKeyDown={handleKeyDown}
                                                    onKeyDown={(e) => {
                            const allowedKeys = ['Backspace', 'Tab', 'Enter'];

                            if (!((e.key >= 'a' && e.key <= 'z') || (e.key >= 'A' && e.key <= 'Z') || allowedKeys.includes(e.key))) {
                              e.preventDefault();
                            }
                          }}

                          {...registerSignUp("firstName")}
                        />
                        <p className="font-14 ms-2" style={{ color: "red" }}>
                          {signUpErrors.firstName?.message}
                        </p>
                      </div>
                      <div className="col-md-6 mb-2">
                        {/* <label className="form-label">
                          Last Name<span className="redP">*</span>
                        </label> */}
                        <input
                          type="text"
                          className="form-control_custom form-control " placeholder="Enter last name"
                          maxLength="25"
                          autoComplete="true"
                          onKeyDown={(e) => {
                            const allowedKeys = ['Backspace', 'Tab', 'Enter'];

                            // Check if the key is a letter or one of the allowed keys
                            if (!((e.key >= 'a' && e.key <= 'z') || (e.key >= 'A' && e.key <= 'Z') || allowedKeys.includes(e.key))) {
                              e.preventDefault();
                            }
                          }}

                          {...registerSignUp("lastName")}
                        />
                        <p className="font-14 ms-2" style={{ color: "red" }}>
                          {signUpErrors.lastName?.message}
                        </p>
                      </div>
                      <div className="col-md-12 mb-2">
                        {/* <label className="form-label">
                          Phone Number<span className="redP">*</span>
                        </label> */}
                        <input
                          type="text"
                          className="form-control_custom form-control " placeholder="Enter phone number"
                          autoComplete="true"
                          onKeyDown={(e) => {
                            // const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, and enter
                            if (!allowedKeys.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}


                          {...registerSignUp("phoneNumber")}
                          maxLength="14"
                        />
                        <p className="font-14 ms-2" style={{ color: "red" }}>
                          {signUpErrors.phoneNumber?.message}
                        </p>
                      </div>
                      <div className="col-md-12 mb-2">
                        {/* <label className="form-label">
                          Email<span className="redP">*</span>
                        </label> */}
                        {/* <input
                          type="text"
                          className="form-control_custom form-control " placeholder="Email"
                          {...registerSignUp("email")}
                          maxLength="50"

                        /> */}
                        <input
                                                  type="text" placeholder="Enter email"
                          className="form-control form-control_custom"
                          autoComplete="true"
                          onKeyDown={(e) => {
                            const allowedKeys = [
                              "Backspace",
                              "Tab",
                              "Enter",
                            ];

                            if (
                              e.target.value.length >= 30 && !allowedKeys.includes(e.key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                          {...registerSignUp("email")}
                          maxLength="30"
                        />
                        <p className="font-14 ms-2" style={{ color: "red" }}>
                          {signUpErrors.email?.message}
                        </p>
                      </div>
                   
                      <div className="col-md-6 mb-2">
                        {/* <label className="form-label">
                          Password<span className="redP">*</span>
                        </label> */}
                        <input
                                                  type="password" placeholder="Enter password"
                          className="form-control_custom form-control "
                          autoComplete="true"
                          {...registerSignUp("password")}
                          maxLength="20"

                        />
                        <p className="font-14 ms-2" style={{ color: "red" }}>
                          {signUpErrors.password?.message}
                        </p>
                      </div>
                      <div className="col-md-6 mb-2">
                        {/* <label className="form-label">
                          Confirm Password<span className="redP">*</span>
                        </label> */}
                        <input
                          type="password"
                          className="form-control_custom form-control " placeholder="Enter confirm password"
                          maxLength="20"
                           autoComplete="true"
                          {...registerSignUp("confirm_password", {
                            required: true,
                            validate: (val) => {
                              if (watch("password") != val) {
                                return "Your passwords do no match";
                              }
                            },
                          })}

                        />
                        <p className="font-14 ms-2" style={{ color: "red" }}>
                          {signUpErrors.confirm_password?.message}
                        </p>
                      </div>
                      <div className="col-md-12 mb-2">
                        <div className="mb-2 form-check d-flex align-items-center ps-1">
                          <input
                            type="checkbox"
                            className="input_checkbox"
                            id="exampleCheck1"
                            {...registerSignUp("termsAndConditions")}
                            onChange={() => setRegistrationFailed(false)}
                          />
                          <label
                            className="form-check-label mb-0"
                            htmlFor="exampleCheck1"
                          >
                            <div className="form-check-label">
                              {"  "}
                              {/* I agree to the <span style={{ color: registrationFailed ? "#3f2a6b" : " " }}>Terms & Conditions</span><span className="redP">*</span> */}
                              I agree to the <span onClick={terms} style={{ color: "#003393", fontWeight: 600, cursor: "pointer" }}>Terms & Conditions</span><span className="redP">*</span>
                            </div>
                          </label>

                    
                       
                          
                          
                        
                        </div>
                        {registrationFailed && (
                            <p className="font-14 ms-2" style={{ color: "red" }}>
                              Please agree to the Terms & Conditions
                            </p>
                          )}

                      </div>
                    
                      <div>
                        {loading ? (
                          <Spinner />
                        ) : (
                          <button className="btn Sign_btn_submit w-100">Sign Up</button>
                        )}
                      </div>
                      {emailVerification && <div className="text-danger text-center mt-2">Please Verify Your Email </div>}

                    </div>
                    <p className="bottom-nav mt-3">Have an account? <a href="/login" > Login</a></p>

                  </form>
                </div>

              </div>

            </div>
          </div>
          <ToastContainer />

        </div>
      </div >



    </div>
  );
}

export default Register;
