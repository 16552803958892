import logo from "../../assets/white_logo.svg";
import React, { useEffect, useState } from "react";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);
  return (
    <div>
      <footer className="container-fluid footer_section">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-md-12 col-xxl-6 col-xl-6 col-lg-5 mb-4">
              <div className="footer_left_area">
                <img src={logo} className="footer_logo" alt="" />
                <ul>
                  <li>Design Smarter, Build Better with DuctArchitect.</li>
                  <li>This all-in-one software empowers HVAC professionals to:</li>
                  <li>Automate heat load calculations for accuracy and time savings.</li>
                  <li>Design optimized duct layouts with intuitive tools.</li>
                  <li>Collaborate seamlessly with cloud-based storage and features.</li>
                  <li>Gain valuable insights with comprehensive reports.</li>
                  <li>Reduce costs, boost efficiency, and design smarter. Try DuctArchitect today!</li>
                </ul>

                <div className="footer_socail_area">
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    className="footerLinks"
                  >
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=61557197305875/"
                    target="_blank"
                    className="footerLinks"
                  >
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                  <a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGBCDAMcox3YQAAAY4Sbv9AC5MOqI6rIldImG_I666Z6HPytTaTvadg4dhYJX_UYW_wT9d6SkpMBVugl9zsFdO-2XpS6YQgVyl949faNPwt7CqOPDh4PQSnPJmG994EN74LMLw=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fductarchitect/"
                    target="_blank"
                    className='footerLinks'>
                    <i className="fa-brands fa-linkedin-in"></i>
                  </a>
                  <a href="https://www.youtube.com/@DuctArchitect/" target="_blank" className='footerLinks'>
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xxl-2 col-xl-3 col-lg-3 mb-4">
              <div className="footer_right_area">
                <div className="mb-3 footer_right_heading_area">
                  <h2>quick Link</h2>
                  <div className="heading_underline"></div>
                </div>
                <div className="contact_info">
                  <div className="Quick_link_item">
                    <i className="fa-solid fa-angle-right"></i>
                    <a href="/" className="footerLinks2">
                      Home
                    </a>
                  </div>
                  <div className="Quick_link_item">
                    <i className="fa-solid fa-angle-right"></i>
                    <a href="/About" className="footerLinks2">
                      About Us{" "}
                    </a>
                  </div>
                  <div className="Quick_link_item">
                    <i className="fa-solid fa-angle-right"></i>
                    <a href="/Pricing" className="footerLinks2">
                      Pricing
                    </a>
                  </div>
                  <div className="Quick_link_item">
                    <i className="fa-solid fa-angle-right"></i>
                    <a href="/Contact" className="footerLinks2">
                      Contact Us
                    </a>
                  </div>
                  <div className="Quick_link_item">
                    <i className="fa-solid fa-angle-right"></i>
                    <a href="/Terms&Condition" target="blank" className="footerLinks2">
                      Terms & Conditions
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-xxl-4 col-xl-3 col-lg-4 mb-4">
              <div className="footer_right_area">
                <div className="mb-3 footer_right_heading_area">
                  <h2>Contact Info</h2>
                  <div className="heading_underline"></div>
                </div>
                <div className="contact_info">
                  <div className="Quick_link_item">
                    <i className="fa-solid fa-envelope"></i>
                    <a
                      href="mailto:ductarchitect@microlent.com"
                      className="footerLinks2"
                    >
                      ductarchitect@microlent.com
                    </a>
                  </div>
                  <div className="Quick_link_item">
                    <i className="fa-solid fa-phone"></i>
                    <a href="tel:9799941913" className="footerLinks2">
                      +91 9799941913{" "}
                    </a>
                  </div>
                  <div className="Quick_link_item">
                    <i className="fa-solid fa-location-dot"></i>
                    <a
                      href="https://maps.app.goo.gl/BeW6KENwRpZkHenQ9"
                      target="_Blank"
                      className="footerLinks2"
                    >
                      Manohar 5th Floor CYB -6 RIICO Cyber Park, near Saras
                      Dairy, HI Area Phase II, Jodhpur, Rajasthan 342003
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row footer_copy_right_section">
          <p style={{ fontSize: "14px" }}>
            © {currentYear} . Powered and Secured by{" "}
            <a href="https://microlent.com/" target="_Blank">
              Microlent Systems
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
