import React, { useEffect, useState } from 'react';
import withAuth from "../WithAuth";
// import axios from 'axios';
import Swal from 'sweetalert2';
// import { API_URL } from '../../Services/UserServices';
import { useAuth } from '../../provider/AuthProvider';
import api from '../../Services/ApiService';
import moment from 'moment';

function Profile() {

  const [profile, setProfile] = useState([])
  const [subsctiptionData, setSubsctiptionData] = useState([])
  const { user } = useAuth();

  const getProjectData = async () => {
    try {
      const response = await api.get(
        `/user/getOne/${user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if (response.data.statusCode === 200) {

        setProfile(response?.data?.data)

      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: e.message,
      });
    }

  }

  const getAllSubs = async () => {
    try {
      const response = await api.get(`/user-subscription/getAllByUserId/${user.id}`)
      console.log(response)
      if (response.data.statusCode == 200) {
        const approvedUsers = response?.data?.data?.users?.filter(user => user.status == 'Approved');
        setSubsctiptionData(approvedUsers);
      }
    }
    catch (e) {
      Swal.fire({
        icon: "error",
        text: e.message,
      });
    }
  }

  useEffect(() => {
    getProjectData();
    getAllSubs();
  }, []);


  return (
    <div>

      <section className="container-fluid PageTitle_banner_section">
        <div className="container">
          <h1 className="PageTitle_banner_heading">Profile</h1>
        </div>
      </section>


      <div className="container">

        <div className="profile_info_area my-5">
          <h4 className='profile_info_heading_hp'>Profile Information</h4>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-12 mb-3">
              <label className="form-label">Name</label>
              <input type="text" className="form-control" value={`${profile?.firstName ?? ""} ${profile?.lastName ?? ""}`}
                disabled />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-12 mb-3">
              <label className="form-label">Phone Number</label>
              <input type="text" className="form-control" value={profile?.mobileNo}

                disabled />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-12 mb-3">
              <label className="form-label">Email</label>
              <input type="text" className="form-control" value={profile?.email} disabled />
            </div>
          </div>
        </div>

        {subsctiptionData.length > 0 && (
          <div className="profile_info_area my-5">
            <h4 className='profile_info_heading_hp'>Subscription History</h4>
            <div className="row">
              <div className='col-12'>
                <div className='table-responsive'>
                  <table className="table border table-hover  mb-0 ">
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Plan Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>

                      </tr>
                    </thead>
                    <tbody>
                      {subsctiptionData?.map((item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>{item?.subscriptionId == "90" ? "Free Trial" : item?.subscriptionId == "94" ? "Monthly" : item?.subscriptionId == "95" ? "Yearly" : ""}</td>
                          <td>{moment(item.subscriptionDate).format(
                            "MMMM Do YYYY"
                          )}</td>
                          <td>{moment(item.expiryDate).format(
                            "MMMM Do YYYY"
                          )}</td>

                        </tr>
                      ))}
                    </tbody>

                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withAuth(Profile);
