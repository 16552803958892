import React from 'react'

const SolarGainWall = ({ register, errors }) => {
    return (
        <div className='space_input_detail_area mb-4'>
            <h4 className='space_input_detail_heading mb-3'>Solar & Trans. Gain Walls & Roof</h4>

            <div className='row'>
                <div className='col-xl-4 col-lg-6 col-md-6 col-12'>
                    <div className='space_input_detail_header_area Space_detai_header1'>
                        <div>Item</div>
                        <div>Area <samll>(Sq ft)</samll></div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>N - Glass</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }}  {...register("nTransGainWallGlass")} /><p style={{ color: "red" }}>{errors.nTransGainWallGlass?.message}</p> </div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>NE - Glass</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }}{...register("neTransGainWallGlass")} /> <p style={{ color: "red" }}>{errors.neTransGainWallGlass?.message}</p></div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>E - Glass</div>
                        <div> <input type="text" id="input" class="form-control" min="0"
                            onKeyDown={(e) => {
                                const inputValue = e.target.value;
                                const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                                // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                                if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                    e.preventDefault();
                                }
                            }}   {...register("eTransGainWallGlass")} /> <p style={{ color: "red" }}>{errors.eTransGainWallGlass?.message}</p> </div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>SE - Glass</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }} {...register("seTransGainWallGlass")} /><p style={{ color: "red" }}>{errors.seTransGainWallGlass?.message}</p> </div>
                    </div>
                </div>
                <div className='col-xl-4 col-lg-6 col-md-6 col-12'>
                    <div className='space_input_detail_header_area Space_detai_header2'>
                        <div>Item</div>
                        <div>Area <samll>(Sq ft)</samll></div>
                    </div>

                    <div className='space_input_detail_data_area'>
                        <div>S - Glass</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }} {...register("sTransGainWallGlass")} /> <p style={{ color: "red" }}>{errors.sTransGainWallGlass?.message}</p></div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>SW - Glass</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }}{...register("swTransGainWallGlass")} /> <p style={{ color: "red" }}>{errors.swTransGainWallGlass?.message}</p></div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>W - Glass</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }}  {...register("wTransGainWallGlass")} /><p style={{ color: "red" }}>{errors.wTransGainWallGlass?.message}</p> </div>
                    </div>
                </div>
                <div className='col-xl-4 col-lg-6 col-md-6 col-12'>
                    <div className='space_input_detail_header_area Space_detai_header3'>
                        <div>Item</div>
                        <div>Area <samll>(Sq ft)</samll></div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>NW - Glass</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }} {...register("nwTransGainWallGlass")} /> <p style={{ color: "red" }}>{errors.nwTransGainWallGlass?.message}</p> </div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>Roof Sun</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }}  {...register("roofSun")} /> <p style={{ color: "red" }}>{errors.roofSun?.message}</p> </div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>Roof Shaded</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }} {...register("roofShaded")} /><p style={{ color: "red" }}>{errors.roofShaded?.message}</p> </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SolarGainWall