import React, { useState } from 'react';
import Sign_Up from '../../assets/logo.svg';
import './ResetPassword.css';
// import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
// import { API_URL } from '../../Services/UserServices';
// import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Backdrop, Box, CircularProgress } from "@mui/material";


import { ToastContainer, toast } from 'react-toastify';
import api from '../../Services/ApiService';
const schemaResetPassword = yup.object({
  new_password: yup
    .string().required("Password required").trim()
    .min(8, "Password must be at least 8 characters")
    .max(32, "Password cannot exceed 32 characters")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#*()_+,.\/;'":?><[\]{}|\\])[A-Za-z\d@$!%*?&^#*()_+,.\/;'":?><[\]{}|\\]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),

  confirm_password: yup
    .string().trim().required("Confirm password required")
    .min(8, "Password must be at least 8 characters")
    .max(32, "Password cannot exceed 32 characters")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#*()_+,.\/;'":?><[\]{}|\\])[A-Za-z\d@$!%*?&^#*()_+,.\/;'":?><[\]{}|\\]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    )
    .oneOf([yup.ref("new_password"), null], "Password must match")
  ,
});


// const passwordStrengthRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

const ResetPassword = () => {
  // const [newPassword, setNewPassword] = useState('');
  // const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmitResetPassword = async (data) => {
    setLoading(true);
    try {
      //e.preventDefault();

      // Check if the new password and confirm password match
      // if (newPassword !== confirmPassword) {
      //   Swal.fire({
      //     title: 'Error!',
      //     text: 'New password and confirm password do not match.',
      //     icon: 'error',
      //     button: 'OK',
      //   });
      //   setLoading(false)
      //   return;
      // }

      // if (!passwordStrengthRegex.test(newPassword)) {
      //   Swal.fire({
      //     title: 'Error!',
      //     text: 'Password must be at least 8 characters and contain one uppercase letter, one digit, and one special character.',
      //     icon: 'error',
      //     button: 'OK',
      //   });
      //   setLoading(false)
      //   return;
      // }



      // Replace 'YOUR_API_ENDPOINT' with the actual endpoint of your server for the "Reset Password" API
      const response = await api.post(
        `/user/reset-password`,
        {
          email: localStorage.getItem('userEmail'),
          new_password: `${data.new_password}`,
        }
      );

      // Check if the response indicates success (modify the condition based on your API response structure)
      if (response.data.statusCode === 200) {
        // Show a success message to the user
        // Swal.fire({
        //   icon: 'success',
        //   title: 'Success!',
        //   text: "Password has been changed successfully.",
        //   button: 'OK',
        // });
        toast.success("Password has been changed successfully.");

        setTimeout(() => {
          navigate("/login");
        }, 2000);


        // Optionally, you can redirect the user to a different page after successful password reset
        // For example, you can use a navigation library like react-router-dom:
        // import { useHistory } from 'react-router-dom';
        // const history = useHistory();
        // history.push('/login'); // Redirect to the login page after password reset
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to reset password. Please try again later.',
          icon: 'error',
          button: 'OK',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: error.message,
        icon: 'error',
        button: 'OK',
      });
    }
    setLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const {
    register: resetPasswordForm,
    handleSubmit: handleResetPassword,
    reset: resetPasswordFrm,
    formState: { errors: resetPasswordErrors },
   } = useForm({
    resolver: yupResolver(schemaResetPassword),
  });

  return (
    <div>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress />
          </Backdrop>
        </Box>
      )
      }


      <div className="container-fluid bg-login_page bg_ForgetPassword_page">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-md-12 col-lg-7 col-xl-6">
              <div className="card card_custom_card">
                <div className="card-body card_custom">
                  <div className="text-center">
                    <a href="/">
                      <img src={Sign_Up} className="img-fluid logo_img" alt="" />
                    </a>
                  </div>
                  <div className="login_heading_Area">
                    <h3 className="fw-bold text-center my-2"> Create New Password</h3>
                    <p className="bottom-nav_headeing">Your new password must be different from previous used password.</p>
                  </div>
                  <form onSubmit={handleResetPassword(onSubmitResetPassword)}>
                    <div className="col-md-12 mb-2">
                      {/* <label className="form-label">New Password<span className="redP">*</span></label> */}
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        //value={newPassword}
                        //onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="Enter new password"
                        {...resetPasswordForm("new_password")}

                      />
                      <span onClick={togglePasswordVisibility} className='toggle-password'>
                        {/* {showPassword ? <FaEyeSlash /> : <FaEye />} */}
                      </span>
                      <p style={{ color: "red" }}>{resetPasswordErrors.new_password?.message}</p>
                    </div>
                    <div className="col-md-12 mb-2">
                      {/* <label className="form-label">Confirm Password<span className="redP">*</span></label> */}
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        className="form-control"
                        //value={confirmPassword}
                        //onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Enter Confirm new password"
                        {...resetPasswordForm("confirm_password")}
                      />
                      <span onClick={toggleConfirmPasswordVisibility} className='toggle-password-confirmBox'>
                        {/* {showConfirmPassword ? <FaEyeSlash /> : <FaEye />} */}
                      </span>
                      <p style={{ color: "red" }}>{resetPasswordErrors.confirm_password?.message}</p>
                    </div>
                    <div>
                      <button className="btn Sign_btn_submit w-100 mt-3" type="submit">
                        {loading ? (
                          <div
                            className="spinner-border"
                            role="status"
                          >
                            <span className="visually-hidden">
                              Loading...
                            </span>
                          </div>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </form>
                </div>

              </div>

            </div>
          </div>
          <ToastContainer />

        </div>
      </div >



      {/* <section className="container-fluid Sign-section  Sign-section-Password">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="card Sign-Up-card-section">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <h3 className="fw-bold">Reset Password</h3>
                     
                    </div>
                    <form onSubmit={handleResetPassword(onSubmitResetPassword)}>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">New Password<span className="redP">*</span></label>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          className="form-control"
                          placeholder="Enter new password"
                          {...resetPasswordForm("new_password")}

                        />
                        <span onClick={togglePasswordVisibility} className='toggle-password'>
                        </span>
                        <p style={{ color: "red" }}>{resetPasswordErrors.new_password?.message}</p>
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Confirm Password<span className="redP">*</span></label>
                        <input
                          type={showConfirmPassword ? 'text' : 'password'}
                          className="form-control"
                          //value={confirmPassword}
                          //onChange={(e) => setConfirmPassword(e.target.value)}
                          placeholder="Confirm new password"
                          {...resetPasswordForm("confirm_password")}
                        />
                        <span onClick={toggleConfirmPasswordVisibility} className='toggle-password-confirmBox'>
                        </span>
                        <p style={{ color: "red" }}>{resetPasswordErrors.confirm_password?.message}</p>
                      </div>
                      <div>
                        <button className="btn Sign_btn_submit" type="submit">
                          {loading ? (
                            <div
                              className="spinner-border"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                    </form>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="card Sign-Up-card-section-img">
                  <div className="card-body">
                    <img src={Sign_Up} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="card Sign-Up-card-section-img">
                <div className="card-body">
                  <img src={Sign_Up} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default ResetPassword;
