import React from 'react'

const HvacHeader = ({ register, errors ,projectName }) => {

    // const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    return (
        <div className='row'>
            <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-2'>
                <div class="form-group">
                    <label for="" className='m-1'>Project Name</label>
                    <input type="text" id="" class="form-control" value={projectName} {...register("projectName")} placeholder="" readOnly />
                    <p className="input_error_msg" style={{ color: "red" }}>{errors.projectName?.message}</p>
                </div>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-2'>
                <div class="form-group">
                    <label for="" className='m-1'>Space Name</label>
                    <input type="text" id="" class="form-control" {...register("space_name")} placeholder="" readOnly />
                    <p className="input_error_msg" style={{ color: "red" }}>{errors.space_name?.message}</p>
                </div>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-2'>
                <div class="form-group">
                    <label for="" className='m-1'>Size  (Sq ft)</label>
                    <input type="text" id="" class="form-control" placeholder="" min="0" onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                            e.preventDefault();
                        }
                    }} {...register("size_sqrft")} />
                    <p className="input_error_msg" style={{ color: "red" }}>{errors.size_sqrft?.message}</p>
                </div>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-2'>
                <div class="form-group">
                    <label for="" className='m-1'>Size  (Cu ft)</label>
                    <input type="text" id="" class="form-control" {...register("size_cuft")} placeholder="" readOnly />
                    <p className="input_error_msg" style={{ color: "red" }}>{errors.size_cuft?.message}</p>
                </div>
            </div>
            {/* <div className='col-xl-3 col-lg-3 col-md-6 col-12 mb-2'>
                            <div class="form-group">
                                <label for="" className='m-1'>Date</label>
                                <input type="date" id="" class="form-control" placeholder=""   min={today}   onKeyDown={(e) =>["e", "E", "+", "-","."].includes(e.key) && e.preventDefault()}  {...register("date")} />
                                <p className="input_error_msg" style={{ color: "red" }}>{errors.date?.message}</p>
                            </div>
                        </div> */}
            <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-2'>
                <div class="form-group">
                    <label for="" className='m-1'>Rev</label>
                    <input type="text" id="" class="form-control" pattern="\d{2}/\d{2}/\d{4}" placeholder="" readOnly onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}  {...register("rev")} />
                    <p className="input_error_msg" style={{ color: "red" }}>{errors.rev?.message}</p>
                </div>
            </div>
        </div>
    )
}

export default HvacHeader;