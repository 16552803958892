import React from "react";

const Condition = ({ register, errors }) => {
  return (
    <div className="space_input_detail_area mb-4">
      <h4 className="space_input_detail_heading mb-3">Condition</h4>

      <div className="row">
        {/* <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='space_input_detail_header_area Space_detai_header1'>
                <div>Item</div>
                <div>Area <samll>(Sq ft)</samll></div>
            </div>
            <div className='space_input_detail_data_area'>
                <div>Outside</div>
                <div> 
                    <input type="text" id="input" class="form-control" min="0"  onKeyDown={(e) =>["e", "E", "+", "-","."].includes(e.key) && e.preventDefault()}  {...register("outside")} />
                    <p style={{ color: "red" }}>{errors.outside?.message}</p>
                     </div>
            </div>
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='space_input_detail_header_area Space_detai_header2'>
                <div>Item</div>
                <div>Area <samll>(Sq ft)</samll></div>
            </div>
            <div className='space_input_detail_data_area'>
                <div>Room</div>
                <div> <input type="text" id="input" class="form-control" min="0"  onKeyDown={(e) =>["e", "E", "+", "-","."].includes(e.key) && e.preventDefault()}  {...register("room")} />
                <p style={{ color: "red" }}>{errors.room?.message}</p>
                 </div>
            </div>
        </div> */}
        <div className="space_input_detail_area mb-0">
          <div className="table-responsive Space_Result_details_area">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    Condition (<span style={{ color: "#eef8ff" }}> Summer </span>)
                  </th>
                  <th>DB <samll><samll>(°F)</samll></samll></th>
                  <th>WB <samll><samll>(°F)</samll></samll></th>
                  <th>% RH</th>
                  <th>DP <samll><samll>(°F)</samll></samll></th>
                  <th>GR/LB</th>
                </tr>
              </thead>
              <tbody className="conditionCustom">
                <tr>
                  <td>
                    <div>Outside</div>
                  </td>
                  <td>
                    <div>
                      <input
                        type="text"
                        id="input"
                        class="form-control"
                        min="0"
                        onKeyDown={(e) => {
                          const inputValue = e.target.value;
                          const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                          // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                          if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                            e.preventDefault();
                          }
                        }}

                        {...register("outside")}
                      />
                      <p style={{ color: "red" }}>{errors.outside?.message}</p>
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      defaultValue="75"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("outsideWb", { defaultValue: "75" })}
                    />
                    <p style={{ color: "red" }}>{errors.outsideWb?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("outsideRh")}
                    />
                    <p style={{ color: "red" }}>{errors.outsideRh?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("outsideDp")}
                    />
                    <p style={{ color: "red" }}>{errors.outsideDp?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      defaultValue="74"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("outsideGr", { defaultValue: "74" })}
                    />
                    <p style={{ color: "red" }}>{errors.outsideGr?.message}</p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div>Room</div>
                  </td>
                  <td>
                    <div>
                      {" "}
                      <input
                        type="text"
                        id="input"
                        class="form-control"
                        min="0"
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-", "."].includes(e.key) &&
                          e.preventDefault()
                        }
                        {...register("room")}
                      />
                      <p style={{ color: "red" }}>{errors.room?.message}</p>
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("roomWb")}
                    />
                    <p style={{ color: "red" }}>{errors.roomWb?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      min="0"
                      defaultValue="55"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("roomRh", { defaultValue: "55" })}
                    />
                    <p style={{ color: "red" }}>{errors.roomRh?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("roomDp")}
                    />
                    <p style={{ color: "red" }}>{errors.roomDp?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      defaultValue="66"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("roomGr", { defaultValue: "66" })}
                    />
                    <p style={{ color: "red" }}>{errors.roomGr?.message}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="space_input_detail_area mb-0">
          <div className="table-responsive Space_Result_details_area">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    Condition ({" "}
                    <span style={{ color: "#eef8ff" }}> Winter </span>){" "}
                  </th>
                  <th>DB <samll>(°F)</samll></th>
                  <th>WB <samll>(°F)</samll></th>
                  <th>% RH</th>
                  <th>DP <samll>(°F)</samll></th>
                  <th>GR/LB</th>
                </tr>
              </thead>
              <tbody className="conditionCustom">
                <tr>
                  <td>
                    <div>Outside</div>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("outsideWinterDb")}
                    />
                    <p style={{ color: "red" }}>{errors.outsideWinterDb?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      defaultValue="41"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("outsideWinterWb", { defaultValue: "41" })}
                    />
                    <p style={{ color: "red" }}>{errors.outsideWinterWb?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("outsideWinterRh")}
                    />
                    <p style={{ color: "red" }}>{errors.outsideWinterRh?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("outsideWinterDp")}
                    />
                    <p style={{ color: "red" }}>{errors.outsideWinterDp?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      defaultValue="32"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("outsideWinterGr", { defaultValue: "32" })}
                    />
                    <p style={{ color: "red" }}>{errors.outsideWinterGr?.message}</p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div>Room</div>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("roomWinterDb")}
                    />
                    <p style={{ color: "red" }}>{errors.roomWinterDb?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("roomWinterWb")}
                    />
                    <p style={{ color: "red" }}>{errors.roomWinterWb?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      defaultValue="30"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("roomWinterRh", { defaultValue: "30" })}
                    />
                    <p style={{ color: "red" }}>{errors.roomWinterRh?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("roomWinterDp")}
                    />
                    <p style={{ color: "red" }}>{errors.roomWinterDP?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"

                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("roomWinterGr")}
                    />
                    <p style={{ color: "red" }}>{errors.roomWinterGr?.message}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="space_input_detail_area mb-0">
          <div className="table-responsive Space_Result_details_area">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    Condition ({" "}
                    <span style={{ color: "#eef8ff" }}> Monsoon </span>){" "}
                  </th>

                  <th>DB <samll>(°F)</samll></th>
                  <th>WB <samll>(°F)</samll></th>
                  <th>% RH</th>
                  <th>DP <samll>(°F)</samll></th>
                  <th>GR/LB</th>
                </tr>
              </thead>
              <tbody className="conditionCustom">
                <tr>
                  <td>
                    <div>Outside</div>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("outsideMonsoonDb")}
                    />
                    <p style={{ color: "red" }}>{errors.outsideMonsoonDb?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      defaultValue="83"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("outsideMonsoonWb", { defaultValue: "83" })}
                    />
                    <p style={{ color: "red" }}>{errors.outsideMonsoonWb?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("outsideMonsoonRh")}
                    />
                    <p style={{ color: "red" }}>{errors.outsideMonsoonRh?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("outsideMonsoonDp")}
                    />
                    <p style={{ color: "red" }}>{errors.outsideMonsoonDp?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      defaultValue="158"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("outsideMonsoonGr", { defaultValue: "158" })}
                    />
                    <p style={{ color: "red" }}>{errors.outsideMonsoonGr?.message}</p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div>Room</div>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("roomMonsoonDb")}
                    />
                    <p style={{ color: "red" }}>{errors.roomMonsoonDb?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("roomMonsoonWb")}
                    />
                    <p style={{ color: "red" }}>{errors.roomMonsoonWb?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      defaultValue="55"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("roomMonsoonRh", { defaultValue: "55" })}
                    />
                    <p style={{ color: "red" }}>{errors.roomMonsoonRh?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("roomMonsoonDp")}
                    />
                    <p style={{ color: "red" }}>{errors.roomMonsoonDp?.message}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name=""
                      id="input"
                      class="form-control"
                      defaultValue="66"
                      min="0"
                      onKeyDown={(e) => {
                        const inputValue = e.target.value;
                        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                        // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                        if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                          e.preventDefault();
                        }
                      }}
                      {...register("roomMonsoonGr", { defaultValue: "66" })}
                    />
                    <p style={{ color: "red" }}>{errors.roomMonsoonGr?.message}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Condition;
