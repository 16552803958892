import React from 'react'

const GainExecptWall = ({ register, errors }) => {
    return (
        <div className='space_input_detail_area mb-4'>
            <h4 className='space_input_detail_heading mb-3'>Trans. Gain Except Walls & Roof</h4>

            <div className='row'>
                {/* <div className='col-xl-4 col-lg-6 col-md-6 col-12'>
            <div className='space_input_detail_header_area Space_detai_header1'>
                <div>Item</div>
                <div>Area <samll>(Sq ft)</samll></div>
            </div>
            <div className='space_input_detail_data_area'>
                <div>N - Glass</div>
                <div> <input type="text" id="input" class="form-control" min="0"   onKeyDown={(e) =>["e", "E", "+", "-","."].includes(e.key) && e.preventDefault()}  {...register("transNGlass")} /> <p style={{ color: "red" }}>{errors.transNGlass?.message}</p> </div>
            </div>
        </div>
        <div className='col-xl-4 col-lg-6 col-md-6 col-12'>
            <div className='space_input_detail_header_area Space_detai_header2'>
                <div>Item</div>
                <div>Area <samll>(Sq ft)</samll></div>
            </div>
            <div className='space_input_detail_data_area'>
                <div>NE - Glass</div>
                <div> <input type="text" id="input" class="form-control"  min="0"  onKeyDown={(e) =>["e", "E", "+", "-","."].includes(e.key) && e.preventDefault()}  {...register("transNeGlass")} /> <p style={{ color: "red" }}>{errors.transNeGlass?.message}</p> </div>
            </div>
        </div>
        <div className='col-xl-4 col-lg-6 col-md-6 col-12'>
            <div className='space_input_detail_header_area Space_detai_header3'>
                <div>Item</div>
                <div>Area <samll>(Sq ft)</samll></div>
            </div>
            <div className='space_input_detail_data_area'>
                <div>E - Glass</div>
                <div> <input type="text" id="input" class="form-control" min="0"   onKeyDown={(e) =>["e", "E", "+", "-","."].includes(e.key) && e.preventDefault()}  {...register("transEGlass")} /> <p style={{ color: "red" }}>{errors.transEGlass?.message}</p> </div>
            </div>
        </div> */}

                <div className='col-xl-4 col-lg-6 col-md-6 col-12'>
                    <div className='space_input_detail_header_area Space_detai_header3'>
                        <div>Item</div>
                        <div>Area <samll>(Sq ft)</samll></div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>Partition</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }}  {...register("partition")} /> <p style={{ color: "red" }}>{errors.partition?.message}</p> </div>
                    </div>
                </div>

           
            </div>
        </div>

    )
}

export default GainExecptWall