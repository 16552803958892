// auth.js
import { getAuth, signOut } from "firebase/auth";
import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(getStoredUser());
  // const [userData, setUserData] = useState(getStoredUserData());

  // Function to retrieve user data from localStorage
  function getStoredUser() {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  }
  // Function to retrieve additional user data from localStorage
  // function getStoredUserData() {
  //   const storedUserData = localStorage.getItem("userData");
  //   return storedUserData ? JSON.parse(storedUserData) : null;
  // }

  // Function to save user data to localStorage
  function setStoredUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }
  // Function to save additional user data to localStorage
  // function setStoredUserData(userData) {
  //   localStorage.setItem("userData", JSON.stringify(userData));
  // }
  // Function to handle user login
  function login(user, userData) {
    // Replace this with your actual login logic
    setUser(user);
    setStoredUser(user);

    // setUserData(userData);
    // setStoredUserData(userData);
  }

  // Function to handle user logout
  async function logout() {
    const auth = getAuth(); // Get the Firebase Auth instance
    await signOut(auth); // Sign out the user
    setUser(null);
    // setUserData(null);
    localStorage.clear();
    window.location.href = "/login";
  }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const auth = useContext(AuthContext);
  if (!auth) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return auth;
};

export { AuthProvider, useAuth };
