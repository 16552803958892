import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import axios from "axios";
import Swal from "sweetalert2"; // Import SweetAlert
import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/AuthProvider";
// import { useState } from "react";
import Sign_Up from "../assets/logo.svg";
import welcome from "../assets/login.jpg";
// import icon from "../assets/welcome.svg";
import { Link } from "react-router-dom";
import { API_URL } from "../Services/UserServices";
import api from "../Services/ApiService";
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { getAuth, FacebookAuthProvider, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
// import { auth, facebook } from '../firebase'
const schemaSignIn = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Email required")
    .matches(/^[\w.%+-]+@[\w.-]+\.[A-Za-z]{2,}$/i, "Email must be valid"),
  password: yup.string().required("Password required"),
});

const Login = ({ activeTab }) => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const redirectPath = new URLSearchParams(location.search).get('redirect');
  const auth = getAuth();
  // const [isLogin, setIsLogin] = useState(false); // Manages whether the user is logged in or not
  // const [user, setUser] = useState(null); // Stores user data when logged in

  const {
    register: registerSignIn,
    handleSubmit: handleSubmitSignIn,
    reset,
    formState: { errors: signInErrors },
  } = useForm({
    resolver: yupResolver(schemaSignIn),
  });

  const onSubmitHandlerSignIn = async (data) => {
    setLoading(true);
    try {
      // Replace 'YOUR_API_ENDPOINT' with the actual endpoint of your server
      const response = await api.post(`${API_URL}/user/login`, data);


      // Check if the response indicates success (modify the condition based on your API response structure)
      if (response?.data?.statusCode == 200) {
        const userData = response.data.data;
        login(userData);
        if (userData.paid == "paid") {


          // navigate("/Project");
          navigate(redirectPath || '/Project');
          reset();
        } else if (userData.paid == "unpaid") {
          // Swal.fire({
          //   title: "Success!",
          //   text: "login successfully.",
          //   icon: "success",
          //   button: "OK",
          // });

          Swal.fire({
            text: "Please subscribe Duct Architect to use the calculator",
          });
                    navigate(redirectPath || '/');
        }
      } else if (response?.data?.statusCode == 400) {
        toast.error(response?.data?.message);
        
      } else if (response?.data?.statusCode == 401) {

        toast.error("Invalid credentials");
      } else {
        toast.error(response?.data?.message);
      }


      // resetSignIn();
    } catch (error) {
      console.log(error,"error");


      toast.error(error?.response?.data?.message);
    }
    setLoading(false);
    // resetSignIn();
  };




  // const [User, setUser] = useState();

  // useEffect(() => {
  //   if (activeTab !== "signIn") {
  //     resetSignIn();
  //   }
  // }, [activeTab, resetSignIn]);

  // const Login = useGoogleLogin({
  //   onSuccess: (codeResponse) => {
  //     setUser(codeResponse);
  //     console.log(codeResponse);
  //   },
  //   onError: (error) => {
  //     console.log(error, "Login Failed");
  //   },
  // });

  // console.log(User, "-------------------------->suesrrrrrrrrrrrrrrr");

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // const access_token = 'YOUR_ACCESS_TOKEN'; // Replace with your actual access token
  //       const access_token = User.access_token;
  //       console.log(access_token, "=------------------------> accesss ");
  //       const response = await axios.get(
  //         `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${access_token}`,
  //             Accept: "application/json",
  //           },
  //         }
  //       );

  //       const { data } = response;
  //       console.log("User info:", data);

  //       // Do something with the user data, such as setting it in state
  //       // setState(data);
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //       // Handle the error, show an error message, etc.
  //     }
  //   };
  //   if (User) {
  //     fetchData();
  //   }
  // }, [User]);

  // const  onFacebookLogin = ()=> {

  //   const provider = new FacebookAuthProvider();

  //   signInWithPopup(auth, provider)
  //     .then((result) => {
  //       // This gives you a Facebook Access Token. You can use it to access the Facebook API.
  //       const credential = FacebookAuthProvider.credentialFromResult(result);
  //       const token = credential.accessToken;

  //       const user = result.user;
  //       // IdP data available using getAdditionalUserInfo(result)
  //       // ...
  //     })
  //     .catch((error) => {
  //       // Handle Errors here.
  //       const errorCode = error.code;
  //       const errorMessage = error.message;
  //       // The email of the user's account used.
  //       const email = error.customData?.email; // Note: Use optional chaining to avoid errors if email is not available.
  //       // AuthCredential type that was used.
  //       const credential = FacebookAuthProvider.credentialFromError(error);
  //       // ...
  //     });

  // }
  const onFacebookLogin = async () => {

    const provider = new FacebookAuthProvider()

    try {

      const result = await signInWithPopup(auth, provider);

      const user = result.user;
      const type = "facebook";
      socialUserData(user, provider, type);
      console.log(user, "user from facebook login");
    } 
    catch (error) {
      console.log(error,"error")
      console.log(error.code,"facebook code");
      if (error.code === 'auth/popup-closed-by-user' || error.code === 'auth/cancelled-popup-request') {
        
        return;
      }else {
 
        toast.error(error.code || "An error occurred during facebook login");
      }

    }
    
    // catch (error) {
    //   toast.error(error);
    // }
  }

  // const handleGoogleLogin = () => {
  //   const provider = new GoogleAuthProvider()
  // signInWithPopup(auth, provider)
  //   .then((result) => {
  //     // The signed-in user info.
  //     const user = result.user;
  //     console.log(user,"user from google")

  //   }).catch((error) => {
  //     // Handle Errors here.
  //     // ...
  //     console.log(error,"error from login")
  //   });
  // }
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const type = "google"
      socialUserData(user, provider, type);
    } catch (error) {
   
      if (error.code === 'auth/popup-closed-by-user' || error.code === 'auth/cancelled-popup-request') {

        return;
      }
      toast.error(error.code);
    }
  };

  const socialUserData = async (user, provider, type) => {
    if (type == "google") {
      const { uid, displayName, email, } = user;
      const userObj = {
        "firstName": displayName,
        "lastName": "",
        "email": email,
        "ssoId": uid,
        "provider": "google"
      }
      try {
        // Make an HTTP POST request to your API endpoint to save user data
        const response = await api.post(`${API_URL}/user/social-connect`, userObj);
        if(response?.status == 200 || response?.status == 201){

        }
         if (response?.data?.statusCode == 200) {
          const userData = response?.data?.data;
          login(userData);
        
          if (!userData.mobileNo) {
            navigate('/MobileNumber');
            return; 
          }

          if (userData.paid == "paid") {

            navigate(redirectPath || '/Project');

          }
          else {
              Swal.fire({
              text: "Please subscribe Duct Architect to use the calculator",
            });
           navigate(redirectPath || '/');
          }      
        }
        else {
          toast.error("Login failed.");
        }

      } catch (error) {
        toast.error(error?.data?.message);
      }
    } else {

      const { uid, displayName, email, } = user;

      const facebookObj = {
        "firstName": displayName,
        "lastName": "",
        "email": email,
        "ssoId": uid,
        "provider": "facebook"
      };
      try {
        // Make an HTTP POST request to your API endpoint to save user data
        const response = await api.post(`${API_URL}/user/social-connect`, facebookObj);
        if (response.data.statusCode == 200) {
          const userData = response?.data?.data;
          login(userData);
          
          if (!userData.mobileNo) {
            navigate('/MobileNumber');
            return; 
          }
          console.log(response?.data); // Log the response from the API
          if (userData?.paid == "paid") {

            navigate(redirectPath || '/Project');
          }
          else {

            Swal.fire({

              text: "Please subscribe Duct Architect to use the calculator",
            });
            navigate(redirectPath || '/');
          }
        }
        else {
          toast.error("Login failed.");
        }

      } catch (error) {
        toast.error(error?.data?.message);
      }
    }
  };


  return (
    <>

      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress />
          </Backdrop>
        </Box>
      )
      }


      <div className="container-fluid bg-login_page">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-md-12 col-lg-7 col-xl-6">
              <div className="card card_custom_card">
                <div className="card-body card_custom">
                  <div className="text-center">
                    <a href="/">  <img src={Sign_Up} className="img-fluid logo_img" alt="" /></a>
                  </div>
                  <div className="login_heading_Area">
                    <h3 className="">Sign In</h3>
                    <p className="bottom-nav_headeing">Welcome To Duct Architect!</p>
                  </div>

                  <form onSubmit={handleSubmitSignIn(onSubmitHandlerSignIn)}>
                    <div className="col-md-12 mb-2">
                      {/* <label className="form-label">
                      Email<span className="redP">*</span>
                    </label> */}
                      <input
                        type="text" placeholder="Enter email"
                        className="form-control form-control_custom "
                        {...registerSignIn("email")}
                        autoComplete="off"
                      />
                      <p className="font-14 ms-2" style={{ color: "red" }}>{signInErrors.email?.message}</p>
                    </div>
                    <div className="col-md-12 mb-2">
                      <div className="position-relative">
                        <input
                          {...registerSignIn("password")}
                          type="password"
                          className="form-control form-control_custom " placeholder="Enter password"
                        />
                      </div>

                      <p className="font-14 ms-2" style={{ color: "red" }}>
                        {signInErrors.password?.message}
                      </p>
                    </div>

                    <div className="col-md-12 text-end">
                      <Link to="/ForgotPassword" className="forget_password">
                        {" "}
                        Forgot Password?
                      </Link>
                    </div>
                    <div>
                      <button className="btn Sign_btn_submit w-100 my-2">
                        Sign In
                      </button>

                    </div>
                  </form>
                  <div className="login_width_socialMedia_area">
                    <p className="fw-bold">Or continue with</p>
                    {/* <p className="">Or sign up with</p> */}
                    <div className="socialmedia">
                      <button className="btn facebook_btn" onClick={handleGoogleLogin}><i className="fa-brands fa-google"></i></button>
                      <button
                        onClick={onFacebookLogin}
                        className="btn facebook_btn"><i class="fa-brands fa-facebook-f"></i></button>
                    </div>
                  </div>
                  <p className="bottom-nav">Don’t have an account? <a href="/Register" > Register now</a></p>


                </div>

              </div>

            </div>
          </div>

          <ToastContainer />

        </div>
      </div >

      {/* 
      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#WelcomeModal">
        welcome
      </button> */}

      <div
        className="modal fade WelcomeModal_section_hp"
        id="WelcomeModal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="WelcomeModalLabel"
        aria-hidden="true "
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div class="">
              <button type="button" class="btn-close close_btn" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
            </div>
            <div className="modal-body">
              <div className="row align-items-center">
                <div className="col-xl-7 col-lg-6 col-md-12">
                  <div className="welcome_content_Area">
                    <div className="WelcomeModal_Content">
                      <h1>Welcome to Duct Architect</h1>
                      <div className="welcome_Newsletter_area d-block">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                        </p>
                        <div className="time">
                          <i class="fa-solid fa-hourglass-half"></i>
                          {/* <img src={icon} className="img-fluid logo_img" alt="" /> */}
                          <span>25 Hrs</span>
                          <span>00 Min</span>
                        </div>
                        <button className="btn">  Go To Subscribe</button>
                      </div>
                    </div>
                  </div>
                  <div class="visme_d" data-title="Copy of Special Offers Subscription Form" data-url="y43m4qng-copy-of-special-offers-subscription-form" data-domain="forms" data-full-page="false" data-min-height="500px" data-form-id="10018"></div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-12 pl-0">
                  <div className="welcome_side_img h-100">
                    <img src={welcome} className="img-fluid logo_img" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
