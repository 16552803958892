import { useEffect } from "react";
import { useAuth } from "../provider/AuthProvider";

const withAuth = (WrappedComponent) => {
  const WithAuth = (props) => {
    const { user } = useAuth();

    useEffect(() => {
      // Check if the user is authenticated
      if (!user) {
        // Redirect to the login page or any other protected route
        window.location.href = "/login";
      }
    }, [user]);

    // If the user is authenticated, render the wrapped component
    // Otherwise, you can show a loading spinner or a message indicating that the user is not authenticated
    return user ? <WrappedComponent {...props} /> :<div
    className="d-flex align-items-center justify-content-center"
    style={{ margin: "5%", minHeight: "320px" }}
  >
    <div className="spinner-border m-5 text-center" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>;
  };

  return WithAuth;
};

export default withAuth;
