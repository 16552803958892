const getwidth = (d, h) => {
    if (!d && !h) {
        return 0;
    }
    if (h == 0) {
        return 0;
    }
    let w = 10;
    while (
        -(1.3 * Math.pow(w * h, 0.625)) / Math.pow(w + h, 0.25) + d * 1000 >
        0.00000001
    ) {
        w += 1;
    }
    return w;
};

const calculateDiameterFromFriction = (flow, fr) => {
    if (
        flow == null ||
        isNaN(flow) ||
        flow === 0 ||
        fr == null ||
        isNaN(fr) ||
        fr === 0
    ) {
        throw new Error(
            "Invalid input. Flow and friction must be non-null numbers and not equal to 0."
        );
    }

    const dia =
        0.0344198 * Math.pow(0.9 / fr, 1 / 4.86) * Math.pow(flow, 1.82 / 4.86);

    return dia;
};

const calculateFrictionFromVelocity = (velo, dia) => {
    if (
        velo == null ||
        isNaN(velo) ||
        velo === 0 ||
        dia == null ||
        isNaN(dia) ||
        dia === 0
    ) {
        throw new Error(
            "Invalid input. Velocity and diameter must be non-null numbers and not equal to 0."
        );
    }

    const fr =
        ((0.0129 / 0.9) * 0.9 * Math.pow(velo, 1.82)) / Math.pow(dia, 1.22);
    return fr;
};

const calculateVelocityFromDiameter = (flow, dia, fr) => {
    if (
        dia == null ||
        isNaN(dia) ||
        dia === 0 ||
        fr == null ||
        isNaN(fr) ||
        fr === 0
    ) {
        throw new Error(
            "Invalid input. Flow, diameter, and friction must be non-null numbers and not equal to 0."
        );
    }
    const velo = Math.pow((fr * Math.pow(dia, 1.22)) / (0.0129 * 0.9), 1 / 1.82);
    return velo;
};

const calculateDuctWidth = (dia, h, r) => {
    if (
        dia == null ||
        isNaN(dia) ||
        dia === 0 ||
        h == null ||
        isNaN(h) ||
        r == null ||
        isNaN(r) ||
        r === 0
    ) {
        return ;
    }

    const width = Math.round(getwidth(dia, parseInt(h)) / r) * r;
    return width;
};

const calculateDiameterFromVelocity = (flow, velo) => {
    if (
        flow == null ||
        isNaN(flow) ||
        flow === 0 ||
        velo == null ||
        isNaN(velo) ||
        velo === 0
    ) {
        throw new Error(
            "Invalid input. Flow and velocity must be non-null numbers and not equal to 0."
        );
    }

    const area = flow / 1000 / velo;
    const dia = Math.sqrt((area * 4) / Math.PI);
    return dia;
};

function calculateVelo(fr, dia) {
    if (
        fr == null ||
        isNaN(fr) ||
        fr === 0 ||
        dia == null ||
        isNaN(dia) ||
        dia === 0
    ) {
        throw new Error(
            "Invalid input. Friction and diameter must be non-null numbers and not equal to 0."
        );
    }

    return 10.91 * Math.pow(fr, 0.54) * Math.pow(dia, 0.67);
}

function calculateDia(fr, flow) {
    if (fr == null || isNaN(fr) || fr === 0 || flow == null || isNaN(flow) || flow === 0) {
        throw new Error("Invalid input. Friction and flow must be non-null numbers and not equal to 0.");
    }

    return 0.0344198 * Math.pow(0.9 / fr, 1 / 4.86) * Math.pow(flow, 1.82 / 4.86);
}

// function calculateAspectRatio(width, height) {
//     if (width == 0) {
//         return "0 : 0";
//     }
//     // Find the greatest common divisor (GCD) of width and height
//     function gcd(a, b) {
//         return b === 0 ? a : gcd(b, a % b);
//     }

//     const divisor = gcd(width, height);

//     // Divide both width and height by the GCD to get the simplified ratio
//     return `${width / divisor} : ${height / divisor}`;
// }
function calculateAspectRatio(width, height) {
    if (width === 0 || height === 0) {
        return "0 : 0";
    }
    // Determine which side is smaller and construct the ratio accordingly
    if (width < height) {
        const ratio = height / width;
        return `1 : ${Number.isInteger(ratio) ? ratio : ratio.toFixed(2)}`;
    } else {
        const ratio = width / height;
        return `${Number.isInteger(ratio) ? ratio : ratio.toFixed(2)} : 1`;
    }

}


const findRecord = (branchId, data) => {
    for (const item of data) {
        if (item.id === branchId) {
            return item;
        }
        if (item.children.length > 0) {
            const found = findRecord(branchId, item.children);
            if (found) {
                return found;
            }
        }
    }
    return null;
};

const findHighestBranchIndex = (node, highestIndex) => {
    if (!node.children || node.children.length === 0) {
        return highestIndex;
    }
    for (const child of node.children) {
        const branchIndex = parseInt(child?.childDuctName.split("-")[1], 10);

        if (!isNaN(branchIndex) && branchIndex > highestIndex) {
            highestIndex = branchIndex;
        }
        highestIndex = findHighestBranchIndex(child, highestIndex);
    }

    return highestIndex;
};

const findParent = (treeData, key) => {
    if (!treeData || treeData.length === 0) {
        return null;
    }

    for (let i = 0; i < treeData.length; i++) {
        const node = treeData[i];

        if (node.children) {
            for (let j = 0; j < node.children.length; j++) {
                const child = node.children[j];

                if (child.id === key) {
                    return node;
                }
            }

            const result = findParent(node.children, key);
            if (result) {
                return result;
            }
        }
    }

    return null;
};

const findNode = (treeData, key) => {
    // Base case: if the tree data array is empty or null, return null
    if (!treeData || treeData.length === 0) {
        return null;
    }

    // Loop through each node in the tree data array
    for (let i = 0; i < treeData.length; i++) {
        const node = treeData[i];

        // If the node key matches the given key, return the node
        if (node.id === key) {
            return node;
        }

        // If the node has children, recursively search for the node in its children array
        if (node.children) {
            const result = findNode(node.children, key);
            if (result) {
                return result;
            }
        }
    }

    // If the node is not found, return null
    return null;
};

function getwidthImperial(d, h) {
  if (!d && !h) {
    return 0;
  }
  if (h == 0) {
    return 0;
  }
  let w = 10;
  while (
    -(1.3 * Math.pow(w * h, 0.625)) / Math.pow(w + h, 0.25) + d * 1000 >
    0.00000001
  ) {
    w++;
  }
  w = Math.round(w / 50.8) * 2;
  return w;
}


function inchesToMillimeters(inches) {
    return inches * 25.4;
}



const CalcualteImperialDuctDiameter = (FlowArea) => {
    return Math.round(Math.pow((FlowArea * 4) / Math.PI, 0.5));
  };

  function calculateFrictionLoss(flow, txtVelocity, txtRoughness) {
    if(!flow || !txtVelocity || !txtRoughness || flow == 0 || txtVelocity == 0 || txtRoughness == 0){
       return 0
    }
    var adjustedVelocity = txtVelocity / 196.8;
    var area = flow / 2.1188799727597 / 1000 / adjustedVelocity;
    var dia = Math.sqrt((area * 4) / Math.PI);
    var fr =
      ((0.0129 / 0.9) * txtRoughness * Math.pow(adjustedVelocity, 1.82)) /
      Math.pow(dia, 1.22);
    return fr * 0.0040146307866177 * 30.48;
  }




  const calculateDiameter = (width, height) => {
    return Math.trunc((1.3 * Math.pow(width * height, 0.625)) / Math.pow(width + height, 0.25));
  };
  
  const calculateVelocity = (airQuantity, diameter) => {
    return (((airQuantity / 1000) * 4) / (Math.pow(diameter, 2) * Math.PI)) * 1000000;
  };
  
  const calculateFrictionLossByAirAndDia = (airQuantity, diameter) => {
    return (0.9 * Math.pow(0.0344198, 4.86) * Math.pow(airQuantity, 1.82)) / Math.pow(diameter / 1000, 4.86);
  };
  
  const calculateFlowArea = (airQuantity, velocity) => {
    return (airQuantity / 1000 / velocity).toFixed(2);
  };



export {
    calculateDiameter,
    calculateVelocity,
    calculateFlowArea,
    CalcualteImperialDuctDiameter,
    calculateDiameterFromVelocity,
    calculateFrictionLoss,
    calculateFrictionLossByAirAndDia,
    calculateDia,
    calculateVelo,
    getwidth,
    calculateDiameterFromFriction,
    calculateFrictionFromVelocity,
    calculateVelocityFromDiameter,
    calculateDuctWidth,
    calculateAspectRatio,
    findRecord,
    findHighestBranchIndex,
    findParent,
    findNode,
    getwidthImperial,
    inchesToMillimeters
};
