import React, { useEffect, useState, useRef } from 'react'
import withAuth from "../WithAuth";
import HvacHeader from '../hvacComponents/hvacHeader';
import SolarGain from '../hvacComponents/solarGain';
import SolarGainWall from '../hvacComponents/SolarGainWall';
import GainExecptWall from '../hvacComponents/GainExecptWall';
import Condition from '../hvacComponents/Condition';
import OtherDetails from '../hvacComponents/OtherDetails';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Swal from 'sweetalert2';
import { useAuth } from '../../provider/AuthProvider';
import api from '../../Services/ApiService';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';
import InternalHeat from '../hvacComponents/InternalHeat';
import ADP from '../hvacComponents/ADP';
import HvacPdf from '../hvacComponents/hvacPdf';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';
import HvacWinterPdf from '../hvacComponents/hvacWinterPdf';
import HdfcMonsoonPdf from '../hvacComponents/hdfcMonsoonPdf';



function SpaceProjectDetail() {
    const schema = yup.object().shape({
        projectName: yup.string(" project name required")

        ,
        space_name: yup.string(" space name required").nullable()
        // .required('Space Name is required')
        ,
        size_sqrft: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        size_cuft: yup.number().nullable().transform((originalValue, originalObject) => {
            return isNaN(originalValue) ? null : originalValue;
        })
        // .required('Size (Cu ft) is required')
        ,
        date: yup.string().nullable(),
        rev: yup.string().nullable()
        // .required('Rev is required')
        ,

        // Solar Gain Glass
        nGlass: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        seGlass: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        wGlass: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        neGlass: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        sGlass: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        nwGlass: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        eGlass: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        swGlass: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        skyLight: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),

        // Solar & Trans. Gain Walls & Roof
        nTransGainWallGlass: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        seTransGainWallGlass: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        wTransGainWallGlass: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        neTransGainWallGlass: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        sTransGainWallGlass: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        nwTransGainWallGlass: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        eTransGainWallGlass: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        swTransGainWallGlass: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        roofSun: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        roofShaded: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),

        // Trans. Gain Except Walls & Roof
        // transNGlass: yup.number().required('Required').min(0)
        //     .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)),
        // transNeGlass: yup.number().required('Required').min(0)
        //     .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)),
        // transEGlass: yup.number().required('Required').min(0)
        //     .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)),

        partition: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),

        AirChange: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),



        // Condition
        outside: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        room: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),


        outsideWb: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        roomWb: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),

        outsideRh: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        roomRh: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),

        outsideDp: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        roomDp: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),

        outsideGr: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        roomGr: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),





        // Condition winter 
        outsideWinterDb: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        roomWinterDb: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),


        outsideWinterWb: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        roomWinterWb: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),

        outsideWinterRh: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        roomWinterRh: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),

        outsideWinterDp: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        roomWinterDp: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),

        outsideWinterGr: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        roomWinterGr: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),







        // Condition monsoon
        outsideMonsoonDb: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        roomMonsoonDb: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),


        outsideMonsoonWb: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        roomMonsoonWb: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),

        outsideMonsoonRh: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        roomMonsoonRh: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),

        outsideMonsoonDp: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        roomMonsoonDp: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),

        outsideMonsoonGr: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        roomMonsoonGr: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),













        // Other Details
        occupancy: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        height: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        lighting: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        freshAir: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        eqLoad: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
       
       
        // Internal Heat
        sensibleHeat: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        latentHeat: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),

        // ADP    
        selected: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        indicated: yup.number().required('Required').min(0)
            .max(1000, "value must not  be greater than 1000").transform((value) => (isNaN(value) ? undefined : value)).test(
                'decimal-places',
                'Only up to 2 decimal places are allowed',
                (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),

    });
    const { user, logout } = useAuth();
    const [InputValueData, setInputValueData] = useState({});
    const [loading, setLoading] = useState(false);
    const { spaceId } = useParams();
    const [projectName, setProjectName] = useState("")

    const [showtab, setShowtab] = useState(false);

    const [buttonStatus, setButtonStatus] = useState(false)

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user?.paid !== "paid" && user?.subscription === false) {
            setButtonStatus(true)
        }
        else {
            setButtonStatus(false)
        }
    }, [buttonStatus])



    const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });


    useEffect(() => {
        if (InputValueData !== "") {
            const { space_input } = InputValueData;
            if (space_input) {
                Object.keys(space_input).map((key) => {

                    setValue(key, space_input[key]);


                })
            }

            if (InputValueData.size_cuft) {
                setValue("size_cuft", InputValueData.size_cuft);
            }


        }
    }, [InputValueData])



    const getSpaceById = async (id) => {
        setLoading(true);
        try {


            const response = await api.get("/hvac/getOne/" + id
            );
            if (response.status == 200) {
                reset({
                    ...response.data,
                    projectName: response.data.product.productName,
                });
                setProjectName(response.data.product.productName);
                setInputValueData(response.data);
                if (response.data.space_output != "") {
                    setShowtab(true)
                }

            };
        } catch (e) {
            setLoading(false);
            //   Swal.fire({
            //     text: e?.message,
            //   });
            toast.error(e?.message);

        }
    };

    useEffect(() => {
        if (spaceId) {
            getSpaceById(spaceId)
        }
    }, [spaceId])


    const onSubmit = async (data, event) => {

        const buttonType = event.nativeEvent.submitter.getAttribute('name');
        let saved = false;

        setLoading(true);
        event.preventDefault(); // Prevent the default form submission behavior

        if (buttonType === 'save') {
            saved = true;
        }

        try {


            // Replace 'YOUR_API_ENDPOINT' with the actual endpoint of your server
            const response = await api.post(`/hvac/create-update?saved=${saved}`, { ...data, project_id: data.project_id });
            console.log(response, "line166")
            if (response.status == 200 || response.status == 201) {
                setShowtab(true)

                if (saved) {

                    toast.success("Calculated & saved", {
                        autoClose: 1000,
                    }


                    );


                }
                else {

                    toast.success("Calculated", {
                        autoClose: 1000,
                    })

                }

                if (saved) {
                    getSpaceById(spaceId);
                } else {
                    console.log(response.data.data.size_cuft, "response.data.data.size_cuft");

                    setInputValueData(response.data.data);
                }
            }
        } catch (error) {
            console.log(error, "error")
            toast.error(error.message);

        }
        setLoading(false);

    };

  const pdfRef = useRef(null);



const downloadSummerPdf = (htmlValue) => {
   // Define options for html2pdf conversion
    const options = {
      filename: 'summer.pdf',
     html2canvas: { scale: 2 },
      jsPDF: { format: 'a4' }
    };
  
    // Convert HTML to PDF using html2pdf library
    html2pdf().from(htmlValue).set(options).save();
  };
  
  const downloadWinterPdf = (winterValue) => {
    // Define options for html2pdf conversion
     const options = {
       filename: 'winter.pdf',
      html2canvas: { scale: 2 },
       jsPDF: { format: 'a4' }
     };
   
     // Convert HTML to PDF using html2pdf library
     html2pdf().from(winterValue).set(options).save();
   };

   const downloadMonsoonPdf = (monsoonValue) => {
    // Define options for html2pdf conversion
     const options = {
       filename: 'monsoon.pdf',
      html2canvas: { scale: 2 },
       jsPDF: { format: 'a4' }
     };
   
     // Convert HTML to PDF using html2pdf library
     html2pdf().from(monsoonValue).set(options).save();
   };

  




  





    const getPdf = (data) => {
        const summerValue=  HvacPdf({inputData: data,user: user});
        const winterValue=  HvacWinterPdf({inputData: data,user:user});
        const monsoonValue=  HdfcMonsoonPdf({inputData: data,user: user});



        if(summerValue){
            downloadSummerPdf(summerValue);

        }
        if(winterValue){
            downloadWinterPdf(winterValue);

        }

        if(monsoonValue){
            downloadMonsoonPdf(monsoonValue);

        }

    };


    const getExcelId = async () => {
        setLoading(true);
        try {


            const response = await api.get("/hvac/download/" + spaceId, {
                responseType: 'blob',
            }
            );
            if (response.status == 200) {

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'excel_file.xlsx');
                document.body.appendChild(link);
                link.click();


            };
        } catch (e) {
            setLoading(false);
            //   Swal.fire({
            //     text: e?.message,
            //   });
            toast.error(e?.message);

        }
    };


    const handleBackButton = () => {
        window.history.back()
    }




    return (
        <>
            <section className="container-fluid PageTitle_banner_section">
                <div className="container">
                    <h1 className="PageTitle_banner_heading">Heat Calculator</h1>
                </div>

            </section>

            <section className="container-fluid Project-section project_section_hp mt-4">
                <div className="container">
                    <div className="row mb-0 ">
                        <div className="col-md-12">
                            <div className="project_heading_area">
                                <div className="project_heading_BackBtn_area">
                                    <a className="back_arrow" onClick={handleBackButton}
                                        data-tooltip-id="BackBtn"
                                        data-tooltip-content="Back"
                                    >
                                        <i class="fa-solid fa-arrow-left-long"></i>
                                        <Tooltip id="BackBtn" />
                                    </a>
                                    <h3 className="">space for test</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="Calculator_section bg-_cal" >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <section className='container-fluid Project-section mt-0'>

                        <div className='container'>
                            <HvacHeader register={register} errors={errors} projectName={projectName} />
                            <SolarGain register={register} errors={errors} />
                            <SolarGainWall register={register} errors={errors} />
                            <GainExecptWall register={register} errors={errors} />
                            <Condition register={register} errors={errors} />
                            <InternalHeat register={register} errors={errors}/>
                            <ADP register={register} errors={errors}/>
                            <OtherDetails register={register} errors={errors} />                            
                            <div className='row'>
                                <div className='col-12 text-end'>
                                    <button className='btn btn_Calculate_sl ' type="submit" name='calcu' disabled={buttonStatus}>Calculate</button>
                                    <button className='btn btn_Calculate_hp' type="submit" name='save' disabled={buttonStatus}>Calculate & Save</button>
                                </div>
                                <ToastContainer />
                            </div>

                        </div>

                    </section>
                </form>
                <section className='container-fluid py-5 Space_Result_section_hp'>
                    {showtab && (

                        <div className='container '>


                            <ul class="nav nav-pills mb-3 Space_Result_option_area" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="pills-Summary-tab" data-bs-toggle="pill" data-bs-target="#pills-Summary" type="button" role="tab" aria-controls="pills-Summary" aria-selected="true">Summary</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-Summer-tab" data-bs-toggle="pill" data-bs-target="#pills-Summer" type="button" role="tab" aria-controls="pills-Summer" aria-selected="false">Summer</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-Winter-tab" data-bs-toggle="pill" data-bs-target="#pills-Winter" type="button" role="tab" aria-controls="pills-Winter" aria-selected="false">Winter</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-Monsoon-tab" data-bs-toggle="pill" data-bs-target="#pills-Monsoon" type="button" role="tab" aria-controls="pills-Monsoon" aria-selected="false">Monsoon</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link btn btn_Calculate_hp" onClick={() => getExcelId()} >Export</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link btn btn_Calculate_hp" onClick={() => getPdf(InputValueData)} >Export As Pdf</button>
                                </li> 
                            </ul>

                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-Summary" role="tabpanel" aria-labelledby="pills-Summary-tab">
                                    <div className='row align-items-stretch'>
                                        <div className='col-12'>
                                            <div className='Space_Result_Summary_heading'>Heat gain</div>
                                        </div>

                                        <div className='col-xl-6 col-lg-6 col-md-12 mb-4'>
                                            <div className='space_input_detail_area h-100'>
                                                <h4 className='space_input_detail_heading mb-3'>Solar Gain Glass Summary</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover' >
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Summer</th>
                                                                <th>Monsoon</th>
                                                                <th>Winter</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>N - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainNglassHeatResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainNglassHeatResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainNglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>NE - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainNEglassHeatResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainNEglassHeatResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainNEglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>E - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainEglassHeatResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainEglassHeatResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainEglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>SE - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainSEglassHeatResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainSEglassHeatResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainSEglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>S - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainSglassHeatResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainSglassHeatResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainSglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>SW - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainSWglassHeatResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainSWglassHeatResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainSWglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>W - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainWglassHeatResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainWglassHeatResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainWglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>NW - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainNWlassHeatResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainNWlassHeatResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainNWlassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Sky light</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainSkyLight} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainSkyLightMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainSkyLightWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div><strong>TOTAL</strong></div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.sumOfSolarGainResults} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.sumOfSolarGainResultsMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.sumOfSolarGainResultsWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-xl-6 col-lg-6 col-md-12 mb-4'>
                                            <div className='space_input_detail_area h-100'>
                                                <h4 className='space_input_detail_heading mb-3'>Solar & Trans. Gain Walls & Roof</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Summer</th>
                                                                <th>Monsoon</th>
                                                                <th>Winter</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>N - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallNglassHeatResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallNglassHeatResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallNglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>NE - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallNEglassHeatResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallNEglassHeatResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallNEglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>E - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallEglassHeatResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallEglassHeatResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallEglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>SE - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallSEglassHeatResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallSEglassHeatResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallSEglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>S - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallSglassHeatResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallSglassHeatResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallSglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>SW - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallSWglassHeatResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallSWglassHeatResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallSWglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>W - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallWglassHeatResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallWglassHeatResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallWglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>NW - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallNWlassHeatResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallNWlassHeatResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallNWlassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Roof Sun</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallRoofSunResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallRoofSunResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallRoofSunResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Roof Shaded</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallRoofShadedResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallRoofShadedResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallRoofShadedResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div><strong>TOTAL</strong></div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.sumOfSolarTransGainWallResults} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.sumOfSolarTransGainWallResultsMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.sumOfSolarTransGainWallResultsWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-xl-6 col-lg-6 col-md-12 mb-4'>
                                            <div className='space_input_detail_area h-100'>
                                                <h4 className='space_input_detail_heading mb-3'>Trans. Gain Except Walls & Roof</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Summer</th>
                                                                <th>Monsoon</th>
                                                                <th>Winter</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Partition</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.transGainExceptPartitonHeatGainResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.transGainExceptPartitonHeatGainResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.transGainExceptPartitonHeatGainResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Ceiling</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.transGainExceptCeilingHeatGainResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.transGainExceptCeilingHeatGainResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.transGainExceptCeilingHeatGainResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Floor</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.transGainExceptFloorHeatGainResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.transGainExceptFloorHeatGainResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.transGainExceptFloorHeatGainResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>All Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.transGainExceptWallGlassHeatGainResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.transGainExceptWallGlassHeatGainResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.transGainExceptWallGlassHeatGainResultWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-xl-6 col-lg-6 col-md-12 mb-4'>
                                            <div className='space_input_detail_area h-100'>
                                                <h4 className='space_input_detail_heading mb-3'>Solar & Trans. Gain Walls & Roof</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Summer</th>
                                                                <th>Monsoon</th>
                                                                <th>Winter</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>People</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.internalHeatGainPeopleBtuResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.internalHeatGainPeopleBtuResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.internalHeatGainPeopleBtuResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Light</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.internalHeatGainLightBtuResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.internalHeatGainLightBtuResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.internalHeatGainLightBtuResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Eq. Load</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.internalHeatGainEqLoadBtuResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.internalHeatGainEqLoadBtuResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.internalHeatGainEqLoadBtuResultWinter} />
                                                                </td>
                                                            </tr>
                                                            {/* <tr>
                                                        <td><div><strong>TOTAL</strong></div></td>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" />
                                                        </td>
                                                    </tr> */}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-xl-6 col-lg-6 col-md-12 mb-4'>
                                            <div className='space_input_detail_area h-100'>
                                                <h4 className='space_input_detail_heading mb-3'>Effective Room Sensible Heat (Ersh)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Summer</th>
                                                                <th>Monsoon</th>
                                                                <th>Winter</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Outside Air</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.ershResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.ershResultMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.ershResultWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-xl-6 col-lg-6 col-md-12 mb-4'>
                                            <div className='space_input_detail_area h-100'>
                                                <h4 className='space_input_detail_heading mb-3'>Effective Room Latent Heat (Erlh)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Summer</th>
                                                                <th>Monsoon</th>
                                                                <th>Winter</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Latent Heat</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.effectiveRoomLatentHeatErlh} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.effectiveRoomLatentHeatErlhMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.effectiveRoomLatentHeatErlhWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Outside Air</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.outSideAirResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.outSideAirHeatLatentResult} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.outSideAirHeatLatentResult} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-xl-6 col-lg-6 col-md-12 mb-4'>
                                            <div className='space_input_detail_area h-100'>
                                                <h4 className='space_input_detail_heading mb-3'>Grand Total Heat</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Summer</th>
                                                                <th>Monsoon</th>
                                                                <th>Winter</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Total heat</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.grandTotalHeat} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.grandTotalHeat} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.grandTotalHeat} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-xl-6 col-lg-6 col-md-12 mb-4'>
                                            <div className='space_input_detail_area h-100'>
                                                <h4 className='space_input_detail_heading mb-3'>Total Cfm Load</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Summer</th>
                                                                {/* <th>Monsoon</th>
                                                        <th>Winter</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Cfm Load</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.cfmLResult?.toFixed(2)} />
                                                                </td>
                                                                {/* <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" />
                                                        </td> */}
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className='col-12 text-end'>
    
                                    <button className='btn btn_Calculate_hp'  >Export</button>
                                </div> */}
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-Summer" role="tabpanel" aria-labelledby="pills-Summer-tab">
                                    <div className='row align-items-stretch'>

                                        <div className='col-xl-6 col-lg-6 col-md-12 mb-4'>
                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Solar Gain Glass</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Area <samll>(Sq ft)</samll></th>
                                                                <th>Sun Gain  <samll>(Btu/h.sq ft)</samll></th>
                                                                <th>Factor</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>N - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.nGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="14" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainNglassHeatResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>NE - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.neGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="12" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainNEglassHeatResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>E - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.eGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="12" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainEglassHeatResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>SE - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.seGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="12" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainSEglassHeatResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>S - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.sGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="12" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainSglassHeatResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>SW - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.swGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="100" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainSWglassHeatResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>W - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.wGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="164" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainWglassHeatResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>NW - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.nwGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="130" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainNWlassHeatResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Sky light</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.skyLight} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarGainResult?.solarGainSkyLight} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Solar & Trans. Gain Walls & Roof</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Area <samll>(Sq ft)</samll></th>
                                                                <th>Eq. temp. diff. <samll>(F)</samll></th>
                                                                <th>U <samll>(Btu/h.sq ft)</samll></th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>N - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.nTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.solarTransGainWallNglassHeatTemp)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallNglassHeatResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>NE - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.neTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.solarTransGainWallNEglassHeatTemp)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallNEglassHeatResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>E - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.eTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.solarTransGainWallEglassHeatTemp)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallEglassHeatResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>SE - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.seTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.solarTransGainWallSEglassHeatTemp)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallSEglassHeatResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>S - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.sTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.solarTransGainWallSglassHeatTemp)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallSglassHeatResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>SW - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.swTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.solarTransGainWallSwglassHeatTemp)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallSWglassHeatResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>W - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.wTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.solarTransGainWallWglassHeatTemp)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallWglassHeatResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>NW - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.nwTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.solarTransGainWallNWglassHeatTemp)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallNWlassHeatResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Roof Sun</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roofSun} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.solarTransGainWallRoofSunTemp)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.55" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallRoofSunResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Roof Shaded</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roofShaded} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.solarTransGainWallRoofShadedResult)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.solarTransGainWallresult?.solarTransGainWallRoofShadedResult} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Trans. Gain Except Walls & Roof</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Area <samll>(Sq ft)</samll></th>
                                                                <th>Eq. temp. diff. <samll>(F)</samll></th>
                                                                <th>U <samll>(Btu/h.sq ft)</samll></th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>All Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.totalSolarGain} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.transGainExceptWallAllGlassTempDiff)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="1.13" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.transGainExceptWallGlassHeatGainResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Partition</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.partition} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.transGainExceptWallPartitionTempDiff)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.32" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.transGainExceptPartitonHeatGainResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Ceiling</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.size_sqrft} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.transGainExceptWallPartitionTempDiff)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.32" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.transGainExceptCeilingHeatGainResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Floor</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.size_sqrft} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.transGainExceptWallPartitionTempDiff)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.38" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.transGainExceptFloorHeatGainResult} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Internal Heat Gain</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Value</th>
                                                                <th>Value</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>People</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.occupancy} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Nos X" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="245" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.internalHeatGainPeopleBtuResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Light</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.lightInternalHeatValue} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="W X 1.25" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="3.41" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.internalHeatGainLightBtuResult} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Eq. Load</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.eqLoad} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="W X 1.25" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="3.41" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.internalHeatGainEqLoadBtuResult} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Sensible Heat (Rsh)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Room Sensible Heat (Rsh)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.roomSensibleHeatRSH} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Calculate</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Supply duct heat gain +Supply duct leak.<br /> loss +Heat gain from fan HP(%)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="3" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.supplyDuctHeatGainSupplyDuctLeakLossHeatGainFanHP} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Safety factor (%)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="8.5" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.SafetyFactor} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Outside Air</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>CFM</th>
                                                                <th>°F</th>
                                                                <th>BF</th>
                                                                <th>Factor</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>People</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.conditionDifference)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.15" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="1.08" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.outsideAirHeatGainResult} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Sensible Heat (RSH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>EFFECTIVE ROOM SENSIBLE HEAT (ERSH)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.ershResult} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Calculation</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>People</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.occupancy} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Nos X" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="205" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.roomLatentHeatRlh} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Latent Heat (RLH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>ROOM LATENT HEAT (RLH)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.roomLatentHeatRlh} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Sensible Heat (RSH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Supply duct leakage loss + Safety factor %</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="12.5" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.suppluDuctLeakageFactor} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Outside Air</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>CFM</th>
                                                                <th>GR/LB</th>
                                                                <th>BF</th>
                                                                <th>Factor</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.maxResult} /></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.condtionOutSideRoomDifference} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.15" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.68" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.outSideAirResult} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Sensible Heat (RSH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>EFFECTIVE ROOM LATENT HEAT (ERLH)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.effectiveRoomLatentHeatErlh} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Sensible Heat (RSH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>EFFECTIVE ROOM TOTAL HEAT (ERTH)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.effectiveRoomTotalHeatErthSummer} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Outside Air Heat (Sensible)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>CFM</th>
                                                                <th>°F</th>
                                                                <th>1 - BF</th>
                                                                <th>Factor</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.maxResult} /></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.conditionDifference)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.85" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="1.08" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.outSideAirHeatSensibleResult} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>OUTSIDE AIR HEAT (LATENT)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>CFM</th>
                                                                <th>GR/LB</th>
                                                                <th>1 - BF</th>
                                                                <th>Factor</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.maxResult} /></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.condtionOutSideRoomDifference} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.85" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.68" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.outSideAirHeatLatentResult} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>TOTAL HEAT</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>HEAT SUB TOTAL</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.outsideLatentHeatSubTotal} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>ROOM SENSIBLE HEAT (RSH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Return duct heat gain & leak. <br /> loss +Dehum. & Pipe loss (%)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="3.0" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.ductHeatGainLeakLossDehumPipe} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-xl-6 col-lg-6 col-md-12 mb-4'>

                                            <div className='space_input_detail_area mb-4'>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>CONDITION</th>
                                                                <th>DB  <samll>(°F)</samll></th>
                                                                <th>WB  <samll>(°F)</samll></th>
                                                                <th>% RH</th>
                                                                <th>DP  <samll>(°F)</samll></th>
                                                                <th>GR/LB</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>OUTSIDE</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.outside} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.outsideWb} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.outsideRh} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.outsideDp} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.outsideGr} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td><div>ROOM</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.room} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roomWb} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roomRh} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roomDp} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roomGr} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td><div>DIFFERENCE</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.conditionDifference)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="XXX" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="XXX" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="XXX" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.condtionOutSideRoomDifference} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>NOTES</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Occupancy" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.occupancy} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Nos" />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Lighting " />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.lighting} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="W/Sq ft" />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Eq. Load" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.internalHeatGainEquLoadBtuValue} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="KW" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Height" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.height} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Ft" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Fresh Air" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.freshAir} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Cfm / person" />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.AirChange} />
                                                                </td>
                                                                <td colSpan="3">
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Air changes per hourn" />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Internal Heat - People</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Sensible Heat" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="245" />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Latent Heat " />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="205" />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <h4 className='space_input_detail_heading mb-3 mt-4'>Check Figures</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Btu/h/ Sq ft" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonTr?.toFixed(2)} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="CFM / Sq ft" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.dehCfmResult?.toFixed(2)} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Sq ft / TR" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.cfmAcph?.toFixed(2)} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="CFM/ TR" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.reHeat?.toFixed(2)} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>OUTSIDE AIR (VENTILATION)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.occupancy} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="People X" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.freshAir} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value=" CFM/Person" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.outSideAirVentilationPerson} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.size_cuft} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Cu ft X" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="1" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Air change.." />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.outSideAirVentilationAirChange} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td> </td>
                                                                <td> </td>
                                                                <td colSpan="2">
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="CFM VENTILATION" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.maxResult} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                {/* <h4 className='space_input_detail_heading mb-3'>EFF. SENSIBLE HEAT FACTOR (ESHF) =</h4> */}
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="2"><strong>EFF. SENSIBLE HEAT FACTOR (ESHF) = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.effSensibleHeatFactorEshf?.toFixed(2)} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Occupancy" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.occupancy} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Indicated ADP " />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="55 F" />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Selected ADP" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="50 F" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Dehum. temp rise" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.dehumTempRise?.toFixed(2)} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="DEHUMIDIFIED CFM" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.dehumiDifieldCfm?.toFixed(2)} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4 Space_detail_result_bg'>
                                                <h4 className='space_input_detail_heading mb-3'>Results</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Cfm Load" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.cfmLResult?.toFixed(2)} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="SL " />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.dehumSlResult?.toFixed(2)} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="ML" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.TRmonsoon?.toFixed(2)} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4 Space_detail_result_bg'>
                                                <h4 className='space_input_detail_heading mb-3'>Total Heat</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="TR" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.TR?.toFixed(2)} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Total Heat" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.grandTotalHeat} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Reheat" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.reHeat?.toFixed(2)} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-Winter" role="tabpanel" aria-labelledby="pills-Winter-tab">
                                    <div className='row align-items-stretch'>

                                        <div className='col-xl-6 col-lg-6 col-md-12 mb-4'>
                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Solar Gain Glass</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Area <samll>(Sq ft)</samll></th>
                                                                <th>Sun Gain  <samll>(Btu/h.sq ft)</samll></th>
                                                                <th>Factor</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Nasda - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.nGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="4" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainNglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>NE - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.neGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="4" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainNEglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>E - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.eGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="4" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainEglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>SE - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.seGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="4" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainSEglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>S - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.sGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="64" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainSglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>SW - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.swGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="114" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainSWglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>W - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.wGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="92" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainWglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>NW - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.nwGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="10" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainNWlassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Sky light</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.skyLight} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarGainResultWinter?.solarGainSkyLightWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Solar & Trans. Gain Walls & Roof</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Area <samll>(Sq ft)</samll></th>
                                                                <th>Eq. temp. diff. <samll>(F)</samll></th>
                                                                <th>U <samll>(Btu/h.sq ft)</samll></th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>N - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.nTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.winterCal?.solarTransGainWallNglassHeatTempWinter)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallNglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>NE - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.neTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.winterCal?.solarTransGainWallNEglassHeatTempWinter)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallNEglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>E - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.eTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.winterCal?.solarTransGainWallEglassHeatTempWinter)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallEglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>SE - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.seTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.winterCal?.solarTransGainWallSEglassHeatTempWinter)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallSEglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>S - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.sTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.winterCal?.solarTransGainWallSglassHeatTempWinter)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallSglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>SW - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.swTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.winterCal?.solarTransGainWallSwglassHeatTempWinter)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallSWglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>W - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.wTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.winterCal?.solarTransGainWallWglassHeatTempWinter)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallWglassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>NW - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.nwTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.winterCal?.solarTransGainWallNWglassHeatTempWinter)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallNWlassHeatResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Roof Sun</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roofSun} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.winterCal?.solarTransGainWallRoofSunTempWinter)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.55" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallRoofSunResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Roof Shaded</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roofShaded} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.winterCal?.solarTransGainWallRoofShadedResultWinter)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallRoofShadedResultWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Trans. Gain Except Walls & Roof</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Area <samll>(Sq ft)</samll></th>
                                                                <th>Eq. temp. diff. <samll>(F)</samll></th>
                                                                <th>U <samll>(Btu/h.sq ft)</samll></th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>All Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.totalSolarGain} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.winterCal?.transGainExceptWallAllGlassTempDiffWinter)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="1.13" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.transGainExceptWallGlassHeatGainResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Partition</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.partition
                                                                    } />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.winterCal?.transGainExceptWallPartitionTempDiffWinter)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.32" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.transGainExceptPartitonHeatGainResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Ceiling</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.size_sqrft} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.winterCal?.transGainExceptCeilingHeatGainTemp)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.38" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.transGainExceptCeilingHeatGainResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Floor</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.size_sqrft} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.winterCal?.transGainExceptFloorHeatGainTemp)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.38" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.transGainExceptFloorHeatGainResultWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Internal Heat Gain</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Value</th>
                                                                <th>Value</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>People</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.occupancy} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Nos X" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="245" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.internalHeatGainPeopleBtuResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Light</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.lightInternalHeatValueWinter} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="W X 1.25" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="3.41" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.internalHeatGainLightBtuResultWinter} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Eq. Load</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.internalHeatGainEquLoadBtuValueWinter} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="W X 1.25" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="3.41" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.internalHeatGainEqLoadBtuResultWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Sensible Heat (Rsh)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Room Sensible Heat (Rsh)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.roomSensibleHeatRSHWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Calculate</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Supply duct heat gain +Supply duct leak.<br /> loss +Heat gain from fan HP(%)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="4" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.supplyDuctHeatGainSupplyDuctLeakLossHeatGainFanHP} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Safety factor (%)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="8.5" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.SafetyFactorWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Outside Air</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>CFM</th>
                                                                <th>°F</th>
                                                                <th>BF</th>
                                                                <th>Factor</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>People</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.winterCal?.conditionDifference)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.15" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="1.08" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.outsideAirHeatGainResultWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Sensible Heat (RSH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>EFFECTIVE ROOM SENSIBLE HEAT (ERSH)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.ershResultWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Calculation</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>People</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.occupancy} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Nos X" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="205" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.roomLatentHeatRlhWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Latent Heat (RLH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>ROOM LATENT HEAT (RLH)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.roomLatentHeatRlhWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Sensible Heat (RSH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Supply duct leakage loss + Safety factor %</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="5.0" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.suppluDuctLeakageFactorWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Outside Air</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>CFM</th>
                                                                <th>GR/LB</th>
                                                                <th>BF</th>
                                                                <th>Factor</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.maxResultWinter} /></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.condtionOutSideRoomDifferenceWinter} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.15" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.68" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.outSideAirResultWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Sensible Heat (RSH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>EFFECTIVE ROOM LATENT HEAT (ERLH)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.effectiveRoomLatentHeatErlhWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Sensible Heat (RSH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>EFFECTIVE ROOM TOTAL HEAT (ERTH)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.effectiveRoomTotalHeatErth} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Outside Air Heat (Sensible)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>CFM</th>
                                                                <th>°F</th>
                                                                <th>1 - BF</th>
                                                                <th>Factor</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.maxResultWinter} /></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.winterCal?.conditionDifference)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.85" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="1.08" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.outSideAirHeatSensibleResult} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>OUTSIDE AIR HEAT (LATENT)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>CFM</th>
                                                                <th>GR/LB</th>
                                                                <th>1 - BF</th>
                                                                <th>Factor</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.maxResultWinter} /></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.condtionOutSideRoomDifferenceWinter} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.85" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.68" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.outSideAirHeatLatentResult} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>TOTAL HEAT</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>HEAT SUB TOTAL</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.outsideLatentHeatSubTotal} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>ROOM SENSIBLE HEAT (RSH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Return duct heat gain & leak. <br /> loss +Dehum. & Pipe loss (%)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="3.0" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.ductHeatGainLeakLossDehumPipe} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-xl-6 col-lg-6 col-md-12 mb-4'>

                                            <div className='space_input_detail_area mb-4'>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>CONDITION</th>
                                                                <th>DB  <samll>(°F)</samll></th>
                                                                <th>WB  <samll>(°F)</samll></th>
                                                                <th>% RH</th>
                                                                <th>DP  <samll>(°F)</samll></th>
                                                                <th>GR/LB</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>OUTSIDE</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.outsideWinterDb} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.outsideWinterWb} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.outsideWinterRh} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.outsideWinterDp} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.outsideWinterGr} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td><div>ROOM</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roomWinterDb} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roomWinterWb} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roomWinterRh} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roomWinterDp} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roomWinterGr} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td><div>DIFFERENCE</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.winterCal?.conditionDifference)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="XXX" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="XXX" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="XXX" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.condtionOutSideRoomDifferenceWinter} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>NOTES</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Occupancy" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.occupancy} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Nos" />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Lighting " />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.lighting} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="W/Sq ft" />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Eq. Load" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.eqLoad} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="KW" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Height" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.height} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Ft" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Fresh Air" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.freshAir} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Cfm / person" />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.AirChange} />
                                                                </td>
                                                                <td colSpan="3">
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Air changes per hourn" />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Internal Heat - People</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Sensible Heat" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="245" />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Latent Heat " />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="205" />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                {/* <h4 className='space_input_detail_heading mb-3 mt-4'>Check Figures</h4> */}
                                                {/* <div className='table-responsive Space_Result_details_area'>
                                            <table className='table table-hover'>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="Btu/h/ Sq ft" />
                                                        </td>
                                                        <td><strong> = </strong></td>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="" />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="CFM / Sq ft" />
                                                        </td>
                                                        <td><strong> = </strong></td>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="" />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="Sq ft / TR" />
                                                        </td>
                                                        <td><strong> = </strong></td>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="" />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="CFM/ TR" />
                                                        </td>
                                                        <td><strong> = </strong></td>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> */}
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>OUTSIDE AIR (VENTILATION)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.occupancy} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="People X" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.freshAir} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value=" CFM/Person" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.outSideAirVentilationPersonWinter} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.size_cuft} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Cu ft X" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="1" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Air change.." />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.outSideAirVentilationAirChange} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td> </td>
                                                                <td> </td>
                                                                <td colSpan="2">
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="CFM VENTILATION" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.maxResult} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                {/* <h4 className='space_input_detail_heading mb-3'>EFF. SENSIBLE HEAT FACTOR (ESHF) =</h4> */}
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="2"><strong>EFF. SENSIBLE HEAT FACTOR (ESHF) = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.effSensibleHeatFactorEshf?.toFixed(2)} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Occupancy" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.occupancy} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Indicated ADP " />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="55 F" />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Selected ADP" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="54 F" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Dehum. temp rise" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.dehumTempRise?.toFixed(2)} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="DEHUMIDIFIED CFM" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.dehumiDifieldCfm?.toFixed(2)} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            {/* <div className='space_input_detail_area mb-4 Space_detail_result_bg'>
                                        <h4 className='space_input_detail_heading mb-3'>Results</h4>
                                        <div className='table-responsive Space_Result_details_area'>
                                            <table className='table table-hover'>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="Cfm Load" />
                                                        </td>
                                                        <td><strong> = </strong></td>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="" />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="SL " />
                                                        </td>
                                                        <td><strong> = </strong></td>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="" />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="ML" />
                                                        </td>
                                                        <td><strong> = </strong></td>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> */}

                                            <div className='space_input_detail_area mb-4 Space_detail_result_bg'>
                                                <h4 className='space_input_detail_heading mb-3'>Total Heat</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="TR" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.TR?.toFixed(2)} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Total Heat" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.grandTotalHeat} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Reheat" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.reHeat?.toFixed(2)} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="SQFT/KW" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.winterCal?.sqftKwResultWinter?.toFixed(2)} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-Monsoon" role="tabpanel" aria-labelledby="pills-Monsoon-tab">
                                    <div className='row align-items-stretch'>

                                        <div className='col-xl-6 col-lg-6 col-md-12 mb-4'>
                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Solar Gain Glass</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Area <samll>(Sq ft)</samll></th>
                                                                <th>Sun Gain  <samll>(Btu/h.sq ft)</samll></th>
                                                                <th>Factor</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>N - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.nGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="11" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainNglassHeatResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>NE - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.neGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="11" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainNEglassHeatResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>E - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.eGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="11" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainEglassHeatResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>SE - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.seGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="11" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainSEglassHeatResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>S - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.sGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="13" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainSglassHeatResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>SW - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.swGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="127" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainSWglassHeatResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>W - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.wGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="165" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainWglassHeatResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>NW - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.nwGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="100" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.56" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainNWlassHeatResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Sky light</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.skyLight} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarGainResultMonsoon?.solarGainSkyLightMonsoon} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Solar & Trans. Gain Walls & Roof</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Area <samll>(Sq ft)</samll></th>
                                                                <th>Eq. temp. diff. <samll>(F)</samll></th>
                                                                <th>U <samll>(Btu/h.sq ft)</samll></th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>N - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.nTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.monsoonCal?.solarTransGainWallNglassHeatTempMonsoon)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallNglassHeatResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>NE - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.neTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.monsoonCal?.solarTransGainWallNEglassHeatTempMonsoon)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallNEglassHeatResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>E - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.eTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.monsoonCal?.solarTransGainWallEglassHeatTempMonsoon)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallEglassHeatResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>SE - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.seTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.monsoonCal?.solarTransGainWallSEglassHeatTempMonsoon)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallSEglassHeatResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>S - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.sTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.monsoonCal?.solarTransGainWallSglassHeatTempMonsoon)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallSglassHeatResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>SW - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.swTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.monsoonCal?.solarTransGainWallSwglassHeatTempMonsoon)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallSWglassHeatResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>W - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.wTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.monsoonCal?.solarTransGainWallWglassHeatTempMonsoon)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallWglassHeatResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>NW - Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.nwTransGainWallGlass} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.monsoonCal?.solarTransGainWallNWglassHeatTempMonsoon)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallNWlassHeatResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Roof Sun</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roofSun} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.monsoonCal?.solarTransGainWallRoofSunTempMonsoon)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.55" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallRoofSunResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Roof Shaded</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roofShaded} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.monsoonCal?.solarTransGainWallRoofShadedResultMonsoon)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.36" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.solarTransGainWallresultMonsoon?.solarTransGainWallRoofShadedResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Trans. Gain Except Walls & Roof</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Area <samll>(Sq ft)</samll></th>
                                                                <th>Eq. temp. diff. <samll>(F)</samll></th>
                                                                <th>U <samll>(Btu/h.sq ft)</samll></th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>All Glass</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.totalSolarGain} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.monsoonCal?.transGainExceptWallAllGlassTempDiffMonsoon)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="1.13" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.transGainExceptWallGlassHeatGainResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Partition</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.partition} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.monsoonCal?.transGainExceptWallPartitionTempDiffMonsoon)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.32" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.transGainExceptPartitonHeatGainResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Ceiling</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.size_sqrft} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.monsoonCal?.transGainExceptWallPartitionTempDiffMonsoon)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.32" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.transGainExceptCeilingHeatGainResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Floor</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.size_sqrft} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.monsoonCal?.transGainExceptWallPartitionTempDiffMonsoon)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.38" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.transGainExceptFloorHeatGainResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Internal Heat Gain</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Value</th>
                                                                <th>Value</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>People</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.occupancy} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Nos X" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="245" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.internalHeatGainPeopleBtuResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Light</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.lightInternalHeatValue} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="W X 1.25" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="3.41" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.internalHeatGainLightBtuResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Eq. Load</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.eqLoad} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="W X 1.25" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="3.41" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.internalHeatGainEqLoadBtuResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Sensible Heat (Rsh)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Room Sensible Heat (Rsh)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.roomSensibleHeatRSHMonsoon} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Calculate</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Supply duct heat gain +Supply duct leak.<br /> loss +Heat gain from fan HP(%)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="3" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.supplyDuctHeatGainSupplyDuctLeakLossHeatGainFanHP} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div>Safety factor (%)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="8.5" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.SafetyFactorMonsoon} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Outside Air</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>CFM</th>
                                                                <th>°F</th>
                                                                <th>BF</th>
                                                                <th>Factor</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>People</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.monsoonCal?.conditionDifference)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.15" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="1.08" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.outsideAirHeatGainResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Sensible Heat (RSH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>EFFECTIVE ROOM SENSIBLE HEAT (ERSH)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.ershResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Calculation</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>People</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.occupancy} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Nos X" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="205" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.roomLatentHeatRlhMonsoon} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Latent Heat (RLH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>ROOM LATENT HEAT (RLH)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.roomLatentHeatRlhMonsoon} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Sensible Heat (RSH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Supply duct leakage loss + Safety factor %</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="12.5" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.suppluDuctLeakageFactorMonsoon} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Outside Air</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>CFM</th>
                                                                <th>GR/LB</th>
                                                                <th>BF</th>
                                                                <th>Factor</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.maxResultMonsoon} /></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.condtionOutSideRoomDifferenceMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.15" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.68" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.outSideAirResultMonsoon} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Sensible Heat (RSH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>EFFECTIVE ROOM LATENT HEAT (ERLH)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.effectiveRoomLatentHeatErlhMonsoon} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Room Sensible Heat (RSH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>EFFECTIVE ROOM TOTAL HEAT (ERTH)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.effectiveRoomTotalHeatErth} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Outside Air Heat (Sensible)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>CFM</th>
                                                                <th>°F</th>
                                                                <th>1 - BF</th>
                                                                <th>Factor</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.maxResultMonsoon} /></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.monsoonCal?.conditionDifference)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.85" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="1.08" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.outSideAirHeatSensibleResult} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>OUTSIDE AIR HEAT (LATENT)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>CFM</th>
                                                                <th>GR/LB</th>
                                                                <th>1 - BF</th>
                                                                <th>Factor</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.maxResultMonsoon} /></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.condtionOutSideRoomDifferenceMonsoon} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.85" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="0.68" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.outSideAirHeatLatentResult} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>TOTAL HEAT</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>HEAT SUB TOTAL</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.outsideLatentHeatSubTotal} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>ROOM SENSIBLE HEAT (RSH)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th>Value</th>
                                                                <th>Heat Gain</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>Return duct heat gain & leak. <br /> loss +Dehum. & Pipe loss (%)</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="3.0" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.ductHeatGainLeakLossDehumPipe} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-xl-6 col-lg-6 col-md-12 mb-4'>

                                            <div className='space_input_detail_area mb-4'>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>CONDITION</th>
                                                                <th>DB  <samll>(°F)</samll></th>
                                                                <th>WB  <samll>(°F)</samll></th>
                                                                <th>% RH</th>
                                                                <th>DP  <samll>(°F)</samll></th>
                                                                <th>GR/LB</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div>OUTSIDE</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.outsideMonsoonDb} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.outsideMonsoonWb} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.outsideMonsoonRh} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.outsideMonsoonDp} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.outsideMonsoonGr} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td><div>ROOM</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roomMonsoonDb} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roomMonsoonWb} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roomMonsoonRh} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roomMonsoonDp} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.roomMonsoonGr} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td><div>DIFFERENCE</div></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={Math.round(InputValueData?.space_output?.monsoonCal?.conditionDifference)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="XXX" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="XXX" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="XXX" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.condtionOutSideRoomDifferenceMonsoon} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>NOTES</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Occupancy" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.occupancy} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Nos" />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Lighting " />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.lighting} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="W/Sq ft" />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Eq. Load" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.eqLoad} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="KW" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Height" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.height} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Ft" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Fresh Air" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.freshAir} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Cfm / person" />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.AirChange} />
                                                                </td>
                                                                <td colSpan="3">
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Air changes per hourn" />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>Internal Heat - People</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Sensible Heat" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="245" />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Latent Heat " />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="205" />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <h4 className='space_input_detail_heading mb-3 mt-4'>Check Figures</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Btu/h/ Sq ft" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.btuHsqftMonsoon?.toFixed(2)} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="CFM / Sq ft" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.dehCfmResultMonsoon?.toFixed(2)} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Sq ft / TR" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.cfmAcphMonsoon?.toFixed(2)} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="CFM/ TR" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.cfmTrMonsoon?.toFixed(2)} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                <h4 className='space_input_detail_heading mb-3'>OUTSIDE AIR (VENTILATION)</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.occupancy} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="People X" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.freshAir} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value=" CFM/Person" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.outSideAirVentilationPerson} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.size_cuft} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Cu ft X" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="1" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Air change.." />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.outSideAirVentilationAirChange} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td> </td>
                                                                <td> </td>
                                                                <td colSpan="2">
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="CFM VENTILATION" />
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.maxResult} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='space_input_detail_area mb-4'>
                                                {/* <h4 className='space_input_detail_heading mb-3'>EFF. SENSIBLE HEAT FACTOR (ESHF) =</h4> */}
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="2"><strong>EFF. SENSIBLE HEAT FACTOR (ESHF) = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.effSensibleHeatFactorEshf?.toFixed(2)} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Occupancy" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_input?.occupancy} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Indicated ADP " />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="55 F" />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Selected ADP" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="54 F" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Dehum. temp rise" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.dehumTempRise?.toFixed(2)} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="DEHUMIDIFIED CFM" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.dehumiDifieldCfmMonsoon?.toFixed(2)} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            {/* <div className='space_input_detail_area mb-4 Space_detail_result_bg'>
                                        <h4 className='space_input_detail_heading mb-3'>Results</h4>
                                        <div className='table-responsive Space_Result_details_area'>
                                            <table className='table table-hover'>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="Cfm Load" />
                                                        </td>
                                                        <td><strong> = </strong></td>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="" />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="SL " />
                                                        </td>
                                                        <td><strong> = </strong></td>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="" />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="ML" />
                                                        </td>
                                                        <td><strong> = </strong></td>
                                                        <td>
                                                            <input type="text" name="" id="input" readOnly class="form-control" value="" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> */}

                                            <div className='space_input_detail_area mb-4 Space_detail_result_bg'>
                                                <h4 className='space_input_detail_heading mb-3'>Total Heat</h4>
                                                <div className='table-responsive Space_Result_details_area'>
                                                    <table className='table table-hover'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="TR" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.TRmonsoon?.toFixed(2)} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Total Heat" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.grandTotalHeat} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value="Reheat" />
                                                                </td>
                                                                <td><strong> = </strong></td>
                                                                <td>
                                                                    <input type="text" name="" id="input" readOnly class="form-control" value={InputValueData?.space_output?.monsoonCal?.reHeatMonsoon?.toFixed(2)} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </section>
            </div>
                    </>
    )
}

export default withAuth(SpaceProjectDetail);