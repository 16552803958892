import React from "react";
// import axios from "axios";
import { useState } from "react";
// import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
// import { API_URL } from "../../Services/UserServices";
import { Backdrop, Box, CircularProgress } from "@mui/material";
// import Sign_Up from "../../assets/Sign_Up.png";
import Sign_Up from "../../../src/assets/logo.svg";
import { ToastContainer, toast } from "react-toastify";
import api from "../../Services/ApiService";


function ForgetPassword() {
  const [email, setEmail] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [emailValid, setEmailValid] = useState(true); // Add email validity state
  const [emailFormatValid, setEmailFormatValid] = useState(true);
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


  const onSubmitHandlerForgotPassword = async (e) => {
    e.preventDefault();

    // Validate email
    if (!email) {
      setEmailValid(false);
      return;
    }
    if (!emailRegex.test(email)) {
      setEmailFormatValid(false);
      return;
    }
    setLoading(true)
    try {
      e.preventDefault();
      localStorage.setItem("userEmail", email);
      // Replace 'YOUR_API_ENDPOINT' with the actual endpoint of your server for the "Forgot Password" API
      const response = await api.post(
        `/user/forget-password?` +
        `email=${email}`,{devMode:true}
      );

      // Check if the response indicates success (modify the condition based on your API response structure)
      if (response?.data?.statusCode == 200) {

        toast.success("OTP has been shared to your email address");

        // Show a success message to the user
        // Swal.fire({
        //   title: "Success!",
        //   text: "OTP has been shared to your email address",
        //   icon: "success",
        //   button: "OK",
        // });

        setTimeout(() => {
          navigate("/OtpVerification");
        }, 2000);


      }
      else {
        // Swal.fire({
        //   title: "Error!",
        //   text: "Provided email does not exists.",
        //   icon: "error",
        //   button: "OK",
        // });
        setEmail('');
        toast.error("Provided email does not exists");


      }
    } catch (error) {

      if (error?.response?.data?.statusCode === 400) {
        // Swal.fire({
        //   title: "Error!",
        //   text: "email must be a valid",
        //   icon: "error",
        //   button: "OK",
        // });
        toast.error("Email must be a valid");

      }
      else {
        // Swal.fire({
        //   title: "Error!",
        //   text: error.message,
        //   icon: "error",
        //   button: "OK",
        // });
        toast.error(error?.response?.data?.message);

      }
    }
    setEmail('')
    setLoading(false)
  };
  // if (loading) {
  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         marginTop: "20%",
  //       }}
  //     >
  //       <Backdrop
  //         sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
  //         open={loading}
  //       >
  //         <CircularProgress />
  //       </Backdrop>
  //     </Box>
  //   );
  // }
  return (
    <>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress />
          </Backdrop>
        </Box>
      )
      }



      <div className="container-fluid bg-login_page bg_Register_page bg_ForgetPassword_page">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-md-12 col-lg-7 col-xl-6">
              <div className="card card_custom_card">
                <div className="card-body card_custom">
                  <div className="text-center">
                    <a href="/">
                      <img src={Sign_Up} className="img-fluid logo_img" alt="" />
                    </a>
                  </div>
                  <div className="login_heading_Area">
                    <h3 className="fw-bold text-center my-2">Forgot Password</h3>
                    <p className="bottom-nav_headeing">
                      Enter your registered email
                    </p>
                  </div>
                  <form onSubmit={onSubmitHandlerForgotPassword}>
                    <div className="col-md-12 mb-3">
                      {/* <label className="form-label">Email<span className="redP">*</span></label> */}
                      <input
                        type="text"
                        className="form-control form-control_custom"
                        value={email || ""}
                        maxLength="40"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailValid(true); // Reset email validity on change
                          setEmailFormatValid(true);
                        }}
                        placeholder="Ex.-email@gmail.com"
                      />
                      {!emailValid && (
                        <span className="redP">
                          Email required
                        </span>
                      )}
                      {!emailFormatValid && (
                        <span className="redP">Invalid email format</span>
                      )}
                    </div>
                    <button className="btn Sign_btn_submit w-100" type="submit">
                      Send
                    </button>


                    <div className="text-center mt-3">
                      <a href="/login">Back to login</a>
                    </div>


                  </form>
                </div>


              </div>

            </div>


          </div>
          <ToastContainer />

        </div>
      </div >






      {/* <form onSubmit={onSubmitHandlerForgotPassword}>
        <section className="container-fluid Sign-section  Sign-section-Password">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="card Sign-Up-card-section">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <h3 className="fw-bold">Forgot Password</h3>
                     
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Email<span className="redP">*</span></label>
                        <input
                          type="text"
                          className="form-control "
                          value={email || ""}
                          maxLength="40"
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailValid(true); 
                            setEmailFormatValid(true);
                          }}
                          placeholder="Ex.-email@gmail.com"
                        />
                        {!emailValid && (
                          <span className="redP">
                            email required
                          </span>
                        )}
                        {!emailFormatValid && (
                          <span className="redP">Invalid email format</span>
                        )}
                      </div>
                      
                      <div>
                        <button className="btn Sign_btn_submit" type="submit">
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="card Sign-Up-card-section-img">
                  <div className="card-body">
                    <img src={Sign_Up} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form> */}









    </>
  );
}

export default ForgetPassword;
