import React from 'react'
import { Tooltip } from 'react-tooltip';


function AddMachineForm() {


    const handleBack = () => {
        window.history.back()
    }

    return (
        <>
            <section className="container-fluid PageTitle_banner_section">
                <div className="container">
                    <h1 className="PageTitle_banner_heading">Add New Machine</h1>
                </div>
            </section>

            <section className="container-fluid Add_machine_Form_section">
                <div className="container">
                    <div className='Add_machine_Form_area'>
                        <form>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='Add_machine_Form_heading'>
                                        <a className="back_arrow " href='/MachineList'
                                            data-tooltip-id="BackBtn"
                                            data-tooltip-content="Back"
                                        >
                                            <i class="fa-solid fa-arrow-left-long"></i>
                                            <Tooltip id="BackBtn" />
                                        </a>
                                        <h4 className=''>New Machine</h4>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-3'>
                                    <div className='form-group'>
                                        <label>Machine Name</label>
                                        <input type="text" placeholder='Enter Machine Name' className='form-control' />
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-3'>
                                    <div className='form-group'>
                                        <label>Company Name</label>
                                        <input type="text" placeholder='Enter Company Name' className='form-control' />
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-3'>
                                    <div className='form-group'>
                                        <label>AC Voltage (Hz)</label>
                                        <input type="text" placeholder='Enter AC voltage' className='form-control' />
                                    </div>
                                </div>

                                <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-3'>
                                    <div className='form-group'>
                                        <label>Air Flow (CMH)</label>
                                        <input type="number" placeholder='Enter Air Flow' className='form-control' />
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-3'>
                                    <div className='form-group'>
                                        <label>Air Flow (CFM)</label>
                                        <input type="number" placeholder='Enter Air Flow' className='form-control' />
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-3'>
                                    <div className='form-group'>
                                        <label>Water Capacity (Ltr)</label>
                                        <input type="text" placeholder='Enter Water Capacity' className='form-control' />
                                    </div>
                                </div>

                                <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-4'>
                                    <div className='form-group'>
                                        <label>Machine Image</label>
                                        <input type="file" placeholder='Enter Water Tank Capacity' className='form-control' />
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='form-group text-end'>
                                        <button className='btn mx-1 cancel_btn_hp'>Cancel</button>
                                        <button className='btn mx-1'>Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>



        </>
    )
}

export default AddMachineForm