import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
// import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import { API_URL } from '../../Services/UserServices';
// import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../provider/AuthProvider';
import api from '../../Services/ApiService';


const PaymentSuccessMessage = () => {

const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const {user} = useAuth()

  const location = useLocation();

  if (loading){
    
    <div
    className="spinner-border"
    role="status"
  >
    <span className="visually-hidden">
      Loading...
    </span>
  </div>
  }
  

  const FetchPaymentSuccess = async () => {
    const queryParams = new URLSearchParams(location.search);
    const paymentId = queryParams.get('paymentId');
    const token = queryParams.get('token');
    const payerId = queryParams.get('PayerID');

    setLoading(true)
    
    try {
      const response = await api.get(`/payment/success`,{
        params: {
          paymentId: paymentId,
          token: token,
          PayerID: payerId,
          cacheBuster: Date.now(), // Add a cache-busting parameter
        },
        headers:{
          Authorization:`Bearer ${user?.token}`
     }
      });
  
      if (response.data.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Payment completed successfully.",
          confirmButtonText: "OK", // Add the "OK" button
        }).then(() => {
          // This code will run after the user clicks the "OK" button
          window.location.href = "/Project";
        });
        const user = JSON.parse(localStorage.getItem("user") ?? '{}');
        if(user){
          user.paid = "paid";
          localStorage.setItem("user",JSON.stringify(user))
        }
  
        // If the API call is successful (status code 200), update the state with the fetched data
       
      } else {
        // If the status code is not 200, show an error message using SweetAlert
        // Swal.fire({
        //   icon: "error",
        //   title: "Error",
        //   text: "Payment failed.",
        // });
        navigate("/PaymentFailed")
      }
    } catch (error) {
      // If there's an error in the API call, show an error message using SweetAlert
      if(error?.response?.status == 401){
        localStorage.removeItem("user")
        window.location.href = '/Register';
      }else{

        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message == "Network Error" ? "Please check your internet connection.": error.message,
        });

      }
     
    
    }
   setLoading(false);

  };


  useEffect(() => {
    FetchPaymentSuccess();
  }, []);

  if (loading){
    
  return ( 
    
    <div className="not-found-container">
    <div
    className="spinner-border"
    role="status"
  >
    <span className="visually-hidden">
      Loading...
    </span>
  </div>
  </div>)
  }



 // Empty dependency array to ensure the API is fetched only once on component mount.

   
 

  return (<div className="not-found-container" >
  <h1 >Congrats </h1>
 <p>Payment Done Successfully </p>
 <Link   to="/"  style={{textDecoration:"none"}} >  go to home page </Link>
</div>);
};

export default PaymentSuccessMessage;
