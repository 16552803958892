import { useEffect, useRef, useState } from "react";
import React from "react";
import Swal from "sweetalert2";
import DirectoryTreeView from "../TreeView";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useAuth } from "../../provider/AuthProvider";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Backdrop, CircularProgress } from "@mui/material/index";
import { useNavigate } from "react-router-dom";
import DuctsList, { flattenArray } from "../DuctsList";
import * as XLSX from "xlsx";
import "sheetjs-style";
import api from "../../Services/ApiService";
import { toast } from "react-toastify";
import withAuth from "../WithAuth";
import { v4 as uuidv4 } from 'uuid';
import {
  CalcualteImperialDuctDiameter,
  calculateAspectRatio,
  calculateDia,
  calculateDiameter,
  calculateDiameterFromFriction,
  calculateDiameterFromVelocity,
  calculateDuctWidth,
  calculateFlowArea,
  calculateFrictionFromVelocity,
  calculateFrictionLoss,
  calculateFrictionLossByAirAndDia,
  calculateVelo,
  calculateVelocity,
  calculateVelocityFromDiameter,
  findHighestBranchIndex,
  findNode,
  findParent,
  findRecord,
  getwidthImperial,
} from "../../utils/helper";
const Unit = {
  SI: "SI",
  IMPERIAL: "Imperial",
};






const Calculator = () => {
  const { user } = useAuth();
  const [Cbunit, setCbunit] = useState(Unit.SI);
  const [ducts, setDucts] = useState([]);
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();
  const [isDisable ,setIsDisable] = useState(false);
  const [isSavedShow , isSetSavedShow] = useState(false);
  const [selectedDuct, setSelectedDuct] = useState({
    id: uuidv4(),
    frictionLoss: "",
    velocity: "",
    width: "",
    height: "",
    lockStatus: false
  });
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState("");
  const [buttonStatus, setButtonStatus] = useState(true);
   const [isCalculating, setIsCalculating] = useState(false);
   const navigateToProject = () => {
    navigate("/Project");
  };

  const getProjectData = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/calculation/getOne/${localStorage.getItem("projectID")}`
      );
      if (response.data.statusCode === 200) {
        const projectData = response.data.data;
        setCbunit(projectData.unit);
        setDucts(projectData.ductData);
        if (projectData?.ductData?.length > 0) {
          setSelectedDuct({
            ...projectData.ductData[0],
            lockStatus: false
          })
        }
        setProject(projectData.productName);
        isSetSavedShow(true);
      }
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user) { return null };

    getProjectData();

    // const isPaid = user?.p}aid === "paid";

    // const isNotSubscribed = !user?.subscription;


    // setButtonStatus(!isPaid && isNotSubscribed);
    const isUserPaidAndSubscribed = user?.paid === "paid" && user?.userSubs;
    setButtonStatus(!isUserPaidAndSubscribed);
  }, [user]);

  const handleMainCalculation = () => {
    setIsCalculating(true);
    // const { frictionLoss, lockStatus } = selectedDuct || {}; 
    // if ((frictionLoss === 0 || frictionLoss === "0" || selectedDuct?.frictionLoss == "0.0" || selectedDuct?.frictionLoss === "0.00" || selectedDuct?.frictionLoss === "0") && !lockStatus) {
    //   toast.error("Friction Loss a cannot be 0 for calculation.");
    //   return;
    // }

    if (!project) {
      toast.error("Enter project name.");
      return
    }
    if (!selectedDuct?.name) {
      toast.dismiss(); 
      toast.error("Enter duct name.");
      return
    }
    if (!selectedDuct) {
      toast.dismiss(); 
      toast.error("Select duct/branch.")
      return
    }
    try {
      setLoading(true);
      if (Cbunit === Unit.SI) {
        handleCalculations();
      } else {
        handleImperialCalculation();
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  // const handleCalculations = () => {
  //   let h = 200;
  //   let flow = 0;
  //   let dia = 0;
  //   let fr = 0;
  //   let r = 50;
  //   let h1 = 250,
  //     h2 = 300,
  //     h3 = 350,
  //     h4 = 400,
  //     h5 = 500,
  //     h6 = 600;
  //   let width1 = 0,
  //     width2 = 0,
  //     width3 = 0,
  //     width4 = 0,
  //     width5 = 0,
  //     width6 = 0;
  //   let level = 1;
  //   const hasPaid =
  //     user?.usertype === "paid" ||
  //     user?.paid === "paid" ||
  //     user?.paid === "unpaid";

  //   if (hasPaid) {
  //     try {
  //       if (!selectedDuct?.name) {
  //         Swal.fire("Select Duct/Branch.");
  //         return;
  //       }
  //       let velo;

  //       if (
  //         !selectedDuct?.airQuantity ||
  //         isNaN(parseFloat(selectedDuct?.airQuantity))
  //       ) {
  //         setErrorMessage("Please Enter Air Quantity.");
  //         return;
  //       }
  //       const convertFactor = 1;
  //       if (Cbunit === Unit.SI) {
  //         flow = selectedDuct.airQuantity;
  //       }

  //       if (!selectedDuct.lockStatus) {
  //         if (level <= 0) {
  //           return;
  //         }

  //         // if (!selectedDuct.frictionLoss && !selectedDuct.velocity) {
  //         //   Swal.fire("Please provide either friction loss or velocity");
  //         //   return;
  //         // }

  //         // if (selectedDuct.frictionLoss && selectedDuct.velocity) {
  //         //   Swal.fire("Please delete either friction loss or velocity");
  //         //   return;
  //         // }
  //         if (
  //           (selectedDuct.frictionLoss == 0 ||
  //             selectedDuct.frictionLoss === undefined) &&
  //           !selectedDuct.velocity
  //         ) {
  //           Swal.fire("friction must be greater than zero.");
  //           return;
  //         }
  //         if (
  //           (selectedDuct.velocity == 0 ||
  //             selectedDuct.velocity === undefined) &&
  //           !selectedDuct.frictionLoss
  //         ) {
  //           Swal.fire("velocity must be greater than zero.");
  //           return;
  //         }

  //         if (selectedDuct.frictionLoss) {
  //           fr = selectedDuct.frictionLoss;
  //           dia = calculateDiameterFromFriction(flow, fr);
  //           velo = calculateVelocityFromDiameter(flow, dia, fr);
  //         } else if (selectedDuct.velocity) {
  //           velo = selectedDuct.velocity;
  //           dia = calculateDiameterFromVelocity(flow, velo);
  //           fr = calculateFrictionFromVelocity(velo, dia);
  //         }

  //         let w = calculateDuctWidth(dia, selectedDuct?.height, r);
  //         var veloResult = 0;
  //         if (selectedDuct.frictionLoss !== "" || !isNaN(selectedDuct.frictionLoss)) {
  //           veloResult = calculateVelo(
  //             selectedDuct.frictionLoss,
  //             calculateDia(selectedDuct.frictionLoss, flow)
  //           ).toFixed(2);
  //         }

  //         selectedDuct.width = w;
  //         selectedDuct.diaMeter = Math.round((dia * 1000).toFixed(2));
  //         selectedDuct.velocity = veloResult ? veloResult : velo;
  //         if (typeof fr === "number") {
  //           selectedDuct.frictionLoss = fr.toFixed(2);
  //         }

  //         if(flow > 0 || velo > 0){
  //           selectedDuct.flowArea = (flow / 1000 / velo).toFixed(2);
  //         }

  //         for (let i = 1; i <= 6; i++) {
  //           const hi = eval(`h${i}`);
  //           const width = calculateDuctWidth(dia, hi * convertFactor, r);
  //           eval(`width${i} = ${width}`);
  //         }
  //       }

  //       if (selectedDuct.lockStatus) {
  //         if (selectedDuct.width === "" && height === "" && h === 0) {
  //           dia = (1.3 * Math.pow(r * h, 0.625)) / Math.pow(r + h, 0.25);
  //           fr =
  //             (0.9 * Math.pow(0.0344198, 4.86) * Math.pow(flow, 1.82)) /
  //             Math.pow(dia / 1000, 4.86);

  //           selectedDuct.diaMeter = Math.round(
  //             dia * 1,
  //             0
  //           );
  //           selectedDuct.frictionLoss = Math.round(
  //             fr * 1,
  //             2
  //           );
  //           selectedDuct.velocity = Math.round(
  //             10.91 *
  //               Math.pow(fr, 0.54) *
  //               Math.pow(dia / 1000, 0.67) *
  //               1,
  //             1
  //           );
  //           selectedDuct.flowArea = (
  //             flow /
  //             1000 /
  //             selectedDuct.velocity
  //           ).toFixed(2);
  //         }
  //       }

  //       if (!selectedDuct.frictioninputStatus) {
  //         selectedDuct.frictioninputStatus = true;
  //         selectedDuct.frictionStatus = true;
  //       } else {
  //         selectedDuct.velocityinputStatus = true;
  //         selectedDuct.velocityStatus = true;
  //       }
  //       selectedDuct.width1 = width1;
  //       selectedDuct.width2 = width2;
  //       selectedDuct.width3 = width3;
  //       selectedDuct.width4 = width4;
  //       selectedDuct.width5 = width5;
  //       selectedDuct.width6 = width6;

  //       selectedDuct.ratio1 = calculateAspectRatio(width1, h1);
  //       selectedDuct.ratio2 = calculateAspectRatio(width2, h2);
  //       selectedDuct.ratio3 = calculateAspectRatio(width3, h3);
  //       selectedDuct.ratio4 = calculateAspectRatio(width4, h4);
  //       selectedDuct.ratio5 = calculateAspectRatio(width5, h5);
  //       selectedDuct.ratio6 = calculateAspectRatio(width6, h6);

  //       const updatedDucts = updateDuctList(ducts);
  //       setDucts(updatedDucts);
  //       setSelectedDuct(selectedDuct);
  //     } catch (error) {
  //       Swal.fire({
  //         icon:"error",
  //         text: "Something went wrong. try again later."
  //       })
  //     }
  //   }
  // };
 
  const handleCalculations = () => {
    let h = 200;
    const { name, airQuantity, lockStatus, frictionLoss, velocity, height } =
      selectedDuct || {};
    let selectedDuct2 = { ...selectedDuct };
    const hasPaid =
      user?.usertype === "paid" ||
      user?.paid === "paid" ||
      user?.paid === "unpaid";

    if (!hasPaid || !name) {
      if (!hasPaid) return;
      toast.error("Select duct/branch.")
      return;
    }

    if (!airQuantity || isNaN(parseFloat(airQuantity))) {
      toast.dismiss(); 
      toast.error("Please enter air quantity between 1 to 10000.");
      return;
    }
    if (airQuantity <= 0 || airQuantity > 10000) {
      toast.dismiss(); 
      toast.error("Please enter air quantity between 1 to 10000");
      return;
    }
    if (selectedDuct?.frictionLoss  && selectedDuct?.velocity) {
      toast.error("Provide either friction loss or velocity.");
      return;
  }
//   if (selectedDuct?.width  ==  0 ||  selectedDuct?.width == "0.00") {
//     toast.error("width cannot be zero.");
//     return;
// }
    if ((frictionLoss === 0 || frictionLoss === "0" || selectedDuct?.frictionLoss == "0.0" || selectedDuct?.frictionLoss === "0.00" || selectedDuct?.frictionLoss === "0") && !lockStatus) {
      toast.error("Friction loss cannot be 0.");

      return;
    }
    if ((velocity === 0 || velocity === "0" || selectedDuct?.velocity == "0.0" || selectedDuct?.velocity === "0.00" || selectedDuct?.velocity === "0") && !lockStatus) {
      toast.error(" Velocity loss cannot be 0.");
      return;
    }
    const flow = airQuantity;
    let dia = 0,
      fr = 0,
      velo = 0;

    if (!lockStatus) {
      if (!frictionLoss && !velocity) {
        toast.error("Provide either friction loss or velocity");
        return;
      }
      if ((frictionLoss <= 0 || frictionLoss == undefined) && !velocity) {
        toast.error("Friction loss must be greater than zero.");
        return;
      }
      if ((velocity <= 0 || velocity == undefined) && !frictionLoss) {
        toast.error("Velocity must be greater than zero.");
        return;
      }

      if (frictionLoss) {
        fr = frictionLoss;
        dia = calculateDiameterFromFriction(flow, fr);
        velo = calculateVelocityFromDiameter(flow, dia, fr);
      } else if (velocity) {
        velo = velocity;
        dia = calculateDiameterFromVelocity(flow, velo);
        fr = calculateFrictionFromVelocity(velo, dia);
      }

      const r = 50;
      const w = calculateDuctWidth(dia, height, r);
      const veloResult =
        frictionLoss || isNaN(frictionLoss)
          ? calculateVelo(fr, calculateDia(fr, flow)).toFixed(2)
          : velo;
// console.log(veloResult,"============>veloResult")
      selectedDuct2 = {
        ...selectedDuct2,
        width: w,
        diaMeter: Math.round(dia * 1000),
        velocity: veloResult,
        frictionLoss: typeof fr === "number" ? fr?.toFixed(2) : fr,
        flowArea: flow > 0 || velo > 0 ? (flow / 1000 / velo).toFixed(2) : null,
      };

      for (let i = 1; i <= 6; i++) {
        const hi = [250, 300, 350, 400, 500, 600][i - 1];
        selectedDuct2[`width${i}`] = calculateDuctWidth(dia, hi, r);
        selectedDuct2[`ratio${i}`] = calculateAspectRatio(
          selectedDuct2[`width${i}`],
          hi
        );
      }
      setIsDisable(true)
    }

    if (lockStatus && !selectedDuct.width && !height && !h) {
      const r = 50;
      dia = (1.3 * Math.pow(r * h, 0.625)) / Math.pow(r + h, 0.25);
      fr =
        (0.9 * Math.pow(0.0344198, 4.86) * Math.pow(flow, 1.82)) /
        Math.pow(dia / 1000, 4.86);

      selectedDuct2 = {
        ...selectedDuct2,
        diaMeter: Math.round(dia),
        frictionLoss: fr.toFixed(2),
        velocity: Math.round(
          10.91 * Math.pow(fr, 0.54) * Math.pow(dia / 1000, 0.67) * 1,
          1
        ),
        flowArea: (flow / 1000 / selectedDuct2.velocity).toFixed(2),
      };
    }
    selectedDuct2.frictioninputStatus = selectedDuct.frictioninputStatus;
    selectedDuct2.frictionStatus = selectedDuct.frictionStatus;


    const updatedDucts = updateDuctList(ducts, selectedDuct2);
    setDucts(updatedDucts);
    setSelectedDuct(selectedDuct2);
  };

  // new

  const handleImperialCalculation = () => {
    const hasPaid =
      user?.usertype === "paid" ||
      user?.paid === "paid" ||
      user?.paid === "unpaid";
    const { name, airQuantity, frictionLoss, velocity, height ,lockStatus} =
      selectedDuct || {};
    let selectedDuct2 = { ...selectedDuct };
    let roughness = 0.9;

    if (!hasPaid) return;

    if (!name) {
      toast.error("Select duct/branch.");
      return;
    }

    if (!airQuantity || isNaN(parseFloat(airQuantity))) {
      toast.error("Please enter air quantity between 1 to 10000.");
      return;
    }


    if (airQuantity <= 0 || airQuantity > 10000) {
      toast.error("Please enter air quantity betweeen 1 to 10000");
      return;
    }

    if (!frictionLoss && !velocity) {
      toast.error("Provide either friction loss or velocity");
      return;
    }

    if (selectedDuct2?.frictionLoss  && selectedDuct2?.velocity) {
      toast.error("Provide either friction loss or velocity.");
      return;
  }
    if ((frictionLoss === 0 || frictionLoss === "0" || selectedDuct2?.frictionLoss == "0.0" || selectedDuct2?.frictionLoss === "0.00" || selectedDuct2?.frictionLoss === "0") && !lockStatus) {
      toast.error("Friction loss cannot be 0.");

      return;
    }
    if ((velocity === 0 || velocity === "0" || selectedDuct2?.velocity == "0.0" || selectedDuct2?.velocity === "0.00" || selectedDuct2?.velocity === "0") && !lockStatus) {
      toast.error(" Velocity loss cannot be 0.");
      return;
    }


    if (velocity) {
      const flow = airQuantity / 2.1188799;
      const FlowArea = calcuateImperialFlowArea();
      const RoundDuctDiameter = CalcualteImperialDuctDiameter(FlowArea);
      const frictionLoss = calculateFrictionLoss(
        airQuantity,
        velocity,
        roughness
      );
      let h = height;
      h *= 25.4;
      const area = flow / 1000 / (velocity / 196.8);
      const dia = Math.pow((area * 4) / Math.PI, 0.5);

      selectedDuct2 = {
        ...selectedDuct2,
        width: getwidthImperial(dia, h),
        diaMeter: RoundDuctDiameter,
        frictionLoss: frictionLoss?.toFixed(2),
        flowArea: FlowArea,
      };

      ["h1", "h2", "h3", "h4", "h5", "h6"].forEach((key, index) => {
        selectedDuct2[key] = (12 + index * 2) * 25.4;
        selectedDuct2[`width${index + 1}`] = getwidthImperial(
          dia,
          selectedDuct2[key]
        );
        const mmW = selectedDuct2[`width${index + 1}`];
        selectedDuct2[`ratio${index + 1}`] = calculateAspectRatio(
          mmW,
          12 + index * 2
        );
      });
    } else {
      const flow = airQuantity / 2.1188799727597;
      let h = height * 25.4;
      let fr = frictionLoss;
      fr *= 249.08890833333 / 30.48;
      const dia =
        0.0344198 * Math.pow(0.9 / fr, 1 / 4.86) * Math.pow(flow, 1.82 / 4.86);
      const velo = 10.91 * Math.pow(fr, 0.54) * Math.pow(dia, 0.67);
      const area = (Math.PI * Math.pow(dia, 2)) / 4;

      selectedDuct2 = {
        ...selectedDuct2,
        width: getwidthImperial(dia, h),
        diaMeter: Math.round(dia / 0.0254),
        velocity: Math.round(velo * 196.8),
        flowArea: (area / (0.0254 * 0.0254)).toFixed(1),
      };

      ["h1", "h2", "h3", "h4", "h5", "h6"].forEach((key, index) => {
        selectedDuct2[key] = (12 + index * 2) * 25.4;
        selectedDuct2[`width${index + 1}`] = getwidthImperial(
          dia,
          selectedDuct2[key]
        );
        const mmW = selectedDuct2[`width${index + 1}`];
        selectedDuct2[`ratio${index + 1}`] = calculateAspectRatio(
          mmW,
          12 + index * 2
        );
      });
    }

    setIsDisable(true)

    selectedDuct2.frictioninputStatus =
      selectedDuct.frictioninputStatus || false;
    selectedDuct2.frictionStatus = selectedDuct.frictioninputStatus;
    selectedDuct2.velocityinputStatus = !selectedDuct.frictioninputStatus;
    selectedDuct2.velocityStatus = !selectedDuct.frictioninputStatus;
    const updatedDucts = updateDuctList(ducts, selectedDuct2);
    setDucts(updatedDucts);
    setSelectedDuct(selectedDuct2);
  };

  // old

  // const handleImperialCalculation = () => {
  //   if (
  //     user?.usertype === "paid" ||
  //     user?.paid === "paid" ||
  //     user?.paid === "unpaid"
  //   ) {
  //     if (!selectedDuct.name) {
  //       Swal.fire("Select Duct/Branch.");
  //       return;
  //     }
  //     if (
  //       !selectedDuct?.airQuantity ||
  //       isNaN(parseFloat(selectedDuct?.airQuantity))
  //     ) {
  //       setErrorMessage("Please Enter Air Quantity.");

  //       return;
  //     }

  //     if (!selectedDuct.frictionLoss && !selectedDuct.velocity) {
  //       Swal.fire("Please provide either friction loss or velocity");
  //       return;
  //     }

  //     if (selectedDuct.frictionLoss && selectedDuct.velocity) {
  //       Swal.fire("Please delete either friction loss or velocity");
  //       return;
  //     }
  //     if (selectedDuct.velocity) {
  //       let FlowArea = calcuateImperialFlowArea();
  //       let RoundDuctDiameter = CalcualteImperialDuctDiameter(FlowArea);
  //       let frictionLoss = calculateFrictionLoss(
  //         selectedDuct.airQuantity,
  //         selectedDuct.velocity,
  //         0.9
  //       );
  //       let h = selectedDuct.height;
  //       h *= 25.4;

  //       let flow = selectedDuct.airQuantity / 2.1188799;
  //       let area = flow / 1000 / (selectedDuct.velocity / 196.8);
  //       let dia = Math.pow((area * 4) / Math.PI, 0.5);

  //       selectedDuct.width = getwidthImperial(dia, h);

  //       selectedDuct.diaMeter = RoundDuctDiameter;
  //       selectedDuct.frictionLoss = frictionLoss?.toFixed(2);
  //       selectedDuct.flowArea = FlowArea;

  //       selectedDuct.h1 = 12;
  //       selectedDuct.h2 = 14;
  //       selectedDuct.h3 = 16;
  //       selectedDuct.h4 = 18;
  //       selectedDuct.h5 = 20;
  //       selectedDuct.h6 = 22;

  //       selectedDuct.width1 = getwidthImperial(dia, selectedDuct.h1 * 25.4);
  //       selectedDuct.width2 = getwidthImperial(dia, selectedDuct.h2 * 25.4);
  //       selectedDuct.width3 = getwidthImperial(dia, selectedDuct.h3 * 25.4);
  //       selectedDuct.width4 = getwidthImperial(dia, selectedDuct.h4 * 25.4);
  //       selectedDuct.width5 = getwidthImperial(dia, selectedDuct.h5 * 25.4);
  //       selectedDuct.width6 = getwidthImperial(dia, selectedDuct.h6 * 25.4);

  //       // new c

  //       selectedDuct.ratio1 = calculateAspectRatioM(
  //         selectedDuct.width1,
  //         selectedDuct.h1
  //       );
  //       selectedDuct.ratio2 = calculateAspectRatioM(
  //         selectedDuct.width2,
  //         selectedDuct.h2
  //       );
  //       selectedDuct.ratio3 = calculateAspectRatioM(
  //         selectedDuct.width3,
  //         selectedDuct.h3
  //       );
  //       selectedDuct.ratio4 = calculateAspectRatioM(
  //         selectedDuct.width4,
  //         selectedDuct.h4
  //       );
  //       selectedDuct.ratio5 = calculateAspectRatioM(
  //         selectedDuct.width5,
  //         selectedDuct.h5
  //       );
  //       selectedDuct.ratio6 = calculateAspectRatioM(
  //         selectedDuct.width6,
  //         selectedDuct.h6
  //       );
  //     } else {
  //       let flow = selectedDuct.airQuantity / 2.1188799727597;
  //       let h = selectedDuct.height * 25.4;
  //       let fr = selectedDuct.frictionLoss;
  //       fr *= 249.08890833333 / 30.48;
  //       var dia =
  //         0.0344198 *
  //         Math.pow(0.9 / fr, 1 / 4.86) *
  //         Math.pow(flow, 1.82 / 4.86);
  //       var velo = 10.91 * Math.pow(fr, 0.54) * Math.pow(dia, 0.67);
  //       var area = (Math.PI * Math.pow(dia, 2)) / 4;

  //       let txtdiameter = Math.round(dia / 0.0254);
  //       let Txtflowarea = (area / (0.0254 * 0.0254)).toFixed(1);
  //       let txtVelocity = Math.round(velo * 196.8);

  //       selectedDuct.width = getwidthImperial(dia, h);
  //       selectedDuct.diaMeter = txtdiameter;
  //       selectedDuct.velocity = txtVelocity;
  //       selectedDuct.flowArea = Txtflowarea;

  //       selectedDuct.h1 = 12;
  //       selectedDuct.h2 = 14;
  //       selectedDuct.h3 = 16;
  //       selectedDuct.h4 = 18;
  //       selectedDuct.h5 = 20;
  //       selectedDuct.h6 = 22;

  //       //new c

  //       selectedDuct.width1 = getwidthImperial(dia, selectedDuct.h1 * 25.4);
  //       selectedDuct.width2 = getwidthImperial(dia, selectedDuct.h2 * 25.4);
  //       selectedDuct.width3 = getwidthImperial(dia, selectedDuct.h3 * 25.4);
  //       selectedDuct.width4 = getwidthImperial(dia, selectedDuct.h4 * 25.4);
  //       selectedDuct.width5 = getwidthImperial(dia, selectedDuct.h5 * 25.4);
  //       selectedDuct.width6 = getwidthImperial(dia, selectedDuct.h6 * 25.4);

  //       selectedDuct.ratio1 = calculateAspectRatioM(
  //         selectedDuct.width1,
  //         selectedDuct.h1
  //       );
  //       selectedDuct.ratio2 = calculateAspectRatioM(
  //         selectedDuct.width2,
  //         selectedDuct.h2
  //       );
  //       selectedDuct.ratio3 = calculateAspectRatioM(
  //         selectedDuct.width3,
  //         selectedDuct.h3
  //       );
  //       selectedDuct.ratio4 = calculateAspectRatioM(
  //         selectedDuct.width4,
  //         selectedDuct.h4
  //       );
  //       selectedDuct.ratio5 = calculateAspectRatioM(
  //         selectedDuct.width5,
  //         selectedDuct.h5
  //       );
  //       selectedDuct.ratio6 = calculateAspectRatioM(
  //         selectedDuct.width6,
  //         selectedDuct.h6
  //       );
  //     }

  //     if (selectedDuct.frictioninputStatus === true) {
  //       selectedDuct.frictioninputStatus = true;
  //       selectedDuct.frictionStatus = true;
  //     } else {
  //       selectedDuct.velocityinputStatus = true;
  //       selectedDuct.velocityStatus = true;
  //     }
  //     const updatedDucts = updateDuctList(ducts,selectedDuct);
  //     setDucts(updatedDucts);
  //     setSelectedDuct(selectedDuct);
  //   }
  // };

  const updateDuctList = (ducts, selectedDuct2) => {
    return ducts.map((duct) => {
      if (duct.id === selectedDuct2.id) {
        return {
          ...duct,
          ...selectedDuct2,
        };
      } else if (duct.children && duct.children.length > 0) {
        return {
          ...duct,
          children: updateDuctList(duct.children, selectedDuct2),
        };
      }
      return duct;
    });
  };

  //Imeperial Code

  const calcuateImperialFlowArea = () => {
    const { airQuantity, velocity } = selectedDuct || {};
    if (!airQuantity || airQuantity == 0 || velocity == 0 || !velocity) {
      return 0;
    }
    return Math.round(
      airQuantity /
      2.1188799727597 /
      1000 /
      (velocity / 196.8) /
      (0.0254 * 0.0254)
    );
  };

  const handleSelected = (selectedNode) => {
    const foundRecords = ducts.find(
      (item) => item.id === selectedNode?.element?.id
    );
    const selectedBranch = findRecord(selectedNode.element.id, ducts);
 
    if (foundRecords) {
      setSelectedDuct({
        ...foundRecords,
        lockStatus: false
      });
    } else {
      setSelectedDuct(selectedBranch);
    }
    setSelected(selectedNode);
  };

 
  const handleFrictionLossChange = (event) => {
    const newValue = event.target.value;
    if (newValue == "") {
      setSelectedDuct(prevState => ({
        ...prevState,
        frictionLoss: ""
      }));
    }

    if (newValue !== "" && (newValue < 0 || newValue > 10000)) {
      return
    }

    setSelectedDuct((prevSelectedDuct) => {
      let updatedDuct = {
        ...prevSelectedDuct,
        frictionLoss: newValue,
        velocityStatus: newValue ? true : false,
        velocityinputStatus: newValue ? true : false,
        frictionStatus: prevSelectedDuct.frictionLoss !== "",
      };

      const mainDuctIndex = ducts.findIndex(
        (duct) => duct.name === prevSelectedDuct.name
      );
      if (mainDuctIndex !== -1) {
        const foundRecords = ducts.find(
          (item) => item.name === prevSelectedDuct.name
        );
        propagateFrictionLoss(foundRecords, newValue);
      }

      return updatedDuct;
    });
  };

  const handleVelocityChange = (event) => {
    const newValue = event.target.value;
    const numericValue = Number(newValue);

    // if (numericValue === 0) {
    //   toast.error("Friction loss can't be 0, please refresh.");
    //   return; // Prevent further execution
    // }
    
    if (newValue == "") {
      setSelectedDuct(prevState => ({
        ...prevState,
        velocity: ""
      }));
    }
    if (newValue !== "" && (newValue <= 0 || newValue > 10000)) {
      return
    }
    setSelectedDuct((prevSelectedDuct) => ({
      ...prevSelectedDuct,
      velocity: newValue,
      frictionStatus: newValue ? true : false,
      frictioninputStatus: newValue ? true : false,
      velocityinputStatus: false,
      velocityStatus: false,
    }));
  };


  // const handleHeightChange = (e) => {
  //   const { name, value } = e.target;

  //   if (value !== "" && (value < 0 || value > 100000)) {
  //     return
  //   }

  //   let updatedWidth;
  //   if (
  //     (Cbunit === Unit.SI && value >= 100 && value <= 1000) ||
  //     (Cbunit === Unit.IMPERIAL && value >= 4 && value <= 40)
  //   ) {
  //     if (selectedDuct.lockStatus === true && name === "height") {
  //       if (Cbunit === Unit.SI) {
  //         handleCalculationforHeight(e.target);
  //       } else if (Cbunit === Unit.IMPERIAL) {
  //         imperialSliderCalculation(e.target);
  //       }
  //     }

  //     if (user?.usertype === "paid" || user?.paid === "paid" || user?.paid === "unpaid") {
  //       if (Cbunit === Unit.SI) {
  //         updatedWidth = calculateDuctWidth(selectedDuct.diaMeter / 1000, value, 50);
  //       } else if (Cbunit === Unit.IMPERIAL) {
  //         updatedWidth = getWidthWithHeightInImperial(value, selectedDuct.airQuantity, selectedDuct.velocity, selectedDuct.width);
  //       }

  //       const updateDuct = (ducts) => {
  //         return ducts.map((duct) => {
  //           if (duct.name === selected?.element?.name) {
  //             return {
  //               ...duct,
  //               [name]: value,
  //               ["width"]: updatedWidth !== undefined ? updatedWidth : selectedDuct.width,
  //               ["velocityStatus"]: true,
  //             };
  //           } else if (duct.children && duct.children.length > 0) {
  //             return {
  //               ...duct,
  //               children: updateDuct(duct.children),
  //             };
  //           }
  //           return duct;
  //         });
  //       };

  //       const updatedDucts = updateDuct(ducts);
  //       setDucts(updatedDucts);
  //       const payload = {
  //         ...selectedDuct,
  //         [name]: value,
  //         ["width"]: updatedWidth !== undefined ? updatedWidth : selectedDuct.width,
  //         ["velocityStatus"]: true,
  //       };
  //       setSelectedDuct(payload);
  //     }
  //   } else {
  //     const payloadOne = {
  //       ...selectedDuct,
  //       [name]: value,
  //     };
  //     setSelectedDuct(payloadOne);
  //   }
  // };


  const handleHeightChange = (e) => {
  
    const { name, value } = e.target;
 
    if (value !== "" && (value < 0 || value > 10000)) {
      return
    }
    if (selectedDuct?.width  ===  0 ||  selectedDuct?.width == "0.00") {
      toast.dismiss();
      toast.error("Width cannot be zero.");
      return;
  }

    let updatedWidth = selectedDuct.width;
    if (selectedDuct.lockStatus === true) {
      // update only width when duct lock and height change and width change.
      if (Cbunit == Unit.SI) {
        handleCalculationforHeight(e.target);
      } else if (Cbunit == Unit.IMPERIAL) {
        imperialSliderCalculation(e.target);
      }
    } else {
      // update only width when duct not lock and height change.
      if (Cbunit === Unit.SI) {
        updatedWidth = calculateDuctWidth(selectedDuct.diaMeter / 1000, value, 50);
      } else if (Cbunit === Unit.IMPERIAL) {
        updatedWidth = getWidthWithHeightInImperial(value, selectedDuct.airQuantity, selectedDuct.velocity, selectedDuct.width);
      }
      const payload = {
        ...selectedDuct,
        [name]: value,
        ["width"]: updatedWidth !== undefined ? updatedWidth : selectedDuct.width,
        ["velocityStatus"]: true,
      };
      setSelectedDuct(payload);
    }
    const updateDuct = (ducts) => {
      return ducts.map((duct) => {
        if (duct.name === selected?.element?.name) {
          return {
            ...duct,
            [name]: value,
            ["width"]: updatedWidth !== undefined ? updatedWidth : selectedDuct.width,
            ["velocityStatus"]: true,
          };
        } else if (duct.children && duct.children.length > 0) {
          return {
            ...duct,
            children: updateDuct(duct.children),
          };
        }
        return duct;
      });
    };

    const updatedDucts = updateDuct(ducts);
    setDucts(updatedDucts);
  };


  const addMainDuct = () => {
    const existingNumbers = ducts.map((duct) => {
      const match = duct?.parentDuctName?.match(/Main-Duct-(\d+)/);
      return match ? parseInt(match[1]) : 0;
    });

    const maxNumber = Math.max(...existingNumbers);

    // Calculate the next main duct number
    const newKey = maxNumber === -Infinity ? 0 + 1 : maxNumber + 1;
    // Create a new node object with the key and label
    const newNode = {
      id: uuidv4(),
      name: `Main-Duct-${newKey}`,
      airQuantity: "",
      frictionLoss: "",
      velocity: "",
      frictionStatus: false,
      frictioninputStatus: false,
      velocityStatus: false,
      velocityinputStatus: false,
      diaMeter: "",
      width: "",
      height: Cbunit === Unit.SI ? 200 : 10,
      flowArea: "",
      widthStatus: true,
      width1: "",
      width2: "",
      width3: "",
      width4: "",
      width5: "",
      width6: "",
      ratio1: "",
      ratio2: "",
      ratio3: "",
      ratio4: "",
      ratio5: "",
      ratio6: "",
      lockStatus: false,
      remark: "",
      parentDuctName: `Main-Duct-${newKey}`,
      childDuctName: `Main-Duct-${newKey}`,
      children: [],
    };
    setDucts([...ducts, newNode]);
    if (ducts.length === 0) {
      // If ducts.length is 0, set the new node as selected
      setSelectedDuct(newNode);
    }
  };


  // for by defaultly seleted in tree view
  const [remove, setRemove] = useState(false);

  useEffect(() => {
    if (!remove) {
         const existingNumbers = ducts.map((duct) => {
      const match = duct?.parentDuctName?.match(/Main-Duct-(\d+)/);
      return match ? parseInt(match[1]) : 0;
    });

    const maxNumber = Math.max(...existingNumbers);

    // Calculate the next main duct number
    const newKey = maxNumber === -Infinity ? 0 + 1 : maxNumber + 1;
    // Create a new node object with the key and label
    const newNode = {
      id: uuidv4(),
      name: `Main-Duct-${newKey}`,
      airQuantity: "",
      frictionLoss: "",
      velocity: "",
      frictionStatus: false,
      frictioninputStatus: false,
      velocityStatus: false,
      velocityinputStatus: false,
      diaMeter: "",
      width: "",
      height: Cbunit === Unit.SI ? 200 : 10,
      flowArea: "",
      widthStatus: true,
      width1: "",
      width2: "",
      width3: "",
      width4: "",
      width5: "",
      width6: "",
      ratio1: "",
      ratio2: "",
      ratio3: "",
      ratio4: "",
      ratio5: "",
      ratio6: "",
      lockStatus: false,
      remark: "",
      parentDuctName: `Main-Duct-${newKey}`,
      childDuctName: `Main-Duct-${newKey}`,
      children: [],
    };
    if (ducts.length === 0) {
      setDucts([...ducts, newNode]);
    }

    if (ducts.length === 0) {
      // If ducts.length is 0, set the new node as selected
      setSelectedDuct(newNode);
    }
  }
  }, [Cbunit, ducts])



  const addMultipleMainDucts = async () => {
    const result = await Swal.fire({
      title: "Enter the number of ducts",
      input: "number",
      placeholder: "Enter the number ",
      title: "Enter the number of ducts",
      input: "number",
      inputAttributes: {
        step: 1,
        min: 1,
        max: 100,
      },
      showCancelButton: true,
      confirmButtonText: "Add",
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value || !/^\d+$/.test(value)) {
          return "Please enter a valid numeric value.";
        }
        if (parseInt(value) > 100) {
          return `You can add up to 100 Ducts at a time.`;
        }
        return null;
      },
    });

    if (result.isConfirmed) {
      const input = result.value;

      if (input === "" || isNaN(input)) {
        toast.error("Enter a valid numeric value.");
        return;
      }

      const count = parseInt(input);

      if (count > 0) {
        if (count <= 100) {
          let newDucts = [];
          const existingNumbers = ducts.map((duct) => {
            const match = duct?.parentDuctName?.match(/Main-Duct-(\d+)/);
            return match ? parseInt(match[1]) : 0;
          });
          const maxNumber = Math.max(...existingNumbers);
          const newKey = maxNumber !== -Infinity && maxNumber;

          let ductCount = newKey;
          for (let i = 0; i < count; i++) {
            ductCount++;
            newDucts.push({
              id: uuidv4(),
              name: `Main-Duct-${ductCount}`,
              airQuantity: "",
              frictionLoss: "",
              velocity: "",
              frictionStatus: false,
              frictioninputStatus: false,
              velocityStatus: false,
              velocityinputStatus: false,
              diaMeter: "",
              width: "",
              height: Cbunit === Unit.SI ? 200 : 10,
              flowArea: "",
              widthStatus: true,
              width1: "",
              width2: "",
              width3: "",
              width4: "",
              width5: "",
              width6: "",
              ratio1: "",
              ratio2: "",
              ratio3: "",
              ratio4: "",
              ratio5: "",
              ratio6: "",
              lockStatus: false,
              remark: "",
              parentDuctName: `Main-Duct-${ductCount}`,
              childDuctName: `Main-Duct-${ductCount}`,
              children: [],
            });
          }
          setDucts([...ducts, ...newDucts]);
        } else {
          toast.error("You can add a maximum of 100 ducts at a time.");
        }
      }
    }
  };

  const addBranchToDuct = () => {
    if (!selected) {
      toast.error("Select duct/branch.");
      return;
    }

    const parentNode = findNode(ducts, selectedDuct?.id);

    let highestIndex = findHighestBranchIndex({ children: ducts }, 0);

    // Generate a new name for the branch with an incremented index
    const newIndex = highestIndex + 1;
    const newNode = {
      id: uuidv4(),
      name: `Branch-${newIndex}`,
      airQuantity: "",
      frictionLoss: selectedDuct?.frictionLoss || "",
      velocity: "",
      frictionStatus: false,
      frictioninputStatus: false,
      velocityStatus: selectedDuct?.velocityStatus,
      diaMeter: "",
      width: "",
      height: Cbunit === Unit.SI ? 200 : 10,
      flowArea: "",
      widthStatus: true,
      width1: "",
      width2: "",
      width3: "",
      width4: "",
      width5: "",
      width6: "",
      ratio1: "",
      ratio2: "",
      ratio3: "",
      ratio4: "",
      ratio5: "",
      ratio6: "",
      lockStatus: false,
      remark: "",
      parentDuctName: parentNode?.parentDuctName,
      childDuctName: `Branch-${newIndex}`,
      children: [],
    };
    // If the parent node exists, push the new node to its children array
    if (parentNode) {
      if (!parentNode.children) {
        parentNode.children = [];
      }
      parentNode.children.push(newNode);
    }

    // Update the tree data state with the new array
    setDucts([...ducts]);
  };

  const addMultipleBranchesToSelectedDuct = async () => {
    if (!selected) {
      toast.error("Select duct/branch.");
      return;
    }

    const { value: countInput } = await Swal.fire({
      title: "Enter the number of branches",
      input: "number",
      inputAttributes: {
        step: 1,
        min: 1,
        max: 100,
      },
      showCancelButton: true,
      confirmButtonText: "Add",
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value || !/^\d+$/.test(value)) {
          return "Please enter a valid numeric value.";
        }
        if (parseInt(value) > 100) {
          return `You can add up to 100 branches at a time.`;
        }
        return null;
      },
    });

    if (countInput) {
      const count = parseInt(countInput);

      const parentNode = findNode(ducts, selected?.element?.id);
      let highestIndex = findHighestBranchIndex({ children: ducts }, 0);
      // Generate a new name for the branch with an incremented index
      const newIndex = highestIndex + 1;
      for (let i = 0; i < count; i++) {
        const newNode = {
          id: uuidv4(),
          name: `Branch-${newIndex + i}`,
          airQuantity: "",
          frictionLoss: selectedDuct?.frictionLoss || "",
          velocity: "",
          frictionStatus: false,
          frictioninputStatus: false,
          velocityStatus: false,
          velocityinputStatus: false,
          diaMeter: "",
          width: "",
          height: Cbunit === Unit.SI ? 200 : 10,
          flowArea: "",
          widthStatus: true,
          width1: "",
          width2: "",
          width3: "",
          width4: "",
          width5: "",
          width6: "",
          ratio1: "",
          ratio2: "",
          ratio3: "",
          ratio4: "",
          ratio5: "",
          ratio6: "",
          lockStatus: false,
          remark: "",
          parentDuctName: parentNode?.parentDuctName,
          childDuctName: `Branch-${newIndex + i}`,
          children: [],
        };
        if (parentNode) {
          if (!parentNode.children) {
            parentNode.children = [];
          }
          parentNode.children.push(newNode);
        }
      }
      setDucts([...ducts]);
    }
  };

  const removeDuct = () => {
    if (!selected) {
      toast.error("Select duct/branch.");
      return;
    }

    const node = findNode(ducts, selected?.element?.id);

    if (!node) {
      toast.error("Selected duct/branch not found.");
      return;
    }

    const parentNode = findParent(ducts, selected?.element?.id);

    if (parentNode) {
      if (parentNode.children) {
        const index = parentNode.children.findIndex(
          (child) => child.id === selected?.element?.id
        );
        if (index !== -1) {
          parentNode.children.splice(index, 1);
          setDucts([...ducts]);

          // Update the selected state to the previous or next branch if available
          if (parentNode.children.length > 0) {
            const newIndex = Math.min(index, parentNode.children.length - 1);
            setSelected({ element: parentNode.children[newIndex] });
            const removeRecord = parentNode.children[newIndex];
            // const newNode = {
            //   id: removeRecord.id,
            //   name: removeRecord?.name,
            //   airQuantity: removeRecord?.airQuantity,
            //   frictionLoss: removeRecord?.frictionLoss,
            //   velocity: removeRecord?.velocity,
            //   frictionStatus: removeRecord?.frictionStatus,
            //   velocityStatus: removeRecord?.velocityStatus,
            //   diaMeter: removeRecord?.diaMeter,
            //   width: removeRecord?.width,
            //   height: removeRecord?.height,
            //   flowArea: removeRecord?.flowArea,
            //   widthStatus: removeRecord?.widthStatus,
            //   width1: removeRecord?.width1,
            //   width2: removeRecord?.width2,
            //   width3: removeRecord?.width3,
            //   width4: removeRecord?.width4,
            //   width5: removeRecord?.width5,
            //   width6: removeRecord?.width6,
            //   lockStatus: removeRecord?.lockStatus,
            //   remark: removeRecord?.remark,
            //   parentDuctName: removeRecord?.parentDuctName,
            //   childDuctName: removeRecord?.childDuctName,
            //   children: [],
            // };
            setSelectedDuct(removeRecord);
          } else {
            setSelected(parentNode.name); // Set the parent node as selected after removing the last branch
            const newIndex = Math.min(index, parentNode.children.length - 1);
            setSelected({ element: parentNode.children[newIndex] });
            // const newNode = {
            //   id: parentNode.id,
            //   name: parentNode?.name,
            //   airQuantity: parentNode?.airQuantity,
            //   frictionLoss: parentNode?.frictionLoss,
            //   velocity: parentNode?.velocity,
            //   frictionStatus: parentNode?.frictionStatus,
            //   velocityStatus: parentNode?.velocityStatus,
            //   diaMeter: parentNode?.diaMeter,
            //   width: parentNode?.width,
            //   height: parentNode?.height,
            //   flowArea: parentNode?.flowArea,
            //   widthStatus: parentNode?.widthStatus,
            //   width1: parentNode?.width1,
            //   width2: parentNode?.width2,
            //   width3: parentNode?.width3,
            //   width4: parentNode?.width4,
            //   width5: parentNode?.width5,
            //   width6: parentNode?.width6,
            //   lockStatus: parentNode?.lockStatus,
            //   remark: parentNode?.remark,
            //   parentDuctName: parentNode?.parentDuctName,
            //   childDuctName: parentNode?.childDuctName,
            //   children: [],
            // };
            setSelectedDuct(parentNode);
          }
        }
      }
    } else {
      // If the parent node is not found, it means we are trying to remove a main duct.
      const index = ducts.findIndex(
        (mainDuct) => mainDuct.name === selected?.element?.name
      );
      if (index !== -1) {
        ducts.splice(index, 1);
        setDucts([...ducts]);

        // Update the selected state to the previous or next main duct if available
        if (ducts.length > 0) {
          const newIndex = Math.min(index, ducts.length - 1);
          setSelected({ element: ducts[newIndex] });
          // if(ducts[newIndex] === 0)
          // {
          const removeRecord = ducts[newIndex];
          const newNode = {
            id: removeRecord.id,
            name: removeRecord.name,
            airQuantity: removeRecord.airQuantity,
            frictionLoss: removeRecord.frictionLoss,
            velocity: removeRecord.velocity,
            frictionStatus: removeRecord.frictionStatus,
            velocityStatus: removeRecord.velocityStatus,
            diaMeter: removeRecord.diaMeter,
            width: removeRecord.width,
            height: removeRecord.height,
            flowArea: removeRecord.flowArea,
            widthStatus: removeRecord.widthStatus,
            width1: removeRecord.width1,
            width2: removeRecord.width2,
            width3: removeRecord.width3,
            width4: removeRecord.width4,
            width5: removeRecord.width5,
            width6: removeRecord.width6,
            lockStatus: removeRecord.lockStatus,
            remark: removeRecord.remark,
            parentDuctName: removeRecord.parentDuctName,
            childDuctName: removeRecord.childDuctName,
            children: [],
          };
          setSelectedDuct(newNode);
          // }
        } else {
          setSelected(null); // No main ducts left, so clear the selected state
          const newNode = {
            id: uuidv4(),
            name: "",
            airQuantity: "",
            frictionLoss: "",
            velocity: "",
            frictionStatus: false,
            frictioninputStatus: false,
            velocityStatus: false,
            velocityinputStatus: false,
            diaMeter: "",
            width: "",
            height: Cbunit === "SI" ? 200 : 10,
            flowArea: "",
            widthStatus: true,
            width1: "",
            width2: "",
            width3: "",
            width4: "",
            width5: "",
            width6: "",
            ratio1: "",
            ratio2: "",
            ratio3: "",
            ratio4: "",
            ratio5: "",
            ratio6: "",
            lockStatus: false,
            remark: "",
            parentDuctName: "",
            childDuctName: "",
            children: [],
          };
          setSelectedDuct(newNode);
        }
      }
    }
  };

  // ... Rest of the code
  const clearAllDucts = async () => {
    setRemove(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You want to remove all the ducts!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed!",
      cancelButtonText: "Cancel",
    }).then(async (res) => {
      if (res.isConfirmed) {
        // API request successful, then clear state and navigate

        setLoading(true);
        setDucts([]);
        setSelectedDuct({}); // clear all data
        setSelectedDuct({
          frictionLoss: "",
          velocity: "",
          width: "",
          height: "",
          lockStatus: false,
          airQuantity:"",
          diaMeter:"",
          flowArea:"",
      });
        const apiUrl = "/calculation/create-update";

        const id = localStorage.getItem("projectID") ?? null;

        const payload = {
          id: id,
          productName: project,
          unit: Cbunit,
          ductData: [],
          userId: user.id,
        };
        if (id) {
          try {
            await api.post(apiUrl, payload);
          } catch (error) {
            setLoading(false);
          }
          setLoading(false);
        }
      }
      setLoading(false);
      // window.location.reload();
    });
  };

  const handleChangeValues = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setSelectedDuct(prevState => ({
        ...prevState,
        airQuantity: ""
      }));
    }
    if (!isNaN(value) && value >= 0 && value <= 10000) {
      const updateDuct = (ducts) => {
        return ducts.map((duct) => {
          if (duct.name === selected?.element?.name) {
            return {
              ...duct,
              [name]: parseFloat(value),
            };
          } else if (duct.children && duct.children.length > 0) {
            return {
              ...duct,
              children: updateDuct(duct.children),
            };
          }
          return duct;
        });
      };

      const updatedDucts = updateDuct(ducts);
      setDucts(updatedDucts);

      const payload = {
        ...selectedDuct,
        [name]: parseFloat(value),
      };
      setSelectedDuct(payload);
    }
  };

  const handleChangeValuesForRemarks = (e) => {
    const { name, value } = e.target;
    const updateDuct = (ducts) => {
      return ducts.map((duct) => {
        if (duct.name === selected?.element?.name) {
          return {
            ...duct,
            [name]: value,
          };
        } else if (duct.children && duct.children.length > 0) {
          return {
            ...duct,
            children: updateDuct(duct.children),
          };
        }
        return duct;
      });
    };

    const updatedDucts = updateDuct(ducts);
    setDucts(updatedDucts);

    const payload = {
      ...selectedDuct,
      [name]: value,
    };
    setSelectedDuct(payload);

  };


  const handleCalculationforHeight = (e) => {
    try {
      const { airQuantity, height, width } = selectedDuct;

      if (!airQuantity || isNaN(parseFloat(airQuantity))) {
        toast.error("Please enter air quantity between 1 to 10000.");
        return;
      }

      if (airQuantity <= 0 || airQuantity > 10000) {
        toast.error("Please enter air quantity between 1 to 10000.");
        return
      }

      const inputValue = parseInt(e.value);
      const newHeight = e.name === "height" ? inputValue : height;
      const newWidth = e.name === "width" ? inputValue : width;

      const diameter = calculateDiameter(newWidth, newHeight);
      const velocity = calculateVelocity(airQuantity, diameter);
      const frictionLoss = calculateFrictionLossByAirAndDia(airQuantity, diameter);
      const flowArea = calculateFlowArea(airQuantity, velocity);

      setSelectedDuct({
        ...selectedDuct,
        width: newWidth,
        height: newHeight,
        diaMeter: diameter.toFixed(2),
        frictionLoss: frictionLoss.toFixed(2),
        velocity:velocity.toFixed(2),
        flowArea: flowArea
      })

   
    } catch (error) {
      console.error("Error in calculations:", error);
    }
  };

  function imperialSliderCalculation(e) {
    let { height, width, airQuantity } = selectedDuct;
    const inputHeight = e.name === "height" ? e.value : height;
    const inputWidth = e.name === "width" ? e.value : width;
    let w, h, dia, fr, velo, area, flow;
    w = inputWidth;
    h = inputHeight;
    flow = airQuantity / 2.1188799727597;
    w *= 25.4
    h *= 25.4
    dia = 1.3 * Math.pow(w * h, 0.625) / Math.pow(w + h, 0.25);
    fr = 0.9 * Math.pow(0.0344198, 4.86) * Math.pow(flow, 1.82) / Math.pow(dia / 1000, 4.86)
    velo = 10.91 * Math.pow(fr, 0.54) * Math.pow(dia / 1000, 0.67);
    area = (Math.PI * Math.pow((dia / 1000), 2) / 4);
    setSelectedDuct({
      ...selectedDuct,
      diaMeter: Math.round(dia / 25.4, 0),
      frictionLoss: (fr * 0.0040146307866177 * 30.48).toFixed(2),
      velocity: Math.round(velo * 196.8, 0),
      flowArea: (area / (0.0254 * 0.0254)).toFixed(2),
      height: inputHeight,
      width: inputWidth
    })


  }


   const handleUnitSystem = (e) => {
    setCbunit(e.target.value);

    const payload = {
      ...selectedDuct,
      airQuantity: "",
      height: e.target.value == Unit.SI ? 200 : 10,
      width: ""
    };
    setSelectedDuct(payload);
  };

  // const handleWidthChange = (e, newValue) => {
  //   const { name, value } = e.target;
  //   if (value !== "" && (value < 0 || value > 100000)) {
  //     return
  //   }
  //   if (Cbunit === Unit.SI && value >= 100 && value <= 1000) {
  //     if (name === "width" && !selectedDuct.lockStatus) {
  //       handleCalculationforHeight(e.target);
  //     }
  //     if (selectedDuct.lockStatus === true) {
  //       if (name === "height") {
  //         handleCalculationforHeight(e.target);
  //       }
  //     }
  //   } else if (Cbunit === Unit.IMPERIAL && value >= 4 && value <= 40) {
  //     if (name === "width" && !selectedDuct.lockStatus) {
  //       imperialSliderCalculation(e.target);
  //     }

  //     if (selectedDuct.lockStatus === true) {
  //       if (name === "height") {
  //         imperialSliderCalculation(e.target);
  //       }
  //     }
  //   } else {
  //     const payload = {
  //       ...selectedDuct,
  //       [name]: value,
  //     };
  //     setSelectedDuct(payload);
  //   }

  //   const updateDuct = (ducts) => {
  //     return ducts.map((duct) => {
  //       if (duct.name === selected?.element?.name) {
  //         return {
  //           ...duct,
  //           [name]: value,
  //           ["velocityStatus"]: true,
  //         };
  //       } else if (duct.children && duct.children.length > 0) {
  //         return {
  //           ...duct,
  //           children: updateDuct(duct.children),
  //         };
  //       }
  //       return duct;
  //     });
  //   };

  //   const updatedDucts = updateDuct(ducts);
  //   setDucts(updatedDucts);
  //   const payload = {
  //     ...selectedDuct,
  //     [name]: value,
  //     ["velocityStatus"]: true,
  //   };
  //   setSelectedDuct(payload);
  // };



  const handleWidthChange = (e) => {
    const { name, value } = e.target;
    if (value !== "" && (value < 0 || value > 10000)) {
      return
    }
  //   if (value === 0) {
  //     toast.error("Width cannot be 0.");
  //     return;
  // }
  // const { width } = selectedDuct
    if (selectedDuct?.height  ==  0 ||  selectedDuct?.height == " ") {
      toast.dismiss()
    // toast.error("Height cannot be zero.");
    return;
}

    if (selectedDuct.lockStatus === true) {
      if (Cbunit === Unit.SI) {
        handleCalculationforHeight(e.target);
      }
      else if (Cbunit === Unit.IMPERIAL) {
        imperialSliderCalculation(e.target);
      }
    }



    const updateDuct = (ducts) => {
      return ducts.map((duct) => {
        if (duct.name === selected?.element?.name) {
          return {
            ...duct,
            [name]: value,
            ["velocityStatus"]: true,
          };
        } else if (duct.children && duct.children.length > 0) {
          return {
            ...duct,
            children: updateDuct(duct.children),
          };
        }
        return duct;
      });
    };

    const updatedDucts = updateDuct(ducts);
    setDucts(updatedDucts);

  };

  function propagateFrictionLoss(branch, value) {
    branch.frictionLoss = value;
    branch.velocityStatus = true;
    if (branch.children) {
      for (const child of branch.children) {
        propagateFrictionLoss(child, value);
      }
    }
  }



  const handleFrictionLossSliderChange = (e) => {
    const { name, value } = e.target;

    if (value === 0) {
      toast.error("Friction loss can't be 0, please refresh.");
      return; // Prevent further execution
    }

    const mainDuct1Index = ducts.findIndex(
      (duct) => duct.name === selectedDuct?.name
    );
    if (mainDuct1Index !== -1) {
      const foundRecords = ducts.find(
        (item) => item.name === selectedDuct?.name
      );
      propagateFrictionLoss(foundRecords, value);
      setDucts(ducts);
    } else {
      const updateDuct = (ducts) => {
        return ducts.map((duct) => {
          if (duct.name === selected?.element?.name) {
            return {
              ...duct,
              ["velocityStatus"]: true,
              ["velocityinputStatus"]: true,
            };
          } else if (duct.children && duct.children.length > 0) {
            return {
              ...duct,
              children: updateDuct(duct.children),
            };
          }
          return duct;
        });
      };
      const updatedDucts = updateDuct(ducts);
      setDucts(updatedDucts);
    }
    const payload = {
      ...selectedDuct,
      [name]: value,
      ["velocityStatus"]: true,
      ["velocityinputStatus"]: true,
    };
    setSelectedDuct(payload);

  };



  function getWidthWithHeightInImperial(h, air, velo, width) {
    if(air == "" || velo == "" || width == ""  ||  h == ""){
      return ;
    }
    let flow = air / 2.1188799;
    let area = flow / 1000 / (velo / 196.8);
    let dia = Math.pow((area * 4) / Math.PI, 0.5);
    h = h * 25.4;
    width = getwidthImperial(dia, h);
    return width;
  }



  const marks = [
    {
      value: 0.1,
      label: "0.1",
    },
    {
      value: 0.3,
      label: "0.3",
    },
    {
      value: 0.5,
      label: "0.5",
    },
    {
      value: 0.7,
      label: "0.7",
    },

    {
      value: 0.9,
      label: "0.9",
    },
    {
      value: 1,
      label: "1",
    },
  ];

  const marksVelo = [
    {
      value: 0,
      label: "",
    },
    {
      value: 1,
      label: "",
    },
    {
      value: 2,
      label: "",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: "10",
    },
  ];

  const marksHeight = [
    {
      value: 100,
      label: "100",
    },
    {
      value: 197,
      label: "200",
    },
    {
      value: 301,
      label: "300",
    },
    {
      value: 398,
      label: "400",
    },
    {
      value: 501,
      label: "500",
    },
    {
      value: 599,
      label: "600",
    },
    {
      value: 702,
      label: "700",
    },
    {
      value: 799,
      label: "800",
    },
    {
      value: 903,
      label: "900",
    },
    {
      value: 1000,
      label: "1000",
    },
  ];

  const marksImp = [
    {
      value: 0.006,
      label: "0.01",
    },
    {
      value: 0.037,
      label: "0.03",
    },
    {
      value: 0.061,
      label: "0.06",
    },
    {
      value: 0.086,
      label: "0.08",
    },
    {
      value: 0.11,
      label: "",
    },
    {
      value: 0.122,
      label: "0.12",
    },
  ];

  const marksVeloImp = [
    {
      value: 98.4,
      label: "",
    },

    {
      value: 590.4,
      label: "590",
    },
    {
      value: 985,
      label: "985",
    },
    {
      value: 1390,
      label: "1390",
    },
    {
      value: 1968,
      label: "1970",
    },
  ];

  const marksHeightImp = [
    {
      value: 4,
      label: "4",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 12,
      label: "12",
    },
    {
      value: 16,
      label: "16",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 24,
      label: "24",
    },
    {
      value: 28,
      label: "28",
    },
    {
      value: 32,
      label: "32",
    },
    {
      value: 36,
      label: "36",
    },
    {
      value: 40,
      label: "40",
    },
  ];

  const saveDuct = () => {
    const apiUrl = "/calculation/create-update";
    const id = localStorage.getItem("projectID") ?? 0;
    if (!project) {
      toast.error("Project name must be required.");
      return
    }
    const payload = {
      id: id,
      productName: project,
      unit: Cbunit,
      ductData: ducts,
      userId: user.id,
    };
    const hasEmptyDuctName = payload.ductData.some(duct => duct.name === "");
    if (!hasEmptyDuctName) {
 
    setLoading(true); // Set loading state to true before making the API call

    api
      .post(apiUrl, payload)
      .then((response) => {
        setLoading(false); // Set loading state to false after the API call
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          const notify = () =>
            toast.success("Caculation saved.", {
              autoClose: 1000,
            });
          notify();
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.status === 400) {
          // Show SweetAlert for email and mobile already existing
          toast.error(error.response.data.message);
  
        } else {
          // Handle other error responses
          toast.error(error.message == "Network Error" ? "Please check your internet connection.": error.message);
  
        }
        // toast.error("Please check your internet connection.");
      });
    }else if(hasEmptyDuctName){
      toast.error("Enter duct name.....");
    }    
  };


  const handleProjectChange = (e) => {
    const value = e.target.value;
    if (value.length <= 255) {
      setProject(value);
    } else {
      setProject(value.slice(0, 255));
    }
  };

  const handleLockDuctSize = () => {
    const updateDuct = (ducts) => {
      return ducts.map((duct) => {
        if (duct.name === selectedDuct?.name) {
          return {
            ...duct,
            ratio1: "",
            ratio2: "",
            ratio3: "",
            ratio4: "",
            ratio5: "",
            ratio6: "",
            width1: "",
            width2: "",
            width3: "",
            width4: "",
            width5: "",
            width6: "",
            ["widthStatus"]: !selectedDuct.lockStatus ? false : true,
            ["frictionStatus"]: !selectedDuct.lockStatus ? true : false,
            ["velocityStatus"]: !selectedDuct.lockStatus ? true : false,
            ["frictioninputStatus"]: !selectedDuct.lockStatus ? true : false,
            ["velocityinputStatus"]: !selectedDuct.lockStatus ? true : false,
            ["lockStatus"]: !selectedDuct.lockStatus ? true : false,
          };
        } else if (duct.children && duct.children.length > 0) {
          return {
            ...duct,
            children: updateDuct(duct.children),
          };
        }
        return duct;
      });
    };

    const updatedDucts = updateDuct(ducts);
    setDucts(updatedDucts);
    const payload = {
      ...selectedDuct,
      ratio1: "",
      ratio2: "",
      ratio3: "",
      ratio4: "",
      ratio5: "",
      ratio6: "",
      width1: "",
      width2: "",
      width3: "",
      width4: "",
      width5: "",
      width6: "",
      ["widthStatus"]: !selectedDuct.lockStatus ? false : true,
      ["frictionStatus"]: !selectedDuct.lockStatus ? true : false,
      ["velocityStatus"]: !selectedDuct.lockStatus ? true : false,
      ["frictioninputStatus"]: !selectedDuct.lockStatus ? true : false,
      ["velocityinputStatus"]: !selectedDuct.lockStatus ? true : false,
      ["lockStatus"]: !selectedDuct.lockStatus ? true : false,
    };
    setSelectedDuct(payload);

  };
  const handleDuctTreeName = (e) => {
    const { name, value } = e.target;
    
    const ductName = value.length <= 50 ? value : value.slice(0, 50);

    const updateDuct = (ducts) => {
      return ducts.map((duct) => {
        if (duct.id === selectedDuct?.id) {
          return {
            ...duct,
            [name]: ductName,
          };
        } else if (duct.children && duct.children.length > 0) {
          return {
            ...duct,
            children: updateDuct(duct.children),
          };
        }
        return duct;
      });
    };
    const updatedDucts = updateDuct(ducts);
    setDucts(updatedDucts);
    const payload = {
      ...selectedDuct,
      [name]: ductName,
    };
    setSelectedDuct(payload);
  };


  const exportExcel = () => {
    if (ducts.length === 0) {
      return;
    }
    const data = flattenArray(ducts);

    const newData = data?.map((item) => {
      return {
        Name: item?.name,
        Diameter: item?.diaMeter,
        Width: item?.width,
        Height: item?.height,
        FlowArea: item?.flowArea,
        AirQuantity: item?.airQuantity,
        FrictionLoss: item?.frictionLoss,
        Velocity: item?.velocity,
      };
    });
    const wb = XLSX.utils.book_new();
    // const ws = XLSX.utils.json_to_sheet(newData);

    // Apply styles
    const ws = XLSX.utils.aoa_to_sheet([
      Object.keys(newData[0]), // Header row
      ...newData.map((item) => Object.values(item)),
    ]);
    ws["!cols"] = [{ width: 15 }, { width: 10 }]; // Adjust column widths

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, `${project ?? "untitle"}.xlsx`);
  };

  const handleRefresh = (e) => {
    setIsDisable(false)
    e.preventDefault();

    if (
      (user?.usertype === "paid" ||
        user?.paid === "paid" ||
        user?.paid === "unpaid") &&
      selectedDuct?.lockStatus
    ) {
      selectedDuct.lockStatus = false;
    }
    const updateDuct = (ducts) => {
      return ducts.map((duct) => {
        if (duct.name === selectedDuct?.name) {
          return {
            ...duct,
            ["airQuantity"]: "",
            ["diaMeter"]: "",
            ["flowArea"]: "",
            ["frictionLoss"]: "",
            ["velocity"]: "",
            ["frictionStatus"]: false,
            ["velocityStatus"]: false,
            ["frictioninputStatus"]: false,
            ["velocityinputStatus"]: false,
            ["width1"]: "",
            ["width2"]: "",
            ["width3"]: "",
            ["width4"]: "",
            ["width5"]: "",
            ["width6"]: "",
            ["height"]: Cbunit == "SI" ? 200 : 10,
            ["width"]: "",
            ["ratio1"]: "",
            ["ratio2"]: "",
            ["ratio3"]: "",
            ["ratio4"]: "",
            ["ratio5"]: "",
            ["ratio6"]: "",
          };
        } else if (duct.children && duct.children.length > 0) {
          return {
            ...duct,
            children: updateDuct(duct.children),
          };
        }
        return duct;
      });
    };

    const updatedDucts = updateDuct(ducts);
    setDucts(updatedDucts);
    const payload = {
      ...selectedDuct,
      ["airQuantity"]: "",
      ["diaMeter"]: "",
      ["flowArea"]: "",
      ["frictionLoss"]: "",
      ["velocity"]: "",
      ["frictionStatus"]: false,
      ["velocityStatus"]: false,
      ["frictioninputStatus"]: false,
      ["velocityinputStatus"]: false,
      ["width1"]: "",
      ["width2"]: "",
      ["width3"]: "",
      ["width4"]: "",
      ["width5"]: "",
      ["width6"]: "",
      ["height"]: Cbunit == "SI" ? 200 : 10,
      ["width"]: "",
      ["ratio1"]: "",
      ["ratio2"]: "",
      ["ratio3"]: "",
      ["ratio4"]: "",
      ["ratio5"]: "",
      ["ratio6"]: "",
    };
    setSelectedDuct(payload);

  };



  const reportTemplateRef = useRef(null);

  const handleGeneratePdf = () => {
    const doc = new jsPDF({ orientation: "landscape", compress: true });

    doc.autoTable({
      html: "#my-table-007",
    });

    //doc.save(`${project}.pdf`);

    doc.autoPrint();
    const fileName = `${project}.pdf`;
    //window.open(doc.output('bloburl', { filename: fileName }), '_blank');

    const blob = doc.output("blob");
    const blobUrl = URL.createObjectURL(blob);

    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
    <html>
      <head>
        <title>Project Name: ${project}</title>
      </head>
      <body>
        <embed width="100%" height="100%" src="${blobUrl}" type="application/pdf">
      </body>
    </html>
  `);
    printWindow.document.close();
  };


  const handleKeyDown = (event) => {
    if (event.key === 'e') {
        event.preventDefault();
    }
    if (
      !(
        (event.key >= '0' && event.key <= '9') ||
        event.key === 'Backspace' ||
        event.key === 'Delete' ||
        event.key === 'ArrowLeft' ||
        event.key === 'ArrowRight' ||
        event.key === 'Home' ||
        event.key === 'End' ||
        event.key === '.'
      )
    ) {
      event.preventDefault();
    }
  }
    
  
  // const handleKeyDown = (event) => {
  //   console.log("2374")
  //   if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
  //     return;
  //   } 
  //   if (event.key === 'e') {
  //     event.preventDefault();
  //     return;
  //   }
  
  //    const isValidInput =
  //     /[0-9]/.test(event.key) || // Numbers
  //     event.key === 'Backspace' || // Backspace key
  //     event.key === 'Delete' || // Delete key
  //     event.key === '.'; // Decimal point
  
  //    if (!isValidInput) {
  //     event.preventDefault();
  //     return;
  //   }
 
  
  //   const airQuantityValue = parseFloat((selectedDuct.airQuantity + event.key).replace(/[^0-9.]/g, ''));
  //   const heightValue = parseFloat((selectedDuct.height + event.key).replace(/[^0-9.]/g, ''));
  //   const frictionLossValue = parseFloat((selectedDuct.frictionLoss + event.key).replace(/[^0-9.]/g, ''));
  //   const widthValue = parseFloat((selectedDuct.width + event.key).replace(/[^0-9.]/g, ''));
  //   const velocityValue = parseFloat((selectedDuct.velocity + event.key).replace(/[^0-9.]/g, ''));
  
  //   // Check if any of the values exceed the range of 1 to 10000
  //   if (airQuantityValue > 10000 || heightValue > 10000 || frictionLossValue > 10000  || widthValue > 10000 || velocityValue > 10000) {
  //     event.preventDefault();
  //     return;
  //   }
  // };
  
  



    // useEffect(() => {
    //   if (ducts?.diaMeter) {
    //     isSetSavedShow(true);
    //   }
    // }, [ducts?.diaMeter]);

const shouldDisableButton = () => {
  if (buttonStatus) return true;
  // if (ducts.length === 0) return true;
  // if (!isDisable) return true;
  // if(isSavedShow) return ;

  if (
    !selectedDuct ||
    selectedDuct.frictionLoss === "" ||
    selectedDuct.velocity === "" ||
    selectedDuct.width === "" || // Adjust this condition based on your actual calculated properties
    selectedDuct.height === "" // Adjust this condition based on your actual calculated properties
  ) 
  {
    return true; // Disable the button if any calculated value is missing for the selected duct
  }

  return false; // Enable the button if all relevant calculated values are present for the selected duct
};

 
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>
      </Box>


      <section className="container-fluid PageTitle_banner_section">
        <div className="container">
          <h1 className="PageTitle_banner_heading">Duct Planner</h1>
        </div>
      </section>

      <section className="container-fluid Calculator_section  py-4">
        <div className="container">
          <div className="row Calculator_projectName_area align-items-center">
            <div className="col-12 col-lg-5 col-xl-4 col-md-7 mb-3">
              <div className="Calculator_backBtn_project_area">
                <div className="project_heading_BackBtn_area me-2 mt-2">
                  <a className="back_arrow" onClick={navigateToProject}>
                    <i className="fa-solid fa-arrow-left-long"></i>
                  </a>
                </div>
                <div className="form-group w-100">
                  <label htmlFor="">Project Name</label>
                  <input
                    type="text"
                    name="projectName"
                    value={project}
                    id=""
                    className={`form-control ${project == "" ? "error_msg_hp" : ""}`}
                    disabled={buttonStatus}
                    onChange={(event) => handleProjectChange(event)}
                    
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-5 col-xl-4 col-md-5 mb-3">
              <div className="form-group">
                <label htmlFor="">Unit System</label>
                <select
                  name="Cbunit"
                  id=""
                  className="form-select"
                  value={Cbunit}
                  title="Please remove all the calculation with previous unit system."
                  disabled={
                    ducts.length > 0 ? true : buttonStatus ? true : false
                  }
                  onChange={(e) => handleUnitSystem(e)}
                >
                  <option value={Unit.SI}>SI</option>
                  <option value={Unit.IMPERIAL}>IMPERIAL</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xl-3 col-lg-12 mb-4">
              <div className="row Calculator_subProject_List_area ">
                <div className="col-12 col-xl-12 col-lg-8 col-md-7 col-12">
                  <div
                    className="Calculator_subProject_List mb-3"
                    style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                  >
                    <DirectoryTreeView
                      data={ducts}
                      onSelected={handleSelected}
                      selectedID={selectedDuct}                

                    />
                  </div>
                </div>
                <div className="col-12 col-xl-12 col-lg-4 col-md-5 col-12">
                  <div className="Calculator_subProject_buttonList">
                    <button
                      className={`btn subProject_btn ${ducts.length === 0 ? "subProject_btn1" : ""
                        }`}
                      style={{
                        backgroundColor: ducts.length === 0 ? "green" : "",
                      }}
                      onClick={addMainDuct}
                      disabled={buttonStatus}
                    >
                      Add main duct
                    </button>
                    <button
                      className="btn subProject_btn"
                      style={{}}
                      onClick={addMultipleMainDucts}
                      disabled={buttonStatus || ducts.length === 0}
                    >
                      Add Multiple main duct
                    </button>
                    <button
                      className="btn subProject_btn"
                      style={{}}
                      onClick={addBranchToDuct}
                      disabled={buttonStatus || ducts.length === 0}
                    >
                      Add Branch
                    </button>
                    <button
                      className="btn subProject_btn"
                      style={{}}
                      onClick={addMultipleBranchesToSelectedDuct}
                      disabled={buttonStatus || ducts.length === 0}
                    >
                      Add multiple Branches
                    </button>
                    <button
                      className="btn subProject_btn"
                      style={{}}
                      onClick={() => removeDuct()}
                      disabled={buttonStatus || ducts.length === 0}
                    >
                      Remove
                    </button>
                    <>
                      <button
                        className="btn subProject_btn"
                        style={{}}
                        onClick={clearAllDucts}
                        disabled={buttonStatus || ducts.length === 0}
                      >
                        Remove all
                      </button>
                    </>
                    <>
                      <button
                        className="btn  subProject_btn1 "
                        onClick={() => saveDuct()}
                        disabled={buttonStatus || ducts.length === 0}
                      >
                        Save
                      </button>
                    </>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-12 col-lg-12 mb-4  col-xl-9 
                  
              `}
            >
              <div className="row align-items-start">
                <div className="col-12 col-xl-4 col-lg-6 col-md-12 mb-4">
                  <div className="Calculator_AirData_area">
                    <h5 className="Calculator_AirData_heading">Air Data</h5>
                    <div className="form-group mb-3">
                      <label htmlFor="">Duct Name</label>
                      <input
                        type="text"
                        name="name"
                        id=""
                        className="form-control"
                        value={selectedDuct?.name || ""}
                        placeholder=""
                        onChange={handleDuctTreeName}
                        disabled={buttonStatus}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="" style={{ textTransform: "none" }}>
                        Air Quantity<span className="redP">*</span>{" "}
                        {Cbunit === Unit.SI
                          ? "( L/S )"
                          : "( CFM )".replace(/l/g, "L").replace(/s/g, "s")}
                      </label>

                      <input
                        type="number"
                        name="airQuantity"
                        id=""
                        className={`form-control no-arrow ${isCalculating && selectedDuct?.airQuantity === "" || isNaN(selectedDuct?.airQuantity) ? "error_msg_hp" : ""}`}
                        placeholder=""
                        value={selectedDuct?.airQuantity}
                        onChange={handleChangeValues}
                        disabled={buttonStatus}
                        onKeyDown={handleKeyDown}

                      />

                    </div>

                    <div className="form-group mb-3">

                      <label htmlFor="" style={{ textTransform: "none" }}>
                        Friction loss{" "}
                        {Cbunit === Unit.SI ? "( P" + "A" + "/M )" : "(IN/100FT)"}
                      </label>

                      <input
                        type="number"                        
                        onKeyDown={handleKeyDown}
                        value={selectedDuct?.frictionLoss}
                        onChange={handleFrictionLossChange}
                        className={`form-control no-arrow ${isCalculating && selectedDuct?.frictionLoss === "" || isNaN(selectedDuct?.frictionLoss) ? "error_msg_hp" : ""}`}
                        disabled={
                          selectedDuct?.lockStatus
                            ? selectedDuct?.frictionStatus
                            : selectedDuct?.frictioninputStatus
                              ? true
                              : buttonStatus
                                ? true
                                : false
                        }
                      />

                      <Box sx={{ paddingTop: 1, marginLeft: 0, minWidth: 220 }}>
                        <Slider
                          aria-label="Custom marks"
                          defaultValue={Cbunit === Unit.SI ? 0.5 : 0.08}
                          min={Cbunit === Unit.SI ? 0.05 : 0.006}
                          max={Cbunit === Unit.SI ? 1 : 0.122}
                          step={Cbunit === Unit.SI ? 0.05 : 0.006}
                          valueLabelDisplay="auto"
                          marks={Cbunit === Unit.SI ? marks : marksImp}
                          name="frictionLoss"
                          value={selectedDuct?.frictionLoss || 0}
                          onChange={handleFrictionLossSliderChange}
                          disabled={
                            selectedDuct?.frictionStatus
                              ? true
                              : buttonStatus
                                ? true
                                : false
                          }
                        />
                      </Box>
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="">
                        Velocity &nbsp;
                        {Cbunit === Unit.SI ? "( m/s )" : "(FPM)"}
                      </label>
                      <input
                        type="number"
                        min={Cbunit === Unit.IMPERIAL  ? 590 : 3}
             
                        value={selectedDuct?.velocity}
                        onKeyDown={handleKeyDown}

                        onChange={handleVelocityChange}
                        className={`form-control no-arrow ${(Cbunit === "SI" &&
                          (selectedDuct?.velocity < 3 ||
                            selectedDuct?.velocity > 9.5) &&
                          selectedDuct.velocity !== "") ||
                          (Cbunit === Unit.IMPERIAL &&
                            (selectedDuct?.velocity < 590 ||
                              selectedDuct?.velocity > 1771) &&
                            selectedDuct.velocity !== "")
                          ? "error_msg_hp"
                          : ""
                          } ${ isCalculating && selectedDuct?.velocity == "" ? "error_msg_hp" : ""}`}
                        disabled={
                          selectedDuct?.lockStatus
                            ? selectedDuct?.velocityStatus
                            : selectedDuct?.velocityinputStatus
                              ? true
                              : buttonStatus
                                ? true
                                : false
                        }
                      />
                      <Box sx={{ paddingTop: 1, marginLeft: 0, minWidth: 220 }}>
                        <Slider
                          aria-label="Custom marks"
                          defaultValue={Cbunit === Unit.SI ? 5 : 98.4}
                          // min={Cbunit === Unit.SI ? 0.5 : 98.4}
                          min={Cbunit === Unit.SI ? 3 : 590}
                          max={Cbunit === Unit.SI ? 10 : 1968}
                          step={Cbunit === Unit.SI ? 0.5 : 98.4}
                          valueLabelDisplay="auto"
                          marks={Cbunit === Unit.SI ? marksVelo : marksVeloImp}
                          name="velocity"
                          value={selectedDuct?.velocity || 0}
                          onChange={handleVelocityChange}
                          disabled={
                            selectedDuct?.velocityStatus
                              ? true
                              : buttonStatus
                                ? true
                                : false
                          }
                        />
                      </Box>
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="">Duct Material</label>
                      <select name="" id="" className="form-select">
                        <option value="">Galvanized Steel</option>
                      </select>
                    </div>
                  </div>
                  <button
                    className="btn btn_viewDuct mx-1"
                    style={{ width: "46%" }}
                    onClick={() => handleMainCalculation()}
                    disabled={buttonStatus || ducts.length === 0}
                  >
                    Calculate
                  </button>
                  <button
                    className="btn btn_viewDuct mx-1"
                    style={{ width: "46%" }}
                    disabled={buttonStatus || ducts.length === 0}
                    onClick={handleRefresh}
                  >
                    Refresh
                  </button>
                </div>
                <div className="col-12 col-xl-4 col-lg-6 col-md-12 mb-4">
                  <div className="Calculator_AirData_area">
                    <h5 className="Calculator_AirData_heading">Duct Size</h5>
                    <div className="form-group mb-3">
                      <label htmlFor="">
                        Round Duct diameter{" "}
                        {Cbunit === Unit.SI ? "( mM )" : "( IN )"}
                      </label>
                      <input
                        type="text"
                        name="diaMeter"
                        id=""
                        className="form-control no-arrow"
                        placeholder=""
                        disabled
                        value={
                          selectedDuct?.diaMeter !== undefined && !isNaN(selectedDuct?.diaMeter)
                            ? selectedDuct?.diaMeter
                            : 0
                        }
                      />
                    </div>

                    <label
                      htmlFor=""
                      className=""
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                      }}
                    >
                      Rectangular Duct Dimensions{" "}
                      {Cbunit === Unit.SI ? "( MM )" : "( IN )"}
                    </label>

                    <div className="form-group mt-2 mb-2">
                      <label htmlFor="">Width  {Cbunit === Unit.SI ? "( mM )" : "( IN )"}</label>
                      <input
                        type="number"
                        min="100"
                        max="1000"
                        name="width"
                        // onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                        onKeyDown={handleKeyDown}
                        value={selectedDuct?.width}
                        onChange={handleWidthChange}
                        className={`form-control no-arrow ${(Cbunit === Unit.SI &&
                          (selectedDuct?.width > selectedDuct?.height * 4 ||
                            selectedDuct?.width < selectedDuct?.height / 4) &&
                          selectedDuct?.width !== "") ||
                          (Cbunit === Unit.IMPERIAL &&
                            (selectedDuct?.width > selectedDuct?.height * 4 ||
                              selectedDuct?.width < selectedDuct?.height / 4) &&
                            selectedDuct?.width !== "")
                          ? "error_msg_hp"
                          : ""
                          }`}
                        disabled={
                          selectedDuct?.widthStatus
                            ? true
                            : buttonStatus
                              ? true
                              : false
                        }
                      />

                      <Box
                        sx={{
                          paddingTop: 1,
                          marginLeft: 0,
                          minWidth: 220,
                        }}
                      >
                        <Slider
                          aria-label="Custom marks"
                          defaultValue={Cbunit === Unit.SI ? 500 : 8}
                          min={Cbunit === Unit.SI ? 100 : 4}
                          max={Cbunit === Unit.SI ? 1000 : 40}
                          step={Cbunit === Unit.SI ? 6 : 1}
                          valueLabelDisplay="auto"
                          marks={
                            Cbunit === Unit.SI ? marksHeight : marksHeightImp
                          }
                          name="width"
                          style={{ fontSize: 11 }}
                          value={selectedDuct?.width || 0}
                          onChange={handleWidthChange}
                          disabled={
                            selectedDuct?.widthStatus
                              ? true
                              : buttonStatus
                                ? true
                                : false
                          }
                        />
                      </Box>
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="">height  {Cbunit === Unit.SI ? "( mM )" : "( IN )"}</label>
                      <input
                        type="number"
                        name="height"
                        value={selectedDuct?.height}
                        onChange={handleHeightChange}
                        // onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                        onKeyDown={handleKeyDown}
                        className="form-control no-arrow"
                        disabled={buttonStatus}
                      />

                      <Box
                        sx={{
                          paddingTop: 1,
                          marginLeft: 0,
                          minWidth: 220,
                        }}
                      >
                        <Slider
                          aria-label="Custom marks"
                          defaultValue={Cbunit === Unit.SI ? 500 : 12}
                          min={Cbunit === Unit.SI ? 100 : 4}
                          max={Cbunit === Unit.SI ? 1000 : 40}
                          step={Cbunit === Unit.SI ? 6 : 1}
                          valueLabelDisplay="auto"
                          marks={
                            Cbunit === Unit.SI ? marksHeight : marksHeightImp
                          }
                          name="height"
                          value={selectedDuct?.height || 0}
                          onChange={handleHeightChange}
                          disabled={
                            selectedDuct?.heightStatus
                              ? true
                              : buttonStatus
                                ? true
                                : false
                          }
                        />
                      </Box>
                    </div>

                    <button
                      className="btn m-auto btn_lock_Duct mb-3"
                      onClick={() =>
                        handleLockDuctSize(selectedDuct?.lockStatus)
                      }
                      title={
                        selectedDuct?.lockStatus
                          ? "Unlock duct size to calculate friction loss and velocity"
                          : "Lock duct size to calculate friction loss and velocity"

                      }
                      // disabled={buttonStatus || ducts.length === 0 || !isDisable  }
                        disabled={shouldDisableButton()}
                    >
                      {selectedDuct?.lockStatus ? (
                        <>
                          <i className="fa-solid fa-lock me-2"></i>
                          Unlock Duct Size
                        </>
                      ) : (
                        <>
                          <i className="fa-solid fa-unlock me-2"></i>
                          Lock Duct Size
                        </>
                      )}
                    </button>

                    <div className="form-group mb-3">
                      <label htmlFor="">
                        Flow area {Cbunit === Unit.SI ? "( m² )" : "( IN² )"}
                      </label>
                      <input
                        type="text"
                        name="flowArea"
                        id=""
                        className="form-control no-arrow"
                        placeholder=""
                        value={!isNaN(selectedDuct?.flowArea) ? selectedDuct?.flowArea || "" : ""}
                        disabled
                      />
                    </div>
                  </div>
                  {(user?.usertype === "paid" ||
                    user?.paid === "paid" ||
                    user?.paid === "unpaid") && (
                      <button
                        className="btn btn_viewDuct w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#ViewModel"

                        disabled={buttonStatus || ducts.length === 0}
                      >
                        View all ducts
                      </button>
                    )}
                </div>
                <div className="col-12 col-xl-4 col-lg-6 col-md-12 mb-4">
                  <div className="Calculator_AirData_area">
                    <h5 className="Calculator_AirData_heading">
                      Possible sizing
                    </h5>
                    <div>
                      {Cbunit === Unit.SI ? (
                        <>
                          <div className="Possible_sizing_heading mb-3 mt-4 ml-0 mr-0">
                            <div>
                              Width <br /> <small>( MM )</small>
                            </div>
                            <div>X</div>
                            <div>
                              Height <br /> <small>( MM )</small>
                            </div>
                            <div>Ratio</div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width1 !== undefined &&
                                selectedDuct?.width1 !== ""
                                ? selectedDuct?.width1
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 250</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio1 !== undefined &&
                                selectedDuct?.ratio1 !== ""
                                ? selectedDuct?.ratio1
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width2 !== undefined &&
                                selectedDuct?.width2 !== ""
                                ? selectedDuct?.width2
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 300</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio2 !== undefined &&
                                selectedDuct?.ratio2 !== ""
                                ? selectedDuct?.ratio2
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width3 !== undefined &&
                                selectedDuct?.width3 !== ""
                                ? selectedDuct?.width3
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 350</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio3 !== undefined &&
                                selectedDuct?.ratio3 !== ""
                                ? selectedDuct?.ratio3
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width4 !== undefined &&
                                selectedDuct?.width4 !== ""
                                ? selectedDuct?.width4
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 400</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio4 !== undefined &&
                                selectedDuct?.ratio4 !== ""
                                ? selectedDuct?.ratio4
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width5 !== undefined &&
                                selectedDuct?.width5 !== ""
                                ? selectedDuct?.width5
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 500</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio5 !== undefined &&
                                selectedDuct?.ratio5 !== ""
                                ? selectedDuct?.ratio5
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data">
                            <div>
                              {selectedDuct?.width6 !== undefined &&
                                selectedDuct?.width6 !== ""
                                ? selectedDuct?.width6
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 600</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio6 !== undefined &&
                                selectedDuct?.ratio6 !== ""
                                ? selectedDuct?.ratio6
                                : ""}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="Possible_sizing_heading mb-3 mt-4">
                            <div>
                              Width <br /> <small>( IN )</small>
                            </div>
                            <div>X</div>
                            <div>
                              Height <br /> <small>( IN )</small>
                            </div>
                            <div>Ratio</div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width1 !== undefined &&
                                selectedDuct?.width1 !== ""
                                ? selectedDuct?.width1
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 12</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio1 !== undefined &&
                                selectedDuct?.ratio1 !== ""
                                ? selectedDuct?.ratio1
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width2 !== undefined &&
                                selectedDuct?.width2 !== ""
                                ? selectedDuct?.width2
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 14</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio2 !== undefined &&
                                selectedDuct?.ratio2 !== ""
                                ? selectedDuct?.ratio2
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width3 !== undefined &&
                                selectedDuct?.width3 !== ""
                                ? selectedDuct?.width3
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 16</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio3 !== undefined &&
                                selectedDuct?.ratio3 !== ""
                                ? selectedDuct?.ratio3
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width4 !== undefined &&
                                selectedDuct?.width4 !== ""
                                ? selectedDuct?.width4
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 18</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio4 !== undefined &&
                                selectedDuct?.ratio4 !== ""
                                ? selectedDuct?.ratio4
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {selectedDuct?.width5 !== undefined &&
                                selectedDuct?.width5 !== ""
                                ? selectedDuct?.width5
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 20</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio5 !== undefined &&
                                selectedDuct?.ratio5 !== ""
                                ? selectedDuct?.ratio5
                                : ""}
                            </div>
                          </div>
                          <div className="Possible_sizing_data">
                            <div>
                              {selectedDuct?.width6 !== undefined &&
                                selectedDuct?.width6 !== ""
                                ? selectedDuct?.width6
                                : ""}
                            </div>
                            <div>X</div>
                            <div> 22</div>
                            <div>
                              {" "}
                              {selectedDuct?.ratio6 !== undefined &&
                                selectedDuct?.ratio6 !== ""
                                ? selectedDuct?.ratio6
                                : ""}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="Calculator_AirData_area mt-4">
                    <div className="form-group ">
                      <label htmlFor="">Remarks</label>
                      <textarea
                        name="remark"
                        id=""
                        placeholder="Ex.- Remarks"
                        className="form-control"
                        rows="9"
                        value={selectedDuct?.remark}
                        onChange={handleChangeValuesForRemarks}
                        disabled={buttonStatus}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="ViewModel"
        tabIndex="-1"
        aria-labelledby="btn_viewDuctLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">All Ducts List</h5>
              <button
                type="button"
                className="btn close"
                id="close-modal"
                data-bs-dismiss="modal"
                aria-label="Close"

              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" ref={reportTemplateRef}>
              <div className="Action_btn_area">
                <button
                  className="btn btn-primary "
                  style={{ marginRight: "5px" }}
                  onClick={exportExcel}
                >
                  <i className="fa-solid fa-download me-1"></i> Export Excel
                </button>
                <button className="btn btn-primary" onClick={handleGeneratePdf}>
                  <i className="fa-solid fa-print me-1"></i> Print Preview
                </button>
              </div>
              <DuctsList unitType={Cbunit} ducts={ducts} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuth(Calculator);
