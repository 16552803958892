import React, { useEffect, useState } from "react";
import { API_URL } from "../../Services/UserServices";
// import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../../provider/AuthProvider";
import withAuth from "../WithAuth";
// import Calculator from "./Calculator";
import { useNavigate } from "react-router-dom";
import api from "../../Services/ApiService";
import NoData from "../../assets/NoData.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Tooltip } from 'react-tooltip';



function Product() {
  const [products, setProduct] = useState([]);
  const [productName, setProductName] = useState("");
  const [unit, setUnit] = useState("SI");
  const [selectProduct, setSelectedProduct] = useState(null);
  const { user } = useAuth();
  const ModalButton = document.getElementById("modal-button");
  const closeButton = document.getElementById("close-modal");
  const [loading, setLoading] = useState(false);
  const pageSize = 10; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("asc");

  const totalPages = Math.ceil(products.length / pageSize);
  const navigate = useNavigate();

  // Get the current page's data
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageProducts = products.slice(startIndex, endIndex);
  // const [productName, setProductName] = useState('');
  // const [unit, setUnit] = useState('');
  const [productNameError, setProductNameError] = useState("");
  const [unitError, setUnitError] = useState("");
  // const [loading, setLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false); // New state for showing errors

  // Function to navigate to a specific page
  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const [projectListLength, setProjectListLenth] = useState(0);
  const getProductList = async () => {
    addCountVisitPage();
    setLoading(true);
    try {
      const { data: res } = await api.get("/calculation/getAll");
      if (res?.statusCode == 200) {
        setProduct(res.data);
        setProjectListLenth(res.data.length);
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      Swal.fire({
        text:  e.message == "Network Error" ? "Please check your internet connection.": e.message,
      });
    }
  };

  const getUserOne = async () => {
    setLoading(true);
    try {
      let user = JSON.parse(localStorage.getItem("user") ?? "{}");
      const { data: res } = await api.get("/user/getOne-user/" + user.id
      );
      if (res?.statusCode == 200) {
        const currentObj = {
          ...user,
          id: res?.data?.id,
          name: user?.name,
          email: user?.email,
          role: user?.role,
          paid: res?.data?.paid,
          userSubs: res?.data?.userSubs,
          isFirstTimeLogin: res?.data?.isFirstTimeLogin,
          subscription: res?.data?.subscription,
          token: user?.token,
          isFreeUsed :user?.isFreeUsed
        };

        localStorage.setItem("user", JSON.stringify(currentObj));
        if (res?.data?.paid !== "paid" && res?.data?.subscription === false) {
          setButtonStatus(true);
        } else {
          setButtonStatus(false);
        }
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {      
      setLoading(false);
      Swal.fire({
        text: e.message == "Network Error" ? "Please check your internet connection.": e.message,
      });
    }
  };

  const [validDays, setValidDays] = useState("");

  const getSubList = async () => {
    setLoading(true);
    try {
      const { data: res } = await api.get("/user-subscription/get-remaining-day"
      );
      let message = "";
      switch (res?.statusCode) {
        case 200:
          if (res?.data > 0) {
            switch (res?.data) {
              case 1:
                message = "Your subscription will expire tomorrow";
                break;
              default:
                if (res?.data <= 30) {
                  message = `Your subscription will expire in ${res?.data} days.`;
                }
                break;
            }
          } else {
            message = "Your Subscription Expired";
          }
          break;
        default:
          message = " ";
          break;
      }
      setValidDays(message);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      Swal.fire({
        text:  e.message == "Network Error" ? "Please check your internet connection.": e.message,
      });
    }
  };

  const addCountVisitPage = async () => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem("user") ?? "{}");
    try {
      const { data: res } = await api.get("/user-analytics/" + user?.id
      );
      if (res.statusCode == 200) {
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      Swal.fire({
        text:  e.message == "Network Error" ? "Please check your internet connection.": e.message,
      });
    }
  };

  const [loginType, setLoginType] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false);
  useEffect(() => {
    getProductList();
    getSubList();
    getUserOne();
    const user = JSON.parse(localStorage.getItem("user") ?? "{}");
    if (user.paid == "paid" || (user.paid == "unpaid" && user?.userSubs == true)) {
      navigate("/Project");
    }
    if (user?.isFirstTimeLogin) {
      setLoginType(true);
    }
  }, []);

  const handleSubmit = async () => {
    setShowErrors(true); // Show errors on submission
    // Validation
    let isValid = true;

    if (productName.trim() === "") {
      setProductNameError("Project name required");
      isValid = false;
    } else if (productName.replace(/\s+/g, "").length < 3) {
      setProductNameError(
        "Project name must have at least 3 non-space characters"
      );
      isValid = false;
    } else if (productName.length > 55) {
      setProductNameError("Project name must be at most 55 characters");
      isValid = false;
    } else {
      setProductNameError("");
    }

    if (!unit) {
      setUnitError("Please select a unit");
      isValid = false;
    } else {
      setUnitError("");
    }

    if (!isValid) {
      return;
    }

    try {
      setLoading(true);

      let recordData;
      if (selectProduct) {
        recordData = {
          id: selectProduct?.id,
          productName,
          unit,
          userId: user?.id,
        };
      } else {
        recordData = {
          productName,
          unit,
          ductData: [],
          children: [],
          userId: user?.id,
        };
      }

      const { data: res } = await api.post("/calculation/create-update", recordData,
        { headers: { Authorization: `bearer ${user?.token}` } }
      );
      if (res?.statusCode == 200 || res?.statusCode == 201) {
        getProductList();
        setProductName("");
        setUnit("SI");
        closeButton.click();
        setLoading(false);
        setSelectedProduct(null);
        getUserOne();
        // Swal.fire(
        //   selectProduct
        //     ? "Project updated successfully."
        //     : "Project created successfully."
        // );
        const notify = () =>
          toast.success(
            selectProduct
              ? "Project updated successfully."
              : "Project created successfully.",
            {
              autoClose: 1000,
            }
          );
        notify();
      } else {
        setLoading(false);
        toast.error(res?.message);

      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status == 401) {
        localStorage.removeItem("user");
        window.location.href = "/Register";
        return;
      }

      if (
        error?.response &&
        error?.response?.data &&
        error?.response?.data.message
      ) {
        // Swal.fire({
        //   icon: "error",
        //   text: error.response.data.message,
        // });
        toast.error(error?.response?.data?.message);

      } else {
        // Swal.fire({
        //   icon: "error",
        //   text: "An error occurred. Please try again later.",
        // });
        toast.error("An error occurred. Please try again later.");

      }
      console.error(error);
    }
  };

  const show = (project) => {
    getUserOne();

    localStorage.setItem("projectID", project.id);
    navigate("/Calculator");
  };

  const edit = (project) => {
    setSelectedProduct(project);
    setProductName(project?.productName ?? "");
    setUnit(project?.unit ?? "");
    ModalButton?.click();
  };

  const deleteProduct = async (product) => {
    try {
      const confirmDelete = await Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this project!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      if (confirmDelete.isConfirmed) {
        setLoading(true);

        const { data: res } = await api.get(
          `/calculation/delete/${product.id}`,
          {
            headers: { Authorization: `bearer ${user?.token}` },
          }
        );

        if (res.statusCode === 200) {
          getProductList();
          Swal.fire("Deleted!", "The product has been deleted.", "success");
        } else {
          Swal.fire(
            "Error",
            res?.message || "Failed to delete the product.",
            "error"
          );
        }

        setLoading(false);
        window.location.reload();
      }
    } catch (e) {
      setLoading(false);
      if (e?.response?.status == 401) {
        localStorage.removeItem("user");
        window.location.href = "/Register";
      } else {
        Swal.fire({
          icon: "error",
          text:  e.message == "Network Error" ? "Please check your internet connection.": e.message || "An error occurred while deleting the product.",
        });
      }
    }
  };

  // const goToCalculation = async (projectId) => {
  //   sessionStorage.setItem("projectId", projectId);
  //   window.location.href = "/Calculator";
  // };

  if (loading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ margin: "5%", minHeight: "320px" }}
      >
        <div className="spinner-border m-5 text-center" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const handleProductNameClick = () => {
    // Assuming "/Calculator" is the path to the calculation page
    navigate("/Calculator");
  };

  const handleSortByDate = () => {
    const sortedProducts = [...products].sort((a, b) => {
      const dateA = moment(a.createdAt);
      const dateB = moment(b.createdAt);

      if (sortOrder === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    setProduct(sortedProducts);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };


  const handleShow = (item) => {

    window.location.href = `/SpaceProjectsList/${item}`


  }
  return (
    <div>

      <section className="container-fluid PageTitle_banner_section">
        <div className="container">
          <h1 className="PageTitle_banner_heading">Projects</h1>
        </div>
      </section>

      <section className="container-fluid  mt-4">
        <div className="container">
          <div className="row ">
            <div className="col-md-12">
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  id="modal-button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <i className="fa-solid fa-plus me-1"></i> New Project
                </button>
              </div>
              <ToastContainer />
            </div>
          </div>

          <div className="Project_listing_section_hp my-3">
            <div className="row">
              <div className="col-md-12">
                {/* {projectListLength == 0 ? (
                  <>
                    <div className="py-1 text-center">
                      <img src={text} />
                    </div>
                  </>
                ) : ( */}
                <div className="table-responsive">
                  <table className="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Project Name</th>
                        <th>Unit System</th>
                        <th onClick={handleSortByDate} style={{ cursor: "pointer" }} >
                          Created On
                          {/* {sortOrder === 'asc' ? '▲' : '▼'} */}
                          {sortOrder === "asc" && (
                            <span style={{ marginLeft: "15px", fontWeight: "200" }}><i class="fa-solid fa-arrow-down-9-1"></i></span>
                          )}
                          {sortOrder === "desc" && (
                            <span style={{ marginLeft: "15px", fontWeight: "200" }}><i class="fa-solid fa-arrow-down-1-9"></i></span>
                          )}
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.length == "0" ? (
                        <tr>
                          <td
                            colSpan="5"
                            style={{ textAlign: "center", height: "300px" }}
                          >
                            <div>
                              <img
                                src={NoData}
                                alt="No data available"
                                style={{ maxWidth: "300px" }}
                              />
                              <h5 className="mt-2">Create Your First Project</h5>
                            </div>

                          </td>
                        </tr>
                      ) : (
                        currentPageProducts?.map((product, index) => (
                          <tr key={index} className="project_list_width_sl">
                            <td>{startIndex + index + 1}</td>
                            <td> {product?.productName} </td>
                            <td>{product?.unit}</td>
                            <td> {product?.createdAt ? moment(product?.createdAt).format("MMMM Do YYYY, h:mm a") : ""} </td>
                            <td>
                              <div className="table-action-icon">
                                {/* <a onClick={handleProductNameClick} >
                                  <i className="fa-solid fa-eye"></i>
                                </a> */}
                                <a onClick={() => show(product)}
                                  data-tooltip-id="Project_view"
                                  data-tooltip-content="View"
                                >
                                  <i className="fa-solid fa-eye"></i>
                                  <Tooltip id="Project_view" />
                                </a>
                                {buttonStatus !== true ? (
                                  <>
                                    {/* <a onClick={() => edit(product)}>
                                            <i className="fa-regular fa-pen-to-square"></i>
                                          </a> */}
                                  </>
                                ) : (
                                  <></>
                                )}

                                <a onClick={() => handleShow(product.id)}
                                  data-tooltip-id="Prodject_HeatSummary"
                                  data-tooltip-content="Heat Summary"
                                >
                                  <i class="fa-solid fa-fire"></i>
                                  <Tooltip id="Prodject_HeatSummary" />
                                </a>
                                <a onClick={() => deleteProduct(product)}
                                  data-tooltip-id="Project_Delete"
                                  data-tooltip-content="Delete"
                                >
                                  <i className="fa-solid fa-trash-can"></i>
                                  <Tooltip id="Project_Delete" />
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                  {/* Pagination Controls */}
                  {products.length == "0" ? (
                    <></>
                  ) : (
                    <div className="d-flex justify-content-end">
                      <div className="pagination m-1">
                        <a
                          className="btn_next_Previous"
                          onClick={() => goToPage(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          <i class="fa-solid fa-angle-left"></i>
                        </a>
                        <div className="mx-3">
                          {" "}
                          Page {currentPage} of {totalPages}
                        </div>
                        <a
                          className="btn_next_Previous"
                          onClick={() => goToPage(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        >
                          <i class="fa-solid fa-angle-right"></i>
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                {/* )} */}
              </div>
            </div>
          </div>

        </div>
      </section>


      {/* <section className="container-fluid Project-section">
        <div className="container">
          <div className="row mb-5 ">
            <div className="col-md-12 mt-4 text-center">
              <h5 className="fw-bold me-3">{validDays}</h5>
            </div>
            <div className="col-md-12 mt-3">
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <h3 className="fw-bold me-1">Projects List</h3>

                <button
                  type="button"
                  className="btn btn-primary"
                  id="modal-button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <i className="fa-solid fa-plus me-1"></i> New Project
                </button>
              </div>
              <ToastContainer />
            </div>

            <div className="col-md-12 my-4">
              {projectListLength == 0 ? (
                <>
                  <div className="py-1 text-center">
                    <img src={text} />
                  </div>
                </>
              ) : (
                <div className="col-md-12 mt-4">
                  <div className="card border-0">
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr className="table-header">
                              <th>S. No.</th>
                              <th>Project Name</th>
                              <th>Unit System</th>
                              <th
                                onClick={handleSortByDate}
                                style={{ cursor: "pointer" }}
                              >
                                Created On
                                {sortOrder === "asc" && (
                                  <span style={{ marginLeft: "4px" }}>▲</span>
                                )}
                                {sortOrder === "desc" && (
                                  <span style={{ marginLeft: "4px" }}>▼</span>
                                )}
                              </th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {products.length == "0" ? (
                              <tr>
                                {" "}
                                <td colSpan="4" className="text-center ">
                                  No Data Found
                                </td>{" "}
                              </tr>
                            ) : (
                              currentPageProducts?.map((product, index) => (
                                <tr key={index} className="project_list_width_sl">
                                  <td>{startIndex + index + 1}</td>
                                  <span
                                    onClick={handleProductNameClick}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {" "}
                                    {product?.productName}
                                  </span>
                                  <td>{product?.unit}</td>
                                  <td>
                                    {product?.createdAt
                                      ? moment(product?.createdAt).format(
                                        "MMMM Do YYYY, h:mm a"
                                      )
                                      : ""}
                                  </td>

                                  <td>
                                    <div className="table-action-icon">
                                     
                                      <a onClick={() => show(product)}>
                                        <i className="fa-regular fa-pen-to-square"></i>
                                      </a>
                                      {buttonStatus !== true ? (
                                        <>
                                         
                                        </>
                                      ) : (
                                        <></>
                                      )}

                                      <a onClick={() => handleShow(product.id)}>
                                        <i class="fa-solid fa-fire"></i>
                                      </a>
                                      <a onClick={() => deleteProduct(product)}>
                                        <i className="fa-regular fa-trash-can"></i>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                        {products.length == "0" ? (
                          <></>
                        ) : (
                          <div className="d-flex justify-content-end">
                            <div className="pagination m-1">
                              <button
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => goToPage(currentPage - 1)}
                                disabled={currentPage === 1}
                              >
                                Previous
                              </button>
                              <span className="mx-3">
                                {" "}
                                Page {currentPage} of {totalPages}
                              </span>
                              <button
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => goToPage(currentPage + 1)}
                                disabled={currentPage === totalPages}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section> */}

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-primary ">
              <h5 className="modal-title text-white " id="exampleModalLabel">
                {selectProduct ? "Update Project" : "Add New Project"}
              </h5>
              <button
                type="button"
                className="btn close"
                id="close-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setSelectedProduct(null)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group mb-3">
                <label className="m-1">Project Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ex-Hotel Ventilation"
                  value={productName}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 55) {
                      setProductName(inputValue);
                      setProductNameError(""); // Clear the error message
                    }
                  }}
                />
                {showErrors && productNameError && (
                  <p className="error-message">{productNameError}</p>
                )}
              </div>
              <div className="form-group mb-3">
                <label className="m-1">Unit System</label>
                {selectProduct ? (
                  <>
                    <div
                      className="form-control"
                      style={{ backgroundColor: "lightgrey" }}
                      disabled
                    >
                      {unit}
                    </div>
                  </>
                ) : (
                  <select
                    className="form-select"
                    value={unit}
                    onChange={(e) => {
                      setUnit(e.target.value);
                      setUnitError(""); // Clear the error message
                    }}
                  >
                    <option value={"SI"}>SI</option>
                    <option value={"Imperial"}>IMPERIAL</option>
                  </select>
                )}
                {showErrors && unitError && (
                  <p className="error-message">{unitError}</p>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuth(Product);
