import React from 'react'

const OtherDetails = ({ register, errors }) => {
    return (

        <div className='space_input_detail_area mb-4'>
            <h4 className='space_input_detail_heading mb-3'>Other Details</h4>

            <div className='row'>
                <div className='col-xl-4 col-lg-6 col-md-6 col-12'>
                    <div className='space_input_detail_header_area Space_detai_header1'>
                        <div>Item</div>
                        <div>Area <samll>(Sq ft)</samll></div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>Occupancy</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }}  {...register("occupancy")} /> <p style={{ color: "red" }}>{errors.occupancy?.message}</p></div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>Height</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }}  {...register("height")} /> <p style={{ color: "red" }}>{errors.height?.message}</p></div>
                    </div>
                </div>

                <div className='col-xl-4 col-lg-6 col-md-6 col-12'>
                    <div className='space_input_detail_header_area Space_detai_header2'>
                        <div>Item</div>
                        <div>Area <samll>(Sq ft)</samll></div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>Lighting</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }}  {...register("lighting")} />  <p style={{ color: "red" }}>{errors.lighting?.message}</p></div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>Fresh Air</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }}  {...register("freshAir")} /> <p style={{ color: "red" }}>{errors.freshAir?.message}</p></div>
                    </div>
                </div>

                <div className='col-xl-4 col-lg-6 col-md-6 col-12'>
                    <div className='space_input_detail_header_area Space_detai_header3'>
                        <div>Item</div>
                        <div>Area <samll>(Sq ft)</samll></div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>Eq. Load</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }}  {...register("eqLoad")} /> <p style={{ color: "red" }}>{errors.eqLoad?.message}</p></div>
                    </div>

                    <div className='space_input_detail_data_area'>
                        <div>Air Change per hour</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }}  {...register("AirChange")} /> <p style={{ color: "red" }}>{errors.AirChange?.message}</p> </div>
                    </div>
                </div>
                

                {/* <div className='col-xl-4 col-lg-6 col-md-6 col-12'>
                    <div className='space_input_detail_header_area Space_detai_header3'>
                        <div>Item</div>
                        <div>Area <samll>(Sq ft)</samll></div>
                    </div>
                   
                </div> */}

                
                <div className='col-12'>
                    <p><strong>Note:- </strong> Occupancy is Air changes (per hour).</p>
                </div>
            </div>

        </div>

    )
}

export default OtherDetails