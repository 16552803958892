import React from 'react'
import MachineImg from '../../assets/Machine.jpg'
import { Tooltip } from 'react-tooltip';


function MachineList() {
    return (
        <>
            <section className="container-fluid PageTitle_banner_section">
                <div className="container">
                    <h1 className="PageTitle_banner_heading">Machines</h1>
                </div>
            </section>

            <section className="container-fluid MachineList_section my-4">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div className="d-flex flex-wrap align-items-center justify-content-end">
                                <a href='/AddMachineForm'
                                    type="button"
                                    className="btn btn-primary add_machine_btn"
                                >
                                    <i className="fa-solid fa-plus me-1"></i> Add Machine
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-3'>
                            <div className='MachineList_item_area'>
                                <div className='MachineList_item_img_area position-relative'>
                                    <img src={MachineImg} alt='MachineImg' />
                                    <a className='Machine_delete_icon'
                                        data-tooltip-id="Machine_Delete"
                                        data-tooltip-content="Delete">
                                        <i class="fa-solid fa-trash-can"></i>
                                        <Tooltip id="Machine_Delete" />
                                    </a>
                                </div>
                                <div className='MachineList_item_content_area'>
                                    <div className='Machine_name_Area'>
                                        <h5>Fiber Duct Air Cooler</h5>
                                        <p>Ice Fire</p>
                                    </div>
                                    <div className='Machine_detail_area'>
                                        <div className=''>
                                            <p>AC Voltage (Hz)</p>
                                            <h4> 1 Phase 220v/50HZ </h4>
                                        </div>
                                        <div className=''>
                                            <p>Air Flow (CMH)</p>
                                            <h4>6500</h4>
                                        </div>

                                        <div className=''>
                                            <p>Air Flow (CFM)</p>
                                            <h4>3825.75</h4>
                                        </div>

                                        <div className=''>
                                            <p>Water Capacity (Ltr)</p>
                                            <h4>125</h4>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-3'>
                            <div className='MachineList_item_area'>
                                <div className='MachineList_item_img_area position-relative'>
                                    <img src={MachineImg} alt='MachineImg' />
                                    <div className='Machine_delete_icon'>
                                        <i class="fa-solid fa-trash-can"></i>
                                    </div>
                                </div>
                                <div className='MachineList_item_content_area'>
                                    <div className='Machine_name_Area'>
                                        <h5>Fiber Duct Air Cooler</h5>
                                        <p>Ice Fire</p>
                                    </div>
                                    <div className='Machine_detail_area'>
                                        <div className=''>
                                            <p>AC Voltage (Hz)</p>
                                            <h4> 1 Phase 220v/50HZ </h4>
                                        </div>
                                        <div className=''>
                                            <p>Air Flow (CMH)</p>
                                            <h4>6500</h4>
                                        </div>

                                        <div className=''>
                                            <p>Air Flow (CFM)</p>
                                            <h4>3825.75</h4>
                                        </div>

                                        <div className=''>
                                            <p>Water Capacity (Ltr)</p>
                                            <h4>125</h4>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-3'>
                            <div className='MachineList_item_area'>
                                <div className='MachineList_item_img_area position-relative'>
                                    <img src={MachineImg} alt='MachineImg' />
                                    <div className='Machine_delete_icon'>
                                        <i class="fa-solid fa-trash-can"></i>
                                    </div>
                                </div>
                                <div className='MachineList_item_content_area'>
                                    <div className='Machine_name_Area'>
                                        <h5>Fiber Duct Air Cooler</h5>
                                        <p>Ice Fire</p>
                                    </div>
                                    <div className='Machine_detail_area'>
                                        <div className=''>
                                            <p>AC Voltage (Hz)</p>
                                            <h4> 1 Phase 220v/50HZ </h4>
                                        </div>
                                        <div className=''>
                                            <p>Air Flow (CMH)</p>
                                            <h4>6500</h4>
                                        </div>

                                        <div className=''>
                                            <p>Air Flow (CFM)</p>
                                            <h4>3825.75</h4>
                                        </div>

                                        <div className=''>
                                            <p>Water Capacity (Ltr)</p>
                                            <h4>125</h4>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MachineList