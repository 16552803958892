// import axios from 'axios';
import React, { useEffect, useState } from 'react'
// import { API_URL } from '../../Services/UserServices';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
// import { removeArray } from '../../Redux/Reducer/planSlice';
import { useAuth } from '../../provider/AuthProvider';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import api from '../../Services/ApiService';


function Checkout() {
    const { user } = useAuth()
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const PlanSelector = useSelector((data) => data.plan)

    const [selectedPlan, setSelectedPlan] = useState("");
    const [couponValue, setCouponValue] = useState("");
    const [couponError, setCouponError] = useState("");
    const [coupon, setCoupon] = useState("");

    useEffect(() => {
        setLoading(true);
        if (PlanSelector !== "") {
            setSelectedPlan(PlanSelector);
            setLoading(false);

        }
        if (couponValue.length > 0) {
            setCouponError("")
        }
    }, [couponValue])


    const discountAmount = coupon !== "" ? coupon.percentage / 100 : 0;
    const price = selectedPlan.PlanType === 1 ? selectedPlan.monthly : selectedPlan.yearly;
    const discountValue = coupon !== "" ? price * discountAmount : 0;
    const GrandTotal = price - discountValue


    const handleCoupon = async () => {
        if (!user) {
            navigate('/login?redirect=/Pricing');
            return;
        }
        if (couponValue == "") {
            setCouponError("Please enter coupon code")
        }
        if (couponValue !== "") {
            const rObj = {
                coupon: couponValue,
            }
            try {
                // setLoading(true)
                const { data } = await api.post(`/coupon/apply-coupon`, rObj, {
                    headers: {
                        Authorization: `Bearer ${user?.token}`
                    }
                });
                if (data?.statusCode == 200 || data?.statusCode == 201) {
                    setCoupon(data?.data)
                    toast.success("Coupon applied successfully");
                    setCouponValue("");
                }
                else if (data?.statusCode == 400) {
                    toast.error("Invalid coupon code");
                    // setCouponValue("");
                    // setLoading(false)
                }
                else {
                    // setLoading(false);
                    // navigate("/PaymentFailed")

                }
            } catch (error) {
                if (error?.response?.status == 401) {
                    // window.location.href = '/Register';
                    // setLoading(false);
                } else {
                    toast.error(error?.message);
                    // setLoading(false);
                }

            }
        }
    }

    const processPayment = async (selectedPlan) => {

        if (!user) {
            navigate('/login?redirect=/Pricing');
            return;
        }
        setLoading(true)

        const paymentData = {
            // amount: selectedPlan?.amount,
            amount: selectedPlan?.PlanType === 1 ? selectedPlan?.monthly : selectedPlan?.yearly,
            description: selectedPlan?.description,
            email: user?.email, // Replace with the actual email of the user
            subscriptionId: selectedPlan?.id,
            isYearly: selectedPlan?.PlanType === 1 ? false : true,
            coupon: coupon.coupon
        };

        try {
            // Make the POST request to the payment API
            const { data } = await api.post(`/payment/create-order`, paymentData, {
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });
            if (data.message == "Coupon applied successfully.") {
                toast.success("Payment done successfully");
                setLoading(false);
                navigate('/Project')
                return;
            }
            if (data.message == "Invalid coupon code.") {
                toast.error("Invalid coupon code");
                return;
            }
            // Check if the response status code is 200 (OK)
            if (data?.statusCode == 200 || data?.statusCode == 201) {
                // Handle the successful payment scenario here, e.g., show a success message, redirect to a success page, etc.
                window.location.href = data?.data?.approvalUrl;
                setLoading(false);

            }
            else {
                // Handle any other status codes (e.g., 4xx or 5xx) for error scenarios
                Swal.fire({
                    icon: 'error',
                    title: 'Payment Error',
                    text: data?.message,
                });
                setLoading(false);
            }

        }
        catch (error) {

            if (error?.response?.status == 401) {
                localStorage.removeItem("user")
                window.location.href = '/login';
            } else {
                Swal.fire(error?.message);
            }
            setLoading(false)
        }
    }

    return (
        <>

            <section className="container-fluid PageTitle_banner_section">
                <div className="container">
                    <h1 className="PageTitle_banner_heading">Checkout</h1>
                </div>
            </section>


            <section className="container-fluid checkout_section my-4">
                <div className="container">
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) :
                        <div className='row'>
                            <div className='col-xl-7 col-lg-6 col-md-12 col-12 mb-4'>
                                <div className='checkout_Select_plan_area'>
                                    <h3 className='checkout_Select_plan_heading'> {!user?.userSubs ? selectedPlan?.packageName + " " + " + Free Trial" : selectedPlan?.packageName}</h3>
                                    <div className="plans_list">
                                        {selectedPlan?.features?.map((features, index) => (
                                            <p key={index}><i class="fa-solid fa-check pt-1 me-2"></i> {features}</p>))}

                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-5 col-lg-6 col-md-12 col-12'>
                                <div className='checkout_Select_plan_area mb-3'>
                                    <h3 className='checkout_Select_plan_heading'>Coupon</h3>
                                    <div className='checkout_coupon_input_Area'>
                                        <input type='text' className={`form-control ${couponError !== "" ? "error_msg_hp" : ""}`} placeholder='Enter coupon code' onChange={(e) => setCouponValue(e.target.value)} />
                                        <button onClick={handleCoupon}>Apply</button>
                                    </div>
                                    <div className='text-danger mt-1'>{couponError}</div>
                                </div>

                                <div className='checkout_Select_plan_area mb-4'>
                                    <h3 className='checkout_Select_plan_heading'>Order Summery</h3>
                                    <div className='Order_summery_area'>
                                        {!user?.userSubs ? (<> <div>
                                            <p>3 Month Free Trial</p>
                                            <h6> {"INR" + " " + "0"}</h6>
                                            {/* {selectedPlan?.PlanType == 1 ? <h6>{"INR" + " " + selectedPlan?.monthly}</h6> : <h6>{"INR" + " " + selectedPlan?.yearly}</h6>} */}
                                        </div>
                                            <div>
                                                <p>1 Month</p>
                                                <h6> {"INR" + " " + price}</h6>
                                                {/* {selectedPlan?.PlanType == 1 ? <h6>{"INR" + " " + selectedPlan?.monthly}</h6> : <h6>{"INR" + " " + selectedPlan?.yearly}</h6>} */}
                                            </div>
                                            <hr /></>) : ""}
                                        <div>
                                            <p>Subtotal</p>
                                            <h6> {"INR" + " " + price}</h6>
                                            {/* {selectedPlan?.PlanType == 1 ? <h6>{"INR" + " " + selectedPlan?.monthly}</h6> : <h6>{"INR" + " " + selectedPlan?.yearly}</h6>} */}
                                        </div>
                                        <div>
                                            <p>Discount</p>
                                            <h6>{"- INR" + " " + discountValue}</h6>
                                            {/* {selectedPlan?.PlanType == 1 ?  <h6>{ "INR" + " "+ selectedPlan?.monthly * discountAmount}</h6> : <h6>{"- INR" + " " + selectedPlan?.yearly * discountAmount}</h6> } */}
                                        </div>
                                        <hr />
                                        <div>
                                            <p className='fw-bold text-dark'>Grand Total</p>
                                            <h6> {"INR" + " " + GrandTotal}</h6>
                                            {/* {selectedPlan?.PlanType == 1 ? <h6>{"$" + " " + selectedPlan?.monthly - discountValue}</h6> : <h6>{"$" + " " + selectedPlan?.yearly - discountValue}</h6>} */}
                                        </div>
                                    </div>
                                </div>
                                <button className='btn w-100 fw-bold' onClick={() => processPayment(selectedPlan)}>
                                    Payment Now
                                </button>
                            </div>
                        </div>}
                </div>
            </section>

        </>
    )
}

export default Checkout