import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUser , createUser , updateUser ,} from '../../Services/UserServices'
const initialState = {
  user : {},
  status: 'idle',
  error: null,
};

export const fetchUserAsync = createAsyncThunk('/ ', async () => {
  const response = await getUser()
  return response.data;
});
export const createUserAsync = createAsyncThunk('/', async (data) => {
  console.log("reach here with this value of data " , data)
  const response = await createUser(data)
  console.log("response===>" ,response )
  return response.data;
});
export const updateUserAsync = createAsyncThunk('/ ', async () => {
  const response = await updateUser()
  return response.data;
});




const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUserAsync.pending, (state) => {
        console.log("state.status ===>" , state.status )
        state.status = 'loading';
      })
      .addCase(createUserAsync.fulfilled, (state, action) => {
        console.log("state.user ===>" , action.payload )
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(createUserAsync.rejected, (state, action) => {
        console.log("state.error ===>" , action.error.message )
        console.log(action.payload)
        state.status = 'failed';
        state.error = action.error.message;
      })
  },
});

export default userSlice.reducer;
