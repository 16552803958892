import React from "react";
// import Address from "../../assets/Address.png";
// import mail from "../../assets/mail.png";
// import call from "../../assets/call.png";
// import facebook from "../../assets/facebook.png";
// import twitter from "../../assets/twitter.png";
// import google from "../../assets/google.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { redirect } from 'react-router-dom';
// import { useDispatch, useSelector } from "react-redux";
// import { contactUserAsync } from "../../Redux/Reducer/ContactReducer";
// import axios from "axios";
import Swal from "sweetalert2";
// import { API_URL } from "../../Services/UserServices";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "../../Services/ApiService";

const schema = yup.object().shape({
  firstName: yup
    ?.string().trim()
    ?.required("First name required*")
    .min(3, "First name must be longer than or equal to 3 characters")
    .max(30, "First name must be shorter than or equal to 30 characters")
    ?.matches(
      /^[a-zA-Z\s]*$/,
      "First name must not contain symbols or numbers"
    ),
  // lastName: yup
  //   ?.string().trim()
  //   .required("Last name required*")
  //   .min(3, "Last name must be longer than or equal to 3 characters")
  //   .max(50, "Last name must be shorter than or equal to 50 characters")
  //   ?.matches(/^[a-zA-Z\s]*$/, "Last name must not contain symbols or numbers"),
  
  phoneNumber: yup
    .string().trim()
    .required("Phone number required").matches(/^[0-9]+$/, "Phone number must contain only digits")
    .min(8, "Phone number must be at least 8 characters long")
    .max(14, "Phone number must be at most 14 characters long"),


  email: yup
    .string()
    // .email(" ")
    .required("Email required*")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Email must be a valid"
    ),
  // subject: yup
  //   .string().trim().required("Subject required*").min(3, "Subject must be longer than or equal to 3 characters")
  //   .max(150, "Subject must be shorter than or equal to 150 characters")
  // ,

  message: yup?.string()?.required("Message required*").trim().min(3, "Message must be longer than or equal to 3 characters")
    .max(500, "Message must be shorter than or equal to 500 characters"),
});


const Contact = () => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

 
  const onSubmitHandler = async (data) => {
    setLoading(true);

    let conData = {
      firstName: data.firstName,
      // lastName: data.lastName,
      email: data.email,
      mobileNo: data.phoneNumber,
      message: data.message,
    };

    try {

      // Replace 'YOUR_API_ENDPOINT' with the actual endpoint of your server
      const response = await api.post(
        `/contact/create-or-update`,
        conData
      );

      if (response.data.statusCode === 200) {
        const notify = () =>
          toast.success(

            "Contact saved.",
            {
              autoClose: 1000,
            }
          );
        notify();
        reset();
      }

    } catch (error) {
      if(error.message == "Network Error"){
        Swal.fire({
          text: "Please check your internet connection.",
        });
      }else if (error?.response?.status === 400) {
        // Show SweetAlert for email and mobile already existing
        toast.error(error.response.data.message);

      } else {
        // Handle other error responses
        toast.error(error.message == "Network Error" ? "Please check your internet connection.": error.message);

      }
    }
    setLoading(false);
  };


  return (
    <>
      <section className="container-fluid PageTitle_banner_section ">
        <div className="container">
          <h1 className="PageTitle_banner_heading">Contact Us</h1>
        </div>
        <ToastContainer />
      </section>

      <section className="container-fluid contact_info_section_hp my-5">
        <div className="container">
          <div className="contact_info_bg_area">
            <div className="row">
              <div className="col-xl-7 col-lg-7 col-12">
                <div className="contact_info_form_area">
                  <div className="contact_info_form_heading_area">
                    <h5>Send us a message</h5>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 5.5H9c-1.1 0-2 .9-2 2v9a2 2 0 0 0 2 2h13c1.11 0 2-.89 2-2v-9a2 2 0 0 0-2-2m0 11H9V9.17l6.5 3.33L22 9.17zm-6.5-5.69L9 7.5h13zM5 16.5c0 .17.03.33.05.5H1c-.552 0-1-.45-1-1s.448-1 1-1h4zM3 7h2.05c-.02.17-.05.33-.05.5V9H3c-.55 0-1-.45-1-1s.45-1 1-1m-2 5c0-.55.45-1 1-1h3v2H2c-.55 0-1-.45-1-1" /></svg>
                  </div>
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                        <input
                          {...register("firstName")}
                          type="text"
                          className="form-control"
                          placeholder="Enter Name"
                          maxLength="30"
                          onKeyDown={(e) => {
                            const allowedKeys = ['Backspace', 'Tab', 'Enter'];

                             if (!((e.key >= 'a' && e.key <= 'z') || (e.key >= 'A' && e.key <= 'Z') || allowedKeys.includes(e.key))) {
                              e.preventDefault();
                            }
                          }}
                        />
                        <p style={{ color: "red" }}>{errors.firstName?.message}</p>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                        <input
                          {...register("phoneNumber")}
                          type="text"
                          className="form-control"
                          placeholder="Enter Phone Number" 
                          maxLength="14"
                          onKeyDown={(e) => {
                            // const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, and enter
                            if (!allowedKeys.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}                     
                         
                        />
                        <p style={{ color: "red" }}>{errors.phoneNumber?.message}</p>
                      </div>
                      <div className="col-md-12 mb-3">
                        <input
                          {...register("email")}
                          type="text"                 
           
                          className="form-control"
                          placeholder="Enter Email"
                          maxLength="30"
                          onKeyDown={(e) => {
                            const allowedKeys = [
                              "Backspace",
                              "Tab",
                              "Enter",
                             ];
  
                             if (
                              e.target.value.length > 30 && !allowedKeys.includes(e.key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                        <p style={{ color: "red" }}>{errors.email?.message}</p>
                      </div>
                     
                      <div className="col-md-12 mb-3">
                        <textarea
                          {...register("message")}
                          className="form-control form-control-lg"
                          placeholder="Enter Message"
                          rows="3"
                          maxLength="500"

                        ></textarea>
                        <p style={{ color: "red" }}>{errors.message?.message}</p>
                      </div>
                      <div className="text-end">
                        <button className="btn msg_submit_btn">
                          {loading ? (
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          ) : (
                            <i class="fa-regular fa-paper-plane"></i>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-xl-5 col-lg-5 col-12">
                <div className="contact_info_content_Area">
                  <div className="contact_info_heading_area">
                    <h5>Contact Information</h5>
                  </div>
                  <div className="contact_info mt-4">
                    <div className='contact_info_item mb-2'>
                      <i className="fa-solid fa-envelope"></i>
                      <a href="mailto:ductarchitect@microlent.com" className='footerLinks2'>ductarchitect@microlent.com</a>
                    </div>
                    <div className='contact_info_item mb-2'>
                      <i className="fa-solid fa-phone"></i>
                      <a href="tel:9799941913" className='footerLinks2'>+91-9799941913 </a>
                    </div>
                    <div className='contact_info_item mb-2'>
                      <i className="fa-solid fa-location-dot"></i>
                      <a href="https://maps.app.goo.gl/BeW6KENwRpZkHenQ9" target='_Blank' className='footerLinks2'>Manohar 5th Floor CYB -6 RIICO Cyber Park, near Saras Dairy, HI Area Phase II, Jodhpur, Rajasthan 342003</a>
                    </div>
                  </div>
                  <div className="footer_socail_area mt-4">
                    <a href="https://twitter.com/" target="_blank" className='footerLinks'>
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=61557197305875/" target="_blank" className='footerLinks'>
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGBCDAMcox3YQAAAY4Sbv9AC5MOqI6rIldImG_I666Z6HPytTaTvadg4dhYJX_UYW_wT9d6SkpMBVugl9zsFdO-2XpS6YQgVyl949faNPwt7CqOPDh4PQSnPJmG994EN74LMLw=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fductarchitect/" target="_blank" className='footerLinks'>
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                    <a href="https://www.youtube.com/@DuctArchitect/" target="_blank" className='footerLinks'>
                      <i className="fab fa-youtube"></i>
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      {/* <section className="container-fluid contact_info_section py-5 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-12 col-12 mb-4 ">
              <div className="why_choose_us_item row m-0 align-items-start">
                <div className="why_choose_us_item_img col-4">
                  <img src={Address} alt="" />
                </div>
                <div className="why_choose_us_item_content text-start col-8">
                  <h5 className="fw-bold text-muted">Address</h5>
                  <p>Manohar 5th Floor CYB -6 RIICO Cyber Park, near Saras Dairy, HI Area Phase II, Jodhpur, Rajasthan 342003</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-12 mb-4 ">
              <div className="why_choose_us_item align-items-start">
                <div className="why_choose_us_item_img">
                  <img src={mail} alt="" />
                </div>
                <div className="why_choose_us_item_content text-start">
                  <h5 className="fw-bold text-muted">E-mail</h5>
                  <p>admin@microlent.com</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-12 mb-4 ">
              <div className="why_choose_us_item align-items-start">
                <div className="why_choose_us_item_img">
                  <img src={call} alt="" />
                </div>
                <div className="why_choose_us_item_content text-start">
                  <h5 className="fw-bold text-muted">Phone Number </h5>
                  <p>+91-9799941913</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}



      {/* <section className="container-fluid">
        <div className="row">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7157.406093584441!2d73.01710454171354!3d26.238836658220727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39418b54195f2839%3A0xb3633326d8efe16f!2sMANISHA%20MEHANDI%20ART!5e0!3m2!1sen!2sin!4v1688728656125!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowfullscreen
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title=" "
          />
        </div>
      </section> */}
    </>
  );
};

export default Contact;
