import React from 'react'
import verify_email from '../../assets/Expired.svg';


const handleButton = () => {
    window.location.href = "/Pricing";

}

function PlanExpried() {
    return (
        <>

            <section className="container-fluid PageTitle_banner_section">
                <div className="container">
                    <h1 className="PageTitle_banner_heading">Plan Expired</h1>
                </div>
            </section>


            <section className="container-fluid Email_verify_section my-5">
                <div className="container">
                    <div className='mx-auto text-center'>
                        <img src={verify_email} />
                    </div>
                    <div className='row'>
                        <div className='col-xl-6 col-lg-6 col-md-11 col-12 m-auto'>
                            <p className='text-center'>
                                To continue using Duct Architect, You will need to upgrade your plan to essential.
                            </p>
                        </div>
                    </div>
                    <div className='text-center'>
                        <button onClick={handleButton} className='mt-3'>Subscribe Now </button>
                    </div>
                </div>
            </section>

        </>
    )
}

export default PlanExpried