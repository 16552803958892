import React from 'react'

const SolarGain = ({ register, errors }) => {

    return (
        <div className='space_input_detail_area mt-4 mb-4'>
            <h4 className='space_input_detail_heading mb-3'>Solar Gain Glass</h4>

            <div className='row'>
                <div className='col-xl-4 col-lg-6 col-md-6 col-12'>
                    <div className='space_input_detail_header_area Space_detai_header1'>
                        <div>Item</div>
                        <div>Area <samll>(Sq ft)</samll></div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>N - Glass</div>
                        <div>
                            <input type="text" id="input" class="form-control" min="0"

                                onKeyDown={(e) => {
                                    const inputValue = e.target.value;
                                    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                                    // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                                    if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                        e.preventDefault();
                                    }
                                }}   {...register("nGlass")} /> <p style={{ color: "red" }}>{errors.nGlass?.message}</p>
                        </div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>NE - Glass</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }} {...register("neGlass")} /><p style={{ color: "red" }}>{errors.neGlass?.message}</p> </div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>E - Glass</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }} {...register("eGlass")} /> <p style={{ color: "red" }}>{errors.eGlass?.message}</p> </div>
                    </div>


                </div>
                <div className='col-xl-4 col-lg-6 col-md-6 col-12'>
                    <div className='space_input_detail_header_area Space_detai_header2'>
                        <div>Item</div>
                        <div>Area <samll>(Sq ft)</samll></div>
                    </div>

                    <div className='space_input_detail_data_area'>
                        <div>SE - Glass</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }}  {...register("seGlass")} /> <p style={{ color: "red" }}>{errors.seGlass?.message}</p></div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>S - Glass</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }} {...register("sGlass")} /> <p style={{ color: "red" }}>{errors.sGlass?.message}</p> </div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>SW - Glass</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }} {...register("swGlass")} /> <p style={{ color: "red" }}>{errors.swGlass?.message}</p></div>
                    </div>


                </div>
                <div className='col-xl-4 col-lg-6 col-md-6 col-12'>
                    <div className='space_input_detail_header_area Space_detai_header3'>
                        <div>Item</div>
                        <div>Area <samll>(Sq ft)</samll></div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>W - Glass</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }}  {...register("wGlass")} /><p style={{ color: "red" }}>{errors.wGlass?.message}</p> </div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>NW - Glass</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }} {...register("nwGlass")} /> <p style={{ color: "red" }}>{errors.nwGlass?.message}</p></div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>Sky light</div>
                        <div> <input type="text" id="input" class="form-control" min="0" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }} {...register("skyLight")} /> <p style={{ color: "red" }}>{errors.skyLight?.message}</p></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SolarGain;