import './App.css'
import Header from './components/common/Header';
import Home from './components/Pages/Home'
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import About from './components/Pages/About';
import Footer from './components/common/Footer';
import Contact from './components/Pages/Contact'
import Pricing from './components/Pages/Pricing';
import Calculator from './components/Pages/Calculator';
import Signup from './components/Pages/signup';
import ResetPassword from './components/Pages/ResetPassword';
import { AuthProvider, useAuth } from './provider/AuthProvider';
import ForgetPassword from './components/Pages/ForgetPassword';
import OtpConfirm from './components/OtpPage';
import NotFound from './components/Pages/NotFound';
import MobileNumber from './components/Pages/MobileNumber';
import AddMachineForm from './components/Pages/AddMachineForm';
import MachineList from './components/Pages/MachineList';
import Checkout from './components/Pages/Checkout';

import PaymentSuccessMessage from './components/Pages/PaymentSuccessMessage';
import PaymentFailedMessage from './components/Pages/PaymentFailed';
import Product from './components/Pages/Product';
import Register from './components/Register';

import SpaceProjectList from './components/Pages/SpaceProjectList';
import SpaceProjectDetail from './components/Pages/SpaceProjectDetail';
import app from './firebase'; // use for login 


import Terms_condition from './components/Pages/Terms_condition';
import PlanExpried from './components/Pages/PlanExpried';
import FreeCalculator from './components/Pages/FreeCalculator';
// import { GoogleOAuthProvider } from '@react-oauth/google';
import ScrollToTop from './components/common/ScrollToTop';
// import HVAC from './components/Pages/HVAC';
import Profile from './components/Pages/Profile'
import EmailVerification from './components/Pages/emailVerification';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect, useState } from 'react';

const HideableHeaderFooter = ({ children }) => {
  const location = useLocation();

  const isRegisterPage = location.pathname === '/Register' || location.pathname === '/login' || location.pathname === '/ForgotPassword' || location.pathname === '/OtpVerification' || location.pathname === '/ResetPassword' || location.pathname === '/MobileNumber';
  return (
    <>
      {!isRegisterPage && <Header />}
      {children}
      {!isRegisterPage && <Footer />}
    </>
  );
};

const App = () => {

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      toast.success("Internet connection is available");
    };
    const handleOffline = () => {
      setIsOnline(false);
      toast.error("Please check your internet connection.");
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  return (
    <div>
      {/* {isOnline ? (
        <p>You are online!</p>
      ) : (
        <p>You are offline. Please check your network connection.</p>
      )} */}
      <ToastContainer closeButton={false} />
      <AuthProvider>
        <Router>
          <ScrollToTop />
          <HideableHeaderFooter>
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route path='/PaymentSuccess' element={<PaymentSuccessMessage />} />
              <Route path='/PaymentFailed' element={<PaymentFailedMessage />} />
              <Route path='/About' element={<About />} />
              <Route path='/Calculator' element={<Calculator />} />
              <Route path='/FreeCalculator' element={<FreeCalculator/>} />
              <Route path='/Project' element={<Product />} />
              <Route path='/SpaceProjectsList/:productId' element={<SpaceProjectList />} />
              <Route path='/SpaceProjectDetail/:spaceId' element={<SpaceProjectDetail />} />
              <Route path='/Profile' element={<Profile />} />
              <Route path='/verify-email' element={<EmailVerification />} />
              <Route path='/Contact' element={<Contact />} />
              <Route path='/MobileNumber' element={<MobileNumber />} />
              <Route path='/AddMachineForm' element={<AddMachineForm />} />
              <Route path='/Checkout' element={<Checkout />} />
              <Route path='/MachineList' element={<MachineList />} />
              <Route path='/Pricing' element={<Pricing />} />
              <Route path='/login' element={<Signup />} />
              <Route path='/Register' element={<Register />} />
              <Route path='/ForgotPassword' element={<ForgetPassword />} />
              <Route path='/ResetPassword' element={<ResetPassword />} />
              <Route path='/OtpVerification' element={<OtpConfirm />} />
              <Route path='/terms&Condition' element={<Terms_condition />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </HideableHeaderFooter>
        </Router>
      </AuthProvider>

    </div>
  );
}

const ProtectedFreeCalculator = () => {
  const { user } = useAuth();
  const LoginUser = user;

  // Check if user is paid and render Calculator instead of FreeCalculator
  if (LoginUser?.paid == "paid" && LoginUser?.userSubs) {
    return <Calculator />;
  } else if (LoginUser?.paid == "unpaid" && LoginUser?.userSubs) {
    return <PlanExpried />;
  }
  // Render FreeCalculator if user is not paid
  return <FreeCalculator />;
}

export default App;
