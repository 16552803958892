import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
// import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import { API_URL } from '../../Services/UserServices';
// import axios from 'axios';
import { useAuth } from '../../provider/AuthProvider';
import api from '../../Services/ApiService';


const PaymentFailedMessage = () => {

  const {user} = useAuth()
  // const navigate = useNavigate();
   
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
      const paymentId = queryParams.get('paymentId');
  
      // Function expression for handling the API call
      const handlePaymentStatus = async (paymentId) => {
        try {
            const response = await api.get(`/payment/cancel` ,  
          {headers:{
            Authorization:`Bearer ${user?.token}`
        }});
          if (response.data.statusCode === 200) {
            // Show the payment failed message using SweetAlert
            Swal.fire({
              icon: 'error',
              title: 'Payment failed!',
              text: response.data.message,
              customClass: {
                popup: 'not-found-container', // Custom CSS class for centering the alert
              },
            });
          } else {
            // Handle other payment statuses if necessary
            // ...
            Swal.fire({
              icon: 'error',
              title: 'Payment failed!',
              text: response.data.message,
            });
          }
        } catch (error) {
          if(error?.response?.status == 401){
            localStorage.removeItem("user")
            window.location.href = '/Register';
          }
          // Handle API call errors
     
        }
      };
  

    useEffect(() => { // Call the function expression
      handlePaymentStatus(paymentId);
    }, [location.search]);

  return    <div className="not-found-container" >
  <h1>  Oops.. </h1>
 <p>Payment failed </p>
 <Link   to="/"  style={{textDecoration:"none"}} >  go to home page </Link>
</div>;
};

export default PaymentFailedMessage;
