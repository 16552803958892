import api from "./ApiService";
export const API_URL = "https://ductarchitect.com/api";

export const createUser = async (data) => {
  return await api.post(`/user/signup`, data);
};

export const getUser = async () => {
  return api.get(`/user/get-All`);
};

export const updateUser = async () => {
  return await api.post(`/user/Update-user`);
};
export const LoginUser = async (data) => {
  return api.post(`/user/login`, data);
};
export const contactUser = async (data) => {
  return api.post(`/user/contact/create-or-update`, data);
};
