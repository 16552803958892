import React from 'react'

function Terms_condition() {
    return (
        <div>
            <section className="container-fluid PageTitle_banner_section">
                <div className="container">
                    <h1 className="PageTitle_banner_heading">Terms & Condition</h1>
                </div>
            </section>

            <section className='container-fluid my-5 terms_Condition_section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <p>
                                <strong>Welcome to DuctArchitect!</strong> These Terms and Conditions ("Terms") govern your use of the DuctArchitect software (the "Software") and related services (the "Services") offered by Microlent Systems ("we," "us," or "our").
                            </p>
                            <p>
                                <strong> Please read these Terms carefully before using the Software or Services.</strong> By accessing or using the Software or Services, you agree to be bound by these Terms. If you do not agree to all of the Terms, you are not authorized to use the Software or Services
                            </p>

                            <div className='my-4'>
                                <h4>1. Services</h4>
                                <p>
                                    The Services include the Software, any updates, upgrades, or new features added to the Software, and any documentation or other materials provided to you in connection with the Software. We may modify or discontinue the Services, in whole or in part, at any time, with or without notice.
                                </p>
                            </div>
                            <div className='my-4'>
                                <h4>2. Payment and Fees</h4>
                                <p>
                                    The Software is offered on a subscription basis. You agree to pay the applicable fees for the Software as set forth on our website or in your subscription agreement.  Payment is due in advance for the subscription period.
                                </p>
                            </div>

                            <div className='my-4'>
                                <h4>3. Non-Refundable Payments</h4>
                                <p>
                                    Due to the nature of the digital product and the immediate access granted upon purchase, <strong>all fees for the Software and Services are non-refundable.</strong>
                                </p>
                            </div>
                            <div className='my-4'>
                                <h4>4. Free Trial</h4>
                                <p>
                                    We may offer a free trial of the Software for a limited time. You may be required to provide a credit card or other payment information to activate the free trial. However, you will not be charged during the free trial period unless you choose to upgrade to a paid subscription. If you do not cancel your subscription before the end of the free trial period, you will be automatically charged for the chosen subscription plan.
                                </p>
                            </div>
                            <div className='my-4'>
                                <h4>5. Term and Termination</h4>
                                <p>
                                    These Terms will remain in effect until terminated by you or us. You may terminate these Terms by discontinuing your use of the Software and Services. We may terminate these Terms at any time for any reason, with or without notice. Upon termination of these Terms, your right to use the Software and Services will cease immediately.
                                </p>
                            </div>
                            <div className='my-4'>
                                <h4>6. Intellectual Property</h4>
                                <p>
                                    The Software and Services are protected by copyright, trademark, and other intellectual property laws. We own all right, title, and interest in and to the Software and Services. You may not modify, reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code of the Software.
                                </p>
                            </div>
                            <div className='my-4'>
                                <h4>7. Disclaimer of Warranties</h4>
                                <p>
                                    The software and services are provided "As is" and "As available" without warranty of any kind, express or implied, including but not limited to the warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that the software or services will function uninterrupted, error-free, or virus-free. We do not warrant that the software or services will meet your requirements.
                                </p>
                            </div>
                            <div className='my-4'>
                                <h4>8. Limitation of Liability</h4>
                                <p>
                                    In no event shall we be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in any way connected with your use of the software or services, even if we have been advised of the possibility of such damages.
                                </p>
                            </div>

                            <div className='my-4'>
                                <h4>9. Governing Law</h4>
                                <p>
                                    These Terms shall be governed by and construed in accordance with the laws of the State of Rajasthan, without regard to its conflict of law principles.
                                </p>
                            </div>
                            <div className='my-4'>
                                <h4>10. Entire Agreement</h4>
                                <p>
                                    These Terms constitute the entire agreement between you and us with respect to the subject matter hereof and supersede all prior or contemporaneous communications, representations, or agreements, whether oral or written.
                                </p>
                            </div>
                            <div className='my-4'>
                                <h4>11. Severability</h4>
                                <p>
                                    If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall remain in full force and effect.
                                </p>
                            </div>
                            <div className='my-4'>
                                <h4>12. Waiver</h4>
                                <p>
                                    No waiver of any provision of these Terms shall be deemed a further or continuing waiver of such provision or any other provision.
                                </p>
                            </div>
                            <div className='my-4'>
                                <h4>13. Contact Us</h4>
                                <p>
                                    If you have any questions about these Terms, please contact us at <a href='mailto:ductarchitect@microlent.com'>ductarchitect@microlent.com</a>
                                </p>
                            </div>




                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Terms_condition