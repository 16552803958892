import React from 'react'

const InternalHeat = ({ register, errors }) => {
    return (

        <div className='space_input_detail_area mb-4'>
            <h4 className='space_input_detail_heading mb-3'>Internal Heat</h4>

            <div className='row'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                    <div className='space_input_detail_header_area Space_detai_header1'>
                        <div>Item</div>
                        <div>Area <samll>(Sq ft)</samll></div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>Sensible Heat</div>
                        <div> 
                            <input type="text" id="input" class="form-control" min="0" defaultValue="245" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }}  {...register("sensibleHeat")} /> <p style={{ color: "red" }}>{errors.sensibleHeat?.message}</p></div>
                    </div>                    
                </div>

                 <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                    <div className='space_input_detail_header_area Space_detai_header2'>
                        <div>Item</div>
                        <div>Area <samll>(Sq ft)</samll></div>
                    </div>
                    <div className='space_input_detail_data_area'>
                        <div>Latent Heat</div>
                        <div> <input type="text" id="input" class="form-control" min="0" defaultValue="205" onKeyDown={(e) => {
                            const inputValue = e.target.value;
                            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Tab', 'Enter'];

                            // Allow only numeric characters, backspace, tab, enter, and a single decimal point
                            if (!allowedKeys.includes(e.key) || (e.key === '.' && inputValue.includes('.'))) {
                                e.preventDefault();
                            }
                        }}  {...register("latentHeat")} />  <p style={{ color: "red" }}>{errors.latentHeat?.message}</p></div>
                    </div>
                </div>
 
                 
            </div>

        </div>

    )
}

export default InternalHeat