import React, { useState } from "react";
import Sign_Up from "../../../src/assets/logo.svg";
import { ToastContainer, toast } from "react-toastify";

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import { API_URL } from "../../Services/UserServices";
// import axios from "axios";
import { useAuth } from "../../provider/AuthProvider";
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { useNavigate } from "react-router";
import api from "../../Services/ApiService";


function MobileNumber() {

    const { user } = useAuth()
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


  

    const schema = yup.object().shape({
        phoneNumber: yup.string().trim().required('Phone number is required').matches(/^\d{8,14}$/, 'Phone number must be between 8 to 14 digits')
      });
      
    
        const { register, handleSubmit,reset, formState: { errors} } = useForm({
          resolver: yupResolver(schema)
        });

        const onSubmit = async(data) => {
         setLoading(true)
      const rObj={

        id: user?.id,
        mobileNo:data?.phoneNumber
      }
          try {
            const response = await api.post(
              `/user/Update-user`,rObj
            );
      
      
            if (response?.data?.statusCode === 200) {      
              const notify = () =>
                toast.success(
                  " Mobile No. updated successfully",
                  {
                    autoClose: 1000,
                  }
                );
              notify();
              reset();

            setTimeout(() => {
                navigate("/");
              }, 2000);
      
            }

            if(response.data.statusCode == 400){
                const notify = () =>{
                  toast.error(response?.data?.message)
                }
                
                notify();

            }
            setLoading(false)
          } catch (error) {
      
            toast.error(error?.response?.data?.message)
          }
         setLoading(false)
        };


        







    return (
        <>
 {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress />
          </Backdrop>
        </Box>
      )
      }



            <div className="container-fluid bg-login_page bg_Register_page bg_ForgetPassword_page">
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-md-12 col-lg-7 col-xl-6">
                            <div className="card card_custom_card">
                                <div className="card-body card_custom">
                                    <div className="text-center">
                                        <a href="/">
                                            <img src={Sign_Up} className="img-fluid logo_img" alt="" />
                                        </a>
                                    </div>
                                    <div className="login_heading_Area">
                                        <h3 className="fw-bold text-center my-2">Update Mobile Number</h3>
                                        <p className="bottom-nav_headeing">
                                            Enter your Mobile Number
                                        </p>
                                    </div>
                                    <form  onSubmit={handleSubmit(onSubmit)} >
                                        <div className="col-md-12 mb-3">
                                            <input
                                                type="text"
                                                className="form-control form-control_custom"
                                                id="phoneNumber"
                                                maxLength="14"
                                                placeholder="Ex.-98XXXXXXXX"
                                                {...register("phoneNumber")}
                                                onKeyDown={(e) => {
                                                  // const inputValue = e.target.value;
                                                  const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab', 'Enter'];
                      
                                                  // Allow only numeric characters, backspace, tab, and enter
                                                  if (!allowedKeys.includes(e.key)) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                            />
                        <span className="font-14 ms-2" style={{ color: "red" }}>
                          {errors?.phoneNumber?.message}
                        </span>
                                        </div>
                                        <button className="btn Sign_btn_submit w-100" type="submit">
                                            Update
                                        </button>

                                        {/* <div className="text-center mt-3">
                                            <a href="/login">Back to login</a>
                                        </div> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />

                </div>
            </div >
        </>
    )
}

export default MobileNumber