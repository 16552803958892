import React, { useEffect, useState } from "react";
import Sign_Up from "../../assets/Sign_Up.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ForgetPassword from "./ForgetPassword";
import { useDispatch, useSelector } from "react-redux";
import { createUserAsync } from "../../Redux/Reducer/UserReducer";
import axios from "axios";
import { LoginUser } from "../../Services/UserServices";
import { useNavigate , useLocation } from "react-router-dom";
// import { loginUserAsync  } from "../../Redux/Reducer/signInReducer";
import Swal from "sweetalert2"; // Import SweetAlert
import { useAuth } from "../../provider/AuthProvider";
import Login from "../Login";
import Register from "../Register";


// const schemaSignIn = yup.object().shape({
//   email: yup.string().email().required(),
//   password: yup.string().required("Password is required"),
// });

function Signup() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      return (window.location.href = "/");
    }
  }, []);

  const location = useLocation();
  const { pathname, search } = location;

  useEffect(() => {
    const params = new URLSearchParams(search);
    const activeTabParam = params.get("activeTab");
    setActiveTab(activeTabParam || "signIn");
  }, [search]);

  const [activeTab, setActiveTab] = useState("signIn");

  

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    // resetForm();
  };

  const resetForm = () => {
    // resetSignUp();
    // resetSignIn();
  };

  return (
    <div>
      <div>
      <Login activeTab={activeTab}/>

       
      </div>
    </div>
  );
}

export default Signup;
