import React from 'react'

function HvacWinterPdf({inputData,user} ) {

    const date = new Date();


    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`;

    return `
    <div>
      <div
        style="width: 100%; margin: auto; font-family:  Montserrat , sans-serif;"
       
      >
        <div style="background-color: #0080e913; padding: 30px 20px; border-radius: 10px; margin: 20px 0;">
          <h2 style="text-align: center; font-size: 25px; font-weight: 700;">
            Heat Load Calculation for Prakash Ji Lodha
          </h2>
          <div style="display: flex; flex-wrap: wrap; align-items: start; justify-content: space-between;width: 100%;">
            <div style="margin: 10px; width: 30%;">
              <label style="font-size: 14px;">Job Name</label>
              <p style="font-size: 16px;font-weight: 600; margin: 5px 0 0;margin-left: 5px;">
              ${inputData?.product?.productName}
              </p>
            </div>

            <div style="margin: 10px; width: 30%;">
              <label style="font-size: 14px;">Flat</label>
              <p style="font-size: 16px;font-weight: 600; margin: 5px 0 0;margin-left: 5px;">
               
              </p>
            </div>
            <div style="margin: 10px; width: 30%;">
              <label style="font-size: 14px;">DATE</label>
              <p style="font-size: 16px;font-weight: 600; margin: 5px 0 0;margin-left: 5px;">
               ${formattedDate}
              </p>
            </div>
            <div style="margin: 10px; width: 30%;">
              <label style="font-size: 14px;">Rev</label>
              <p style="font-size: 16px;font-weight: 600; margin: 5px 0 0;margin-left: 5px;">
                00
              </p>
            </div>

            <div style="margin: 10px; width: 30%;">
              <label style="font-size: 14px;">Space For</label>
              <p style="font-size: 16px;font-weight: 600; margin: 5px 0 0;margin-left: 5px;">
              ${inputData?.space_name}
              </p>
            </div>

            <div style="margin: 10px; width: 30%;">
              <label style="font-size: 14px;">Estimated By</label>
              <p style="font-size: 16px;font-weight: 600; margin: 5px 0 0;margin-left: 5px;">
              ${user?.name}
              </p>
            </div>

            <div style="margin: 10px; width: 30%;">
              <label style="font-size: 14px;">Size (Sq ft)</label>
              <p style="font-size: 16px;font-weight: 600; margin: 5px 0 0;margin-left: 5px;">
              ${inputData?.size_sqrft}
              </p>
            </div>

            <div style="margin: 10px; width: 30%;">
              <label style="font-size: 14px;">Size (Cu ft)</label>
              <p style="font-size: 16px;font-weight: 600; margin: 5px 0 0;margin-left: 5px;">
              ${inputData?.size_cuft}
              </p>
            </div>
            <div style="margin: 10px; width: 30%;">
              <label style="font-size: 14px;">Estimate for</label>
              <p style="font-size: 16px;font-weight: 600; margin: 5px 0 0;margin-left: 5px;">
                Winter
              </p>
            </div>
          </div>
        </div>














        <div style="display: flex; flex-wrap: wrap; width: 100%;">
          <div style="width: 48%; padding: 0 10px; float:left; ">

            <div style="margin-bottom: 15px; width:100%;">
              <h3 style="font-size: 15px;font-weight:800; margin-bottom: 10px;">
                Solar Gain Glass
              </h3>
              <table style="border: 1px solid #0000001f; border-radius: 10px;width: 100%;table-layout:fixed; overflow:hidden;">
                <thead style="background-color: #ECF6FD;">
                  <tr>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Item</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Area</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Sun Gain</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Factor</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Heat Gain</span></th>
                  </tr>
                  <tr>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;"></span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">(Sq ft)</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">(Btu/h.sq ft)</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;"></span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;"></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">N - Glass</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_input?.nGlass}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">4</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">0.56</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;"> ${inputData?.space_output?.winterCal?.solarGainResultWinter?.solarGainNglassHeatResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">NE - Glass</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_input?.neGlass}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">4</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">0.56</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${ inputData?.space_output?.winterCal?.solarGainResultWinter?.solarGainNEglassHeatResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">E - Glass</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_input?.eGlass}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">4</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">0.56</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${ inputData?.space_output?.winterCal?.solarGainResultWinter?.solarGainEglassHeatResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">SE - Glass</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_input?.seGlass}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">4</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">0.56</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${ inputData?.space_output?.winterCal?.solarGainResultWinter?.solarGainSEglassHeatResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">S - Glass</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_input?.sGlass}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">64</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">0.56</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${ inputData?.space_output?.winterCal?.solarGainResultWinter?.solarGainSglassHeatResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">SW - Glass</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_input?.swGlass}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">114</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">0.56</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_output?.winterCal?.solarGainResultWinter?.solarGainSWglassHeatResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">W - Glass</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_input?.wGlass}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">92</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">0.56</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_output?.winterCal?.solarGainResultWinter?.solarGainWglassHeatResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">NW - Glass</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_input?.nwGlass}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">10</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">0.56</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_output?.winterCal?.solarGainResultWinter?.solarGainNWlassHeatResultWinter}</span></td>
                  </tr>

                  <tr>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">Sky light</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_input?.skyLight}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;"></span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;"></span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_output?.winterCal?.solarGainResultWinter?.solarGainSkyLightWinter}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style="margin-bottom: 15px;">
              <h3 style="font-size: 15px;font-weight:800; margin-bottom: 10px;">
                Solar & Trans. Gain Walls & Roof
              </h3>
              <table style="border: 1px solid #0000001f; border-radius: 10px;width: 100%;table-layout:fixed; overflow:hidden;">
                <thead style="background-color: #ECF6FD;">
                  <tr>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Item</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Area</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Eq. temp. diff.</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">U</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Heat Gain</span></th>
                  </tr>
                  <tr>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;"></span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">(Sq ft)</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">(°F)</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">(Btu/h.sq ft)</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;"></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">N - Wall</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_input?.nTransGainWallGlass}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${Math.round (inputData?.space_output?.winterCal?.solarTransGainWallNglassHeatTempWinter)}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">0.36</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${ inputData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallNglassHeatResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">NE - Wall </span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_input?.neTransGainWallGlass}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${Math.round(inputData?.space_output?.winterCal?.solarTransGainWallNEglassHeatTempWinter)}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">0.36</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallNEglassHeatResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">E - Wall</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_input?.eTransGainWallGlass}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${Math.round( inputData?.space_output?.winterCal?.solarTransGainWallEglassHeatTempWinter)}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;"> 0.36</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${ inputData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallEglassHeatResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">SE - Wall</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_input?.seTransGainWallGlass}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${Math.round(inputData?.space_output?.winterCal?.solarTransGainWallSEglassHeatTempWinter)}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">0.36</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${ inputData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallSEglassHeatResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">S - Wall</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_input?.sTransGainWallGlass}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${Math.round( inputData?.space_output?.winterCal?.solarTransGainWallSglassHeatTempWinter)}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">0.36</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${ inputData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallSglassHeatResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">SW - Wall</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_input?.swTransGainWallGlass}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${Math.round( inputData?.space_output?.winterCal?.solarTransGainWallSwglassHeatTempWinter)}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">0.36</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallSWglassHeatResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">W - Wall</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_input?.wTransGainWallGlass}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${Math.round(inputData?.space_output?.winterCal?.solarTransGainWallWglassHeatTempWinter)}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">0.36</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallWglassHeatResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">NW - Wall</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${inputData?.space_input?.nwTransGainWallGlass}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">${Math.round(inputData?.space_output?.winterCal?.solarTransGainWallNWglassHeatTempWinter)}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">0.36</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallNWlassHeatResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Roof Sun</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.roofSun}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${Math.round( inputData?.space_output?.winterCal?.solarTransGainWallRoofSunTempWinter)}</span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;">0.55</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${  inputData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallRoofSunResultWinter}</span></td>
                  </tr>
                  <tr>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Roof Shaded</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.roofShaded}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${Math.round( inputData?.space_output?.winterCal?.solarTransGainWallRoofShadedResultWinter)}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">0.36</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${ inputData?.space_output?.winterCal?.solarTransGainWallresultWinter?.solarTransGainWallRoofShadedResultWinter}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style="margin-bottom: 15px;">
              <h3 style="font-size: 15px;font-weight:800; margin-bottom: 10px;">
                Trans. Gain Except Walls & Roof
              </h3>
              <table style="border: 1px solid #0000001f; border-radius: 10px;width: 100%;table-layout:fixed; overflow:hidden;">
                <thead style="background-color: #ECF6FD;">
                  <tr>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Item</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Area</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Temp. diff.</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">U</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Heat Gain</span></th>
                  </tr>
                  <tr>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;"></span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">(Sq ft)</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">(°F)</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">(Btu/h.sq ft)</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;"></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;"> All Glass</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.totalSolarGain}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${Math.round( inputData?.space_output?.winterCal?.transGainExceptWallAllGlassTempDiffWinter)}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">1.13</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${ inputData?.space_output?.winterCal?.transGainExceptWallGlassHeatGainResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Partition</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.partition}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${Math.round(inputData?.space_output?.winterCal?.transGainExceptWallPartitionTempDiffWinter)}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">0.32</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${ inputData?.space_output?.winterCal?.transGainExceptPartitonHeatGainResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Ceiling</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.size_sqrft}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${Math.round(inputData?.space_output?.winterCal?.transGainExceptCeilingHeatGainTemp)}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">0.32</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${  inputData?.space_output?.winterCal?.transGainExceptCeilingHeatGainResultWinter}</span></td>
                  </tr>
                  <tr>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Floor</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.size_sqrft}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${Math.round( inputData?.space_output?.winterCal?.transGainExceptFloorHeatGainTemp)}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">0.38</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${ inputData?.space_output?.winterCal?.transGainExceptFloorHeatGainResultWinter }</span></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style="margin-bottom: 15px;">
              <h3 style="font-size: 15px;font-weight:800; margin-bottom: 10px;">
                Internal Heat Gain
              </h3>
              <table style="border: 1px solid #0000001f; border-radius: 10px;width: 100%;table-layout:fixed; overflow:hidden;">
                <tbody>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">People</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.occupancy}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Nos X</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">245</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.internalHeatGainPeopleBtuResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Light</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.lightInternalHeatValueWinter}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">W X 1.25</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">3.41</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.internalHeatGainLightBtuResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Eq. Load</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.internalHeatGainEquLoadBtuValueWinter}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">W X 1.25</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">3.41</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.internalHeatGainEqLoadBtuResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;"></span></td>
                    <th colspan="4"><span style="text-align:Center;font-size:12px;white-space:break-spaces;width:auto;padding:0 0 0 5px;margin:0">ROOM SENSIBLE HEAT (RSH)</span></th>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.roomSensibleHeatRSHWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Supply duct Heat Gain +</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Supply duct leak. loss +</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Heat Gain from fan HP(%)</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">3</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.supplyDuctHeatGainSupplyDuctLeakLossHeatGainFanHP}</span></td>
                  <tr>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;"></span></td>
                    <td><span style="text-align: Center;font-size: 10px;white-space: break-spaces;width: auto;padding: 0;margin:0;"></span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Safety factor (%)</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">8.5</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.SafetyFactorWinter}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style="margin-bottom: 15px;">
              <h3 style="font-size: 15px;font-weight:800; margin-bottom: 10px;">Outside Air</h3>
              <table style="border: 1px solid #0000001f; border-radius: 10px;width: 100%;table-layout:fixed; overflow:hidden;">
                <thead style="background-color: #ECF6FD;">
                  <tr>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">CFM</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">°F</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">BF</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">FACTOR</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Heat Gain</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">People</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${Math.round(inputData?.space_output?.winterCal?.conditionDifference)}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">0.15</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">1.08</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.outsideAirHeatGainResultWinter}</span></td>
                  </tr>
                  <tr>
                    <th colspan="4"><span style="text-align:Center;font-size:12px;white-space:break-spaces;width:auto;padding:0 0 0 5px;margin:0">EFFECTIVE ROOM SENSIBLE HEAT (ERSH)</span></th>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.ershResultWinter}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style="margin-bottom: 15px;">
              <h3 style="font-size: 15px;font-weight:800; margin-bottom: 10px;">Latent Heat</h3>
              <table style="border: 1px solid #0000001f; border-radius: 10px;width: 100%;table-layout:fixed; overflow:hidden;">
                <tbody>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">People</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.occupancy}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Nos X</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">205</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0"> ${inputData?.space_output?.winterCal?.roomLatentHeatRlhWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0"></span></td>
                    <th colspan="3"><span style="text-align:Center;font-size:12px;white-space:break-spaces;width:auto;padding:0 0 0 5px;margin:0">ROOM LATENT HEAT (RLH)</span></th>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.roomLatentHeatRlhWinter}</span></td>
                  </tr>
                  <tr>
                    <td colspan="2"><span style="text-align:Center;font-size:12px;white-space:break-spaces;width:auto;padding:0 0 0 5px;margin:0">Supply duct leakage loss +</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Safety factor %</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">12.5</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.suppluDuctLeakageFactor}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style=" margin-bottom: 15px;">
              <h3 style="font-size: 15px;font-weight:800; margin-bottom: 10px;">Outside Air</h3>
              <table style="border: 1px solid #0000001f; border-radius: 10px;width: 100%;table-layout:fixed; overflow:hidden;">
                <thead style="background-color: #ECF6FD;">
                  <tr>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">CFM</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">GR / LB</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">BF</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">FACTOR</span></th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Heat Gain</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.maxResultWinter}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.condtionOutSideRoomDifferenceWinter}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">0.15</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">0.68</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.outSideAirResultWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <th colspan="4"><span style="text-align:Center;font-size:12px;white-space:break-spaces;width:auto;padding:0 0 0 5px;margin:0">EFFECTIVE ROOM LATENT HEAT (ERLH)</span></th>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.effectiveRoomLatentHeatErlhWinter}</span></td>
                  </tr>
                  <tr>
                    <th colspan="4"><span style="text-align:Center;font-size:12px;white-space:break-spaces;width:auto;padding:0 0 0 5px;margin:0">EFFECTIVE ROOM TOTAL HEAT (ERTH)</span></th>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${ inputData?.space_output?.winterCal?.effectiveRoomTotalHeatErth}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style="margin-bottom: 15px;">
              <h3 style="font-size: 15px;font-weight:800; margin-bottom: 10px;">
                Outside Air Heat (Sensible)
              </h3>
              <table style="border: 1px solid #0000001f; border-radius: 10px;width: 100%;table-layout:fixed; overflow:hidden;">
                <thead style="background-color: #ECF6FD;">
                  <tr>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">CFM</th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">°F</th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">1 - BF</th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">FACTOR</th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Heat Gain</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.maxResultWinter}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${Math.round(inputData?.space_output?.winterCal?.conditionDifference)}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">0.85</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">1.08</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.outSideAirHeatSensibleResult}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style="margin-bottom: 15px;">
              <h3 style="font-size: 15px;font-weight:800; margin-bottom: 10px;">
                Outside Air Heat (Latent)
              </h3>
              <table style="border: 1px solid #0000001f; border-radius: 10px;width: 100%;table-layout:fixed; overflow:hidden;">
                <thead style="background-color: #ECF6FD;">
                  <tr>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">CFM</th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">GR / LB</th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">1 - BF</th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">FACTOR</th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Heat Gain</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.maxResultWinter}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.condtionOutSideRoomDifferenceWinter}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">0.85</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">0.68</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.outSideAirHeatLatentResult}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <th colspan="4"><span style="text-align:Center;font-size:12px;white-space:break-spaces;width:auto;padding:0 0 0 5px;margin:0">HEAT SUB TOTAL</span></th>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.outsideLatentHeatSubTotal}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Return duct Heat Gain & leak. loss +</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">HP Pump +</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Dehum. & Pipe loss (%)</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">3.0</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.ductHeatGainLeakLossDehumPipe}</span></td>
                  </tr>
                  <tr>
                    <th><span style="text-align:Center;font-size:12px;white-space:break-spaces;width:auto;padding:0 0 0 5px;margin:0">TR</span>
                    <th><span style="text-align:Center;font-size:12px;white-space:break-spaces;width:auto;padding:0 0 0 5px;margin:0">${inputData?.space_output?.winterCal?.TR?.toFixed(2)}</span></th>
                    <th colspan="2"><span style="text-align:Center;font-size:13px;white-space:break-spaces;width:auto;padding:0 0 0 5px ;margin:0">GRAND TOTAL HEAT</span></th>
                    <td><span style="text-align:Center;font-size:12px;white-space:break-spaces;width:auto;padding:0 0 0 5px;margin:0">${inputData?.space_output?.winterCal?.grandTotalHeat}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>






























          <div style="width: 49%;padding: 0 10px;">
            <div style="margin: 28px 0 15px;">
              <table style="border: 1px solid #0000001f; border-radius: 10px;width: 100%;table-layout:fixed; overflow:hidden;">
                <thead style="background-color: #ECF6FD;">
                  <tr>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">Condition</span> </th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">DB (°F)</span> </th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">WB (°F)</span> </th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">% RH</span> </th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">DP (°F)</span> </th>
                    <th><span style="text-align: center; font-size: 12px; white-space: break-spaces;width: auto;padding: 0;margin:0;">GR / LB</span> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Outside</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.outsideWinterDb}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.outsideWinterWb}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.outsideWinterRh}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.outsideWinterDp}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.outsideWinterGr}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Room</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.roomWinterDb}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.roomWinterWb}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.roomWinterRh}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.roomWinterDp}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.roomWinterGr}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Difference</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${Math.round(inputData?.space_output?.winterCal?.conditionDifference)}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">XXXX</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">XXXX</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">XXXX</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.condtionOutSideRoomDifferenceWinter}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>


            <div style="margin-bottom: 15px;">
              <h3 style="font-size: 15px;font-weight:800; margin-bottom: 10px;">
                Outside Air (Ventilation)
              </h3>
              <table style="border: 1px solid #0000001f; border-radius: 10px;width: 100%;table-layout:fixed; overflow:hidden;">
                <tbody>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.occupancy}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">People X</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.freshAir}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">CFM/Person</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.outSideAirVentilationPersonWinter}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.size_cuft}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Cu ft X</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">1.0</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Air change per hour</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.outSideAirVentilationAirChange}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">CFM VENTILATION</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.maxResult}</span></td>
                  </tr>

                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td colspan="3"><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">EFF. SENSIBLE HEAT FACTOR (ESHF) =</span></td>
                    <td colspan="2"><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.effSensibleHeatFactorEshf?.toFixed(2)}</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td colspan="3"><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Indicated ADP =</span></td>
                    <td colspan="2"><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">55 °F</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td colspan="3"><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Selected ADP =</span></td>
                    <td colspan="2"><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">54 °F</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td colspan="3"><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Dehum. temp rise =</span></td>
                    <td colspan="2"><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.dehumTempRise?.toFixed(2)}°F</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td colspan="3"><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Dehumidified CFM =</td>
                    <td colspan="2"><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_output?.winterCal?.dehumiDifieldCfm?.toFixed(2)}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style="margin-bottom: 15px;">
              <h3 style="font-size: 15px;font-weight:800; margin-bottom: 10px;">Notes</h3>
              <table style="border: 1px solid #0000001f; border-radius: 10px;width: 100%;table-layout:fixed; overflow:hidden;">
                <tbody>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Occupancy =</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.occupancy}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">NOS</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Lighting =</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.lighting}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">W/Sq ft</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Eq. Load =</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.eqLoad}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">KW</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Height =</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.height}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">FT</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Fresh Air =</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.freshAir}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">CFM per person</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Dehumidified CFM =</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">${inputData?.space_input?.AirChange}</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Air chnages per hour</span></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style="margin-bottom: 15px;">
              <h3 style="font-size: 15px;font-weight:800; margin-bottom: 10px;">
                Internal Heat - People
              </h3>
              <table style="border: 1px solid #0000001f; border-radius: 10px;width: 100%;table-layout:fixed; overflow:hidden;">
                <tbody>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Sensible Heat =</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">245</span></td>
                  </tr>
                  <tr style="border-bottom: 1px solid #0000001f;">
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">Latent Heat =</span></td>
                    <td><span style="text-align:Center;font-size:10px;white-space:break-spaces;width:auto;padding:0;margin:0">205</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  `
}


export default HvacWinterPdf